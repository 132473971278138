import { Table } from "antd";
import React, { useState, useEffect } from "react";
import { getColor } from "../util/CC";
import { CheckIcon } from "../util/Icons";
import { useMediaQuery } from "react-responsive";
import Html from "../components/utilities/Html";

export default function TableGrid(props) {

    const template = window.sitesettings.template_info;
    const ismobile = useMediaQuery({ query: '(max-width: 716px)' })
    const { content = {} } = props;
    const [dataRow, setDataRow] = useState([]);

    const {
        location = "main",
        component = "table_grid",
        container = true,
        data = {},
        injected_data = {},
    } = content;

    const {
        background_colour = 0,
        header_bg_colour = 0,
        row_preset = 0,
        row_colour = 0,
        alternating_colour = 0,
        border_thickness = 1,
        fixed_width = false,
        css_id = "",
        border_colour = 1,
        align = "left",
        height = 1000,
        width = 1248,
        has_width = false,
        has_height = false,
        padding_x = 10,
        padding_y = 10,
        alternating = false,
        columns = [
            { header: "Column 1", padding_x: 0, padding_y: 0, text_align:"left", header_preset: 0, min_width: 200},
            { header: "Column 2", padding_x: 0, padding_y: 0, text_align:"left", header_preset: 0, min_width: 200},
            { header: "Column 3", padding_x: 0, padding_y: 0, text_align:"left", header_preset: 0, min_width: 200},
        ],
        rows = [
            {data: "Row 1 Column 1", check: false},{data: "Row 1 Column 2", check: false},{data: "Row 1 Column 3", check: false},
        ],

        allow_mobile_overrides = false,
        mobile_width = 100,
        mobile_height = 200,

    } = data;

    const uniqueId = `table-${css_id}`

    const dataRows = () => {
        const newRows = rows.map(row => row); 
        const groupedRows = [];
    
        for (let i = 0; i < newRows.length; i += columns.length) {
            const group = newRows.slice(i, i + columns.length);
            groupedRows.push({ ...group });
        }
    
        setDataRow(groupedRows);
    };

    useEffect(dataRows, [columns, rows]) 

    const dataColumns = columns.map((col, i) => ({
        title: 
            <div style={{ textAlign: col.text_align, padding: col.padding_y + "px " + col.padding_x + "px", minWidth: fixed_width ? col.min_width + "px" : '', maxWidth: fixed_width ? col.min_width + "px" : ''}}>
                <div className={"bm-component bm-txt-" + col.header_preset}>
                    <Html clear={false} className="" html={col.header} />
                </div>
            </div>,
        dataIndex: i,
        key: i,
        render: (e = {}, f, rowIndex) => {
            return {
                props: {
                    style: { background: alternating ? rowIndex % 2 === 1 ? getColor(alternating_colour, template) : getColor(row_colour, template) : row_colour === 0 ? "" : getColor(row_colour, template)}
                },
                children: 
                    <div style={{ textAlign: col.text_align, padding: col.padding_y + "px " + col.padding_x + "px",  minWidth: fixed_width ? col.min_width + "px" : '', maxWidth: fixed_width ? col.min_width + "px" : '' }}>
                        <div className={"bm-component bm-txt-" + row_preset} >
                            {e.check ? <CheckIcon />: ""}
                            <Html clear={false} className="" html={e.data} />
                        </div>
                    </div>
            };
        }
    }));

    const drawRender = () => {

        let _align = {
            background: getColor(background_colour, template),
            padding: `${padding_x}px ${padding_y}px`,
            maxWidth: "100%",
            display: fixed_width ? "flex" : "",
            flexDirection: fixed_width ? "column" : "",
        }

        let _table = {
            justifyContent: "space-evenly",
            height: (allow_mobile_overrides && ismobile) ? mobile_height + "px" : (has_height) ? height + "px" : "auto",
            maxWidth: (allow_mobile_overrides && ismobile) ? mobile_width + "%" : (has_width) ?  width + "px" : "100%",
            overflowY: "scroll",
            overFLowX: "scroll",
        }

        switch (align) {
            case "center":
                _table.alignSelf = "center";
                break;
            case "right":
                _table.alignSelf = "flex-end";
                break;
            default:
                _table.alignSelf = "flex-start";
                break;
        }

        return (
            <div className={(container) ? "preview-container" : ""}>
                <style>
                    {`
                        .${uniqueId} .ant-table-thead .ant-table-cell {
                            background-color: ${getColor(header_bg_colour, template)};
                        }

                        .${uniqueId} .ant-table table {
                            border-collapse: collapse;
                        }
                            
                        .${uniqueId} 
                        .ant-table-tbody > tr > td,
                        .ant-table-thead > tr > th
                        {
                            border-top-right-radius: 0 !important;
                            border-top-left-radius: 0 !important;
                        }
                    
                        .${uniqueId} .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
                            display: none;
                        }

                        .${uniqueId} .ant-table-tbody > tr > td {
                            border: ${border_thickness}px solid ${getColor(border_colour, template)};
                        }

                        .${uniqueId} .ant-table-thead > tr > th {
                            border: ${border_thickness}px solid ${getColor(border_colour, template)};
                            border-bottom: none;

                        }
                    `}
                </style>
                <div style={_align}>
                    <div style={_table}>
                        <Table 
                            size="small"
                            className={uniqueId}
                            dataSource={dataRow}
                            columns={dataColumns}
                            pagination={false}
                            scroll={{
                                x: 'max-content',
                            }}
                        />
                    </div>
                </div>

            </div>
        )
    }


    return drawRender();

}
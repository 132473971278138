import { Button, Form, message, Modal, Space } from "antd";
import React, { useState } from "react";
import CreditCardForm from "./CreditCardForm";
import { apiCall } from "../../utilities/Api";
import GlobalPaymentsForm from "./GlobalPaymentsForm";

export default function GlobalWrapper(props) {

    const { onComplete = () => { }, amount = "" } = props;

    const [open, setOpen] = useState(false);

    return (
        <>
            <Modal onCancel={() => setOpen(false)} width={350} footer={null} open={open}>
                <GlobalPaymentsForm onComplete={onComplete} amount={amount} />
            </Modal>
            <div onClick={() => setOpen(true)}>{props.children}</div>
        </>
    )
}
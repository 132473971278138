import { Button, Form, message, Modal, Space } from "antd";
import React, { useState } from "react";
import CreditCardForm from "./CreditCardForm";
import { apiCall } from "../../utilities/Api";

export default function CreditCardWrapper(props) {

    const { onComplete = () => { } } = props;
    const [form] = Form.useForm();
    const [open, setOpen] = useState(false);

    const encryptInfo = (_f) => {
        apiCall("checkout/setCreditCard", _f, (_s, _r) => {
            if(_s){
                onComplete(_r.cc_info);
                setOpen(false);
            } else {
                message.error(_r.result);
            }
        });
    }

    return (
        <>
            <Modal onCancel={() => setOpen(false)} width={350} footer={null} open={open}>
                <Form form={form} layout="vertical" onFinish={(_f) => encryptInfo(_f)}>
                    <CreditCardForm form={form} />
                    <div className="float-flex">
                        <div>&nbsp;</div>
                        <Space>
                            <Button onClick={() => setOpen(false)}>Cancel</Button>
                            <Button type="primary" htmlType="submit">Finalize Checkout</Button>
                        </Space>
                    </div>
                </Form>
            </Modal>
            <div onClick={() => setOpen(true)}>{props.children}</div>
        </>
    )
}
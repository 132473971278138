import React, { useEffect, useRef } from 'react';

export default function EmbedRenderer(props) {

  const {
      embed_code = ""
  } = props;

  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current) {
      // Set the dom with the embed code, parse the script tags, create script elements with attributes and draw
      containerRef.current.innerHTML = embed_code;
      const scripts = containerRef.current.querySelectorAll('script');
      
      scripts.forEach((script) => {
        const newScript = document.createElement('script');
        if (script.src) {
          newScript.src = script.src;
        } else {
          newScript.textContent = script.textContent;
        }
        script.parentNode.replaceChild(newScript, script);
      });
    }
  }, []);

  return <div ref={containerRef} />;
}


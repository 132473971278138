import React, { useState, useEffect } from "react"
import { Button, Modal, Form, Row, Col, Divider, Space, Input } from 'antd';
import { useHistory } from "react-router-dom";
import GiftCardPersonalize from "./GiftCardPersonalize";
import { apiCall } from "../../utilities/Api";
import { useSession } from "../../../util/Session";
import conditional from "../../utilities/conditional";
export default function GiftCardAddToCart(props) {

    // get image path from props; 



    const history = useHistory();


    const { update = false, cart_info = {}, is_button = false, cover_image_cache = "", cc_giftcard = false, item = {} } = props;
    const { emailed = false } = item;
    const { cart_id = 0 } = cart_info;
    const [context, setContext] = useSession();
    const [form] = Form.useForm();

    const [added, setAdded] = useState(false);
    const [animating, setAnimating] = useState(false);

    const GIFTCARD_MINIMUM = (parseInt(window.sitesettings.store_info.giftcard_minimum) < 1) ? 1 : parseInt(window.sitesettings.store_info.giftcard_minimum);
    const GIFTCARD_MAXIMUM = (parseInt(window.sitesettings.store_info.giftcard_maximum) < 1) ? 1 : parseInt(window.sitesettings.store_info.giftcard_maximum);


    const submitForm = () => {
        form.submit();
    }

    useEffect(() => {
        return () => clearInterval(window.gcadded);
    }, []);



    const reset = (_close = false) => {

        if (_close) {
            setModalVisible(false);
        }

        setAdded(false);


    }

    const onAddGiftcardToCart = (_status, _result) => {

        if (_status) {
            setContext({ ...context, "cart": _result })

            setAnimating(true);
            setAdded(true);
            window.gcadded = setTimeout(() => { setAnimating(false); }, 1000);

        }

    }
    const onFormFinish = (e) => {


        // add fresh
        let args = {
            eisbn: props.eisbn,
            price: e.amount,
            quantity: e.quantity,
            personalized: e.personalize
        }

        if (emailed || e.recipient_email) {
            args.recipient_email = e.recipient_email;
        }

        if (e.personalize) {
            args["from"] = e.from;
            args["to"] = e.to;
            args["message"] = e.message;
        }

        if (update) {

            args["cart_id"] = cart_id;
            apiCall("cart/add", args, onAddGiftcardToCart);

        } else {

            apiCall("cart/add", args, onAddGiftcardToCart);
        }



    }

    const addToCart = () => {

        Modal.confirm({
            icon: null,
            centered: true,
            title: "Gift cards can be loaded with any amount between $" + GIFTCARD_MINIMUM + " - " + GIFTCARD_MAXIMUM,
            content: <GiftCardPersonalize emailed={emailed} cart_info={props.cart_info} callback={onFormFinish} form={form} />,
            okText: (update) ? "Update" : "Add to cart",
            cancelText: "Cancel",
            onOk: () => submitForm()
        });
    }

    const [modalVisible, setModalVisible] = useState(false);

    const displayModal = () => {

        return (
            <>
                <Modal centered={true} destroyOnClose={true} open={modalVisible} closable={false} onCancel={() => setModalVisible(false)} footer={null}>
                    <div style={{ "padding": "10px" }}>
                        <Form onFinish={onFormFinish} form={form}>
                            <div style={{ "fontSize": "16px", "lineHeight": "20px" }}>Gift cards can be loaded with any amount between ${GIFTCARD_MINIMUM} - ${GIFTCARD_MAXIMUM}</div>
                            <conditional.true value={(!added)}>
                                <GiftCardPersonalize emailed={emailed} cart_info={props.cart_info} callback={onFormFinish} form={form} />
                            </conditional.true>

                            <conditional.true value={(added)}>
                            <div aria-hidden><Divider /></div>

                                <div style={{ "textAlign": "center" }}>
                                    <div><strong>Gift card added to cart</strong></div>
                                    <br />
                                    <Space>
                                        <Button onClick={() => reset(true)}>Keep shopping</Button>
                                        <Button onClick={() => reset(false)}>Add more gift cards</Button>
                                    </Space>
                                </div>
                            </conditional.true>


                            <div aria-hidden><Divider /></div>
                            <div className="float-flex">
                                <div>&nbsp;</div>
                                <div>
                                    <conditional.true value={(!added)}>

                                        <Space>
                                            <Button onClick={() => setModalVisible(false)}>Close</Button>
                                            <Button onClick={() => submitForm()} type="primary">Add to cart</Button>
                                        </Space>
                                    </conditional.true>

                                    <conditional.true value={(added && animating)}>
                                        <Space>
                                            <Button onClick={() => setModalVisible(false)}>Close</Button>
                                            <Button type="primary">Added to cart</Button>
                                        </Space>
                                    </conditional.true>

                                    <conditional.true value={(added && !animating)}>
                                        <Space>
                                            <Button onClick={() => setModalVisible(false)}>Close</Button>
                                            <Button onClick={() => history.push("/checkout/cart")} type="primary">Go to Cart</Button>
                                        </Space>
                                    </conditional.true>
                                </div>
                            </div>
                            <br clear="all" />
                        </Form>
                    </div>
                </Modal>
            </>
        )

    }

    const imageFallback = (i) => {
        i.target.src = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8WA8AAmcBcgw8D2oAAAAASUVORK5CYII="
        i.target.width = "292px";
        i.target.height = "184";
    }


    const displayCartable = () => {
        return (<Button onClick={() => addToCart()} htmlType="submit" style={{ "marginBottom": "5px" }} size="small" >{props.children}</Button>)
    }

    const displayCartableCC = () => {
        return (<div onClick={() => addToCart()} htmlType="submit" style={{ "marginBottom": "5px" }} size="small" >{props.children}</div>)
    }




    const displayCC = () => {

        return (
            <>
                {displayModal()}
                <div onClick={(e) => { e.preventDefault(); e.stopPropagation(); setModalVisible(true) }} htmlType="submit" type="primary" >{props.children}</div>
            </>
        )
    }



    const display = () => {

        return (<div onClick={(e) => { e.preventDefault(); e.stopPropagation() }}>


            {displayModal()}

            <conditional.true value={(is_button)}>
                <Button aria-label={props.title + " Add to cart"} onClick={(e) => { e.preventDefault(); e.stopPropagation(); setModalVisible(true) }} htmlType="submit" type="primary" >Add to cart</Button>
            </conditional.true>

            <conditional.true value={(!is_button)}>
                <div style={{ "margin": "0 auto", "maxWidth": "650px" }}>
                    <br />
                    <Row align={"middle"}>
                        <Col xs={24} sm={12} ><img onError={(i) => imageFallback(i)} alt={props.title} style={{ "borderRadius": "15px" }} className="shadow-light" src={"https://cdn1.bookmanager.com/i/m.php?b=" + props.eisbn + "&cb=" + props.cover_image_cache} /></Col>
                        <conditional.true value={(window.sitesettings.cart_enabled)}>
                            <Col xs={24} sm={12} >
                                <strong><big>{props.title}</big></strong>
                                <span style={{ "margin": "20px 0px", "display": "block" }}><Button aria-label={props.title + "Add to cart"} onClick={() => setModalVisible(true)} htmlType="submit" type="primary" >Add to cart</Button></span>
                            </Col>
                        </conditional.true>
                    </Row>
                    <br />
                </div>
            </conditional.true>



        </div>)

    }





    const displayStatus = () => {

        // USE WEBSTORE DESIGNER
        if (cc_giftcard) {
            if (props.cartable) {
                return displayCartableCC();
            } else {
                return displayCC();
            }
        }

        if (props.cartable) {
            return displayCartable();
        } else {
            return display();
        }
    }

    return displayStatus();

}
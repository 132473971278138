import React, { useState } from "react";
import { ExclamationCircleFilled } from '@ant-design/icons';
import { useSession } from "../../../util/Session";
import { Form, Input, Button, Divider, Space } from 'antd';
import { Link, useHistory } from "react-router-dom";
import { apiCall } from "../../utilities/Api";
import conditional from "../../utilities/conditional";

/**
 * Sign Up 
 *  - User enters email address
 *  - Email is validated via an API Call
 *  - If the email is invalid, present message
 *  - If the email exists in the system we present message with links to forgot password, login
 *  - If the email does not exist in the system, save email to context and move to account create
 *
 * @component
 * 
 */

function AccountSignUp() {


    const [session, setSession] = useSession();
    const history = useHistory();
    const [error, setError] = useState(false);
    const [message, setMessage] = useState("");
    const [email, setEmail] = useState("");
    const [registered, setRegistered] = useState(false);
    const [loading, setLoading] = useState(false);


    /**
    * Callback for API Call - 
    * @param  {bool} _status
    * @param  {object} _result object or error object
    */
    const onCheckEmail = (_status, _result) => {

        setLoading(false);
        if (!_status) {
            setError(true);
            setMessage(<div aria-live="assertive">There was an error. Please ensure you've entered a valid email address.</div>);
        } else {
            if (_result.email_address.exists) {
                setError(true);
                setRegistered(true);
                setMessage(
                    <>
                        <div aria-live="polite">Your account is already set up and ready to go!</div>
                        <div className="shim" /><div className="shim" /><div className="shim" />
                        <div style={{ "textAlign": "left", "color" : "#444444" }}>
                            <div>This store utilizes the <strong style={{ "textDecoration": "underline", "cursor": "pointer" }}><a aria-label="Visit bookmanager (opens in a new tab)" href="https://bookmanager.com/" target="_blank">Bookmanager</a></strong> platform, which powers hundreds of independent bookstores across North America. 
                            <div className="shim" /><div className="shim" />
                            You have already created an account at another store using Bookmanager, so all you need to do here is log in with the same email and password, and you're all set! 
                            <div className="shim" /><div className="shim" />
                            If you can't remember your password, no worries, use the <strong onClick={() => history.push("/account/forgot")} style={{ "textDecoration": "underline", "cursor": "pointer" }}>I Forgot My Password</strong> link in the Login menu to reset your password.</div>
                            <div className="shim" /><div className="shim" />
                            <div style={{"lineHeight" : "16px"}}><small>Only stores that you have submitted orders to will have access to your account data, which is used to fulfil orders. Credit card info is never stored online across the Bookmanager userbase. So rest easy that your data is only provided to those stores you're shopping with.</small></div>
                        </div>

                    </>
                );
            } else {


                if (_result.email_address.validation_required) {
                    // verify first
                    history.push({ "pathname": "/account/verify", "state": _result })
                } else {
                    // set sesssion 
                    let user = { "email_address": _result.email_address.address }
                    setSession({ ...session, "user": user });
                    history.push({ "pathname": "/account/create" })

                }


            }
        }
    }

    /**
     * API Call checks if email_address is valid and if it's in the system already
     * - eg.  http://apiv1.bookmanager.com/checkAccount?email_address=test@test.com
     * @function
     * @param  {object} _form
     * @param  {string} _form.email_address
     */
    const checkEmail = (_form) => {

        if (loading) { return; }
        setLoading(true);
        setRegistered(false);
        setError(false);
        let { email_address } = _form;
        apiCall("account/check", { "email_address": email_address, "callback_url": "account/verify" }, onCheckEmail);
        setEmail(_form.email_address);
    }

    /**
     * Displays the JSX 
     * @returns {jsx} JSX 
     */
    const display = () => {
        return (
            <div>
                <h2>Create an Account</h2>
                <div aria-hidden><Divider /></div>
                <div style={{ "opacity": (loading) ? "0.7" : "1", "margin": "0 auto", "marginTop": "40px", "maxWidth": "500px" }}>
                    <Form onFinish={(values) => checkEmail(values)} layout="vertical">
                        <Input.Group compact>
                            <Form.Item name="email_address" noStyle>
                                <Input aria-label="Email Address" inputMode="email" size="large" type="email" style={{ "width": '74%' }} placeholder="Email Address" />
                            </Form.Item>
                            <Button aria-label="Sign Up" htmlType="submit" style={{ "width": '26%' }} size="large" type="primary">Sign Up</Button>
                        </Input.Group>
                    </Form>


                    {/* ERROR MESSAGE */}
                    <conditional.true value={(error)}>
                        <p style={{ "paddingTop": "25px", "color": "#e70d3d", "textAlign": "center" }} className="error"><ExclamationCircleFilled /> {message}</p>
                    </conditional.true>


                    {/* ALREADY REGISTERED*/}
                    <conditional.true value={(registered)}>
                        <div className="shim" /><div className="shim" />
                        <div style={{ "margin": "0 auto", "display": "flex", "alignItems": "center", "justifyContent": "center" }}>
                            <Button onClick={() => history.push("/account/login")}>Login</Button>
                            <div>&nbsp;&nbsp;&nbsp;</div>
                            <Button onClick={() => history.push("/account/forgot")}>Reset Password</Button>
                        </div>
                    </conditional.true>

                    <br />
                </div>
            </div>
        )
    }

    return display();

}

export default AccountSignUp;
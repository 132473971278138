import React, { useState, useEffect } from "react";
import { Switch, Route, useLocation, Redirect, useHistory } from "react-router-dom";
import { useSession } from "./Session";
import ReactPixel from 'react-facebook-pixel';

// Layouts
import HomeLayout from "../layouts/HomeLayout";
import BrowseLayout from "../layouts/BrowseLayout";
import ItemLayout from "../layouts/ItemLayout";
import CustomLayout from "../layouts/CustomLayout";
import GiftCardLayout from "../layouts/GiftCardsLayout";
import CustomListsLayout from "../layouts/CustomListsLayout";
import CheckoutLayout from "../layouts/CheckoutLayout";
import AccountLayout from "../layouts/AccountLayout";
import ListGridLayout from "../layouts/ListGridLayout";
import ContactLayout from "../layouts/ContactLayout";
import EventsLayout from "../layouts/EventsLayout";
import PrivacyPolicy from "../layouts/PrivacyPolicy";
import FeaturesLayout from "../layouts/FeaturesLayout";
import OrdersLayout from "../layouts/OrdersLayout";

// Site Wide
import GlobalBanner from "../components/GlobalBanner";
import LocationSelector from "../components/LocationSelector";
import RequiredLogin from "../components/RequiredLogin";
import { apiCall } from "../components/utilities/Api";
import ProcessPDF from "../components/components/ProcessPdf"
import ReactGA from "react-ga4";
import GiftRegistry from "../components/components/giftregistry/GiftRegistry";
import GiftRegistryDetails from "../components/components/giftregistry/GiftRegistryDetails";
import Checkout from "../components/components/newcheckout/Checkout";
import SiteFooter2 from "../components/SiteFooter2";
import SiteNav2 from "../components/SiteNav2";
import SiteSearchBar from "../components/SiteSearchBar";
import LiveToggle from "./LiveToggle";
import { Alert, Button, Card, Result } from "antd";
import Test from "../components/Test";
import BMAlert from "./BMAlert";
import CancelEvent from "../components/components/events/CancelEvent";
import useScrollMemory from "./useScrollMemory";
import { getPrice } from "./Utils";
import PurchaseLayout from "../layouts/PurchaseLayout";

export const pixelTrack = (_key, _data, _obj) => {


    if (!window.sitesettings.store_info.facebook_pixel_id) {
        return;
    }

    if (window.pixelTrack) {
        return;
    }

    window.pixelTrack = true;
    setTimeout(() => {
        window.pixelTrack = false;
    }, 100)

    switch (_key) {
        case "AddToCart":
            let _add_to_cart = {
                content_name: _data?.title,
                content_ids: [_obj?.eisbn],
                content_type: _obj?.condition || "New",
                value: getPrice(_data.available, _obj.condition),
                currency: window.sitesettings.store_info.iso4217_currency,
                quantity: _obj?.quantity
            }
            ReactPixel.track("AddToCart", _add_to_cart);
            return;

        case "InitiateCheckout":
            let _initiate_checkout = {
                contents: _data.items.map((i => {
                    return {
                        id: i.title_info.eisbn,
                        name: i.title_info.title,
                        quantity: i.cart_info.quantity,
                        price: Number(i.cart_info.sold_price)
                    }
                })),
                content_type: _obj, // pickup or ship
                value: Number(_data.summary.total), // total cart value
                currency: window.sitesettings.store_info.iso4217_currency
            }
            ReactPixel.track("InitiateCheckout", _initiate_checkout);
            return;

        case "Purchase":
            let _purchase = {
                contents: _data.items.map((i => {
                    return {
                        id: i.title_info.eisbn,
                        name: i.title_info.title,
                        quantity: i.cart_info.quantity,
                        price: Number(i.cart_info.sold_price)
                    }
                })),
                content_type: _obj.delivery_preference, // pickup or ship
                value: Number(_data.summary.total), // total cart value
                currency: window.sitesettings.store_info.iso4217_currency
            }

            ReactPixel.track("Purchase", _purchase);
            return;

        case "Search":
            let _search = {
                search_string: _data, // search string
                content_category: _obj, // search type
            }
            ReactPixel.track("Search", _search);
            return;

        case "CompleteRegistration":
            let _register = {
            }
            ReactPixel.track("CompleteRegistration", _register);
            return;





    }

    return;

}

function SiteRouter(props) {

    const history = useHistory();
    const location = useLocation();
    const [session] = useSession();
    const current = useLocation();
    const [viewed, setViewed] = useState({});

    useScrollMemory();
    // Google Analytics

    // Initialize 
    useEffect(() => {

        if (window.sitesettings.store_info.facebook_pixel_id) {
            ReactPixel.init(window.sitesettings.store_info.facebook_pixel_id, {}, { autoConfig: true, debug: false });
        }

        if (window.sitesettings.store_info.google_analytics_code) {
            ReactGA.initialize(window.sitesettings.store_info.google_analytics_code);
        }
    }, [])

    // Send GA And Pixel Page views
    useEffect(() => {
        if (window.sitesettings.store_info.google_analytics_code) {
            ReactGA.send({ hitType: "pageview", page: location.pathname })
        }

        if (window.sitesettings.store_info.facebook_pixel_id) {
            ReactPixel.pageView(location.pathname);
        }

    }, [location]);



    // Parse search route
    const unslugify = (slug) => {
        let result = slug.replace(/-/g, " ");
        result = result.replace(/\//g, " ");
        return result.replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    }

    // Synthetically update the title as routes change
    const setTitle = (_path, ret = false) => {
        let _title = window.sitesettings.store_info.name.toString();

        if (session.location_id) {
            let _s = window.sitesettings.store_info.multistore_info.find(item => item.id === session.location_id);
            if (_s) {
                _title = _s.name;
                _ti = _s.name;
            }
        }
        let _ti = _title;

        if (_path.includes("/item") || _path.includes("/lists")) { }

        else if (_path.includes("/browse")) {
            _title = _title + " | Browse";
            _ti = "Browse";
        } else {
            if (_path.length > 1) {
                _title = _title + " | " + unslugify(_path);
                _ti = unslugify(_path);
            } else {

            }
        }

        if (ret) {
            return _ti;
        } else {
            document.title = _title;
        }


    }


    const triggerAnchor = () => {
        if (history) {
            if (history.location.hash) {
                if (document.getElementById(history.location.hash.substr(1, history.location.hash.length))) {
                    document.getElementById(history.location.hash.substr(1, history.location.hash.length)).scrollIntoView(true);
                }
            }
        }
    }

    const drawBMLink = () => {
        setTitle(current.pathname);
        window.bmlink = function (e, _path) {

            e.preventDefault();
            e.stopPropagation();

            if (_path.includes("@")) {
                window.location.href = "mailto:" + _path;
                return;
            }

            // people linking to images.. 
            if (_path.includes(".jpg") || _path.includes(".png")) {

                if (_path.includes("http")) {
                    window.location.href = _path;
                } else {
                    window.location.href = window.sitesettings.store_info.base_url + "/" + _path;
                }

                return;
            }

            // path to a file
            if (_path.includes("/i/")) {
                window.open(_path, "_blank");
                return;
            }

            history.push(_path, props.sitesettings.site_navigation);
        }
        if (!current.pathname.includes("/browse/filter")) {
            if (history.action === "PUSH") {
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
            }
        }

        setTimeout(triggerAnchor, 1000)

    }

    useEffect(drawBMLink, [current]);

    // const onLogError = (_status, _result) => {

    // }

    // const logError = (e, s, l, c, n) => {

    //     if (e === "Script error.") {
    //         return;
    //     }
    //     let err = {};
    //     err.location = (location && location.pathname) ? location.pathname : "no location";
    //     err.user_agent = (navigator && navigator.userAgent) ? navigator.userAgent : "no agent";
    //     err.error_info = (e) ? e : "no error info";
    //     err.source = (s) ? s : "no source";
    //     err.lineno = (l) ? l : "no lineno";
    //     err.colno = (c) ? c : "no colno";
    //     err.error = (n) ? n : "no error";

    //     apiCall("log/error", err, onLogError);
    // }

    // const errorListener = () => {
    //     window.onerror = (e, s, l, c, n) => {
    //         logError(e, s, l, c, n);
    //     }
    // }

    // useEffect(errorListener, []);

    const addWrap = (_component) => {
        return (
            <>
                <div className="shim hide-block" style={{ "height": "20px" }} />
                <div className="shim" style={{ "height": "0px" }} />
                <CustomLayout is_global={true} />
                {_component}
                <div className="shim" style={{ "height": "20px" }} />
            </>
        )
    }


    const getLayout = (location) => {

        // CONTENT CREATOR 
        // destructure because key does not exist on v1
        let { is_v1 = true } = location;
        if (!is_v1) {
            return <><CustomLayout is_global={true} /> <CustomLayout location={location} /></>;
        }



        switch (location.type) {
            case "home":
                return addWrap(<HomeLayout location={location} />)
            case "browse":
                return addWrap(<BrowseLayout location={location} />)
            case "lists":
                return (<></>)
            case "unlinked":
                return (location.path === "/events") ? addWrap(<EventsLayout location={location} />) : <></>
            case "custom":
                return addWrap(<CustomLayout location={location} />)
            case "terms_conditions":
                return addWrap(<CustomListsLayout location={location} />)
            case "custom_lists":
                return addWrap(<CustomListsLayout location={location} />)
            case "v3_content":
                return addWrap(<CustomListsLayout location={location} />)
            case "gift_cards":
                return addWrap(<GiftCardLayout location={location} />)
            case "contact_and_hours":
                return addWrap(<ContactLayout location={location} />)
            case "events":
                return addWrap(<EventsLayout location={location} />)
            case "link":
                return addWrap(<CustomListsLayout location={location} />)
            case "features":
                return addWrap(<FeaturesLayout location={location} />)
            default:
                return (<><pre>{JSON.stringify(location, null, 2)}</pre></>)
        }
    }

    const hidden_routes = props.sitesettings.extra_navigation.filter(item => item.path).map((item) => {
        let ret = [];


        ret.push(
            <Route exact key={item.id} path={item.path}>
                <div>{getLayout(item)}</div>
            </Route>
        );
        return ret;
    })

    const url_shortcuts = props.sitesettings.url_shortcuts.filter(item => item.path).map((item) => {
        let ret = [];
        if (item.path.startsWith("http")) {
            ret.push(
                <Route exact path={"/" + item.key} component={() => {
                    window.location.href = item.path;
                    return null;
                }} />
            );
        }
        ret.push(
            <Route exact path={"/" + item.key}>
                <Redirect to={item.path} />
            </Route>
        );
        return ret;
    })

    let events_page = false;
    if (!window.sitesettings.is_v1) {

        let _navarr = props.sitesettings.site_navigation;

        _navarr.forEach(item => {
            if (item.type === "events") {
                events_page = item;
            }
            item.submenu.forEach(subitem => {
                if (subitem.type === "events") {
                    events_page = subitem;
                }
            });
        });

        if (!events_page) {
            events_page = props.sitesettings.extra_navigation.find(item => item.type === "events" || (item?.built_in_type === "events"));
            if (!events_page) {
                events_page = props.sitesettings.site_navigation.find(item => item.type === "events" || (item?.built_in_type === "events"));
            }
            if (!events_page) {
                events_page = { is_v1: true }
            }
        }
    } else {
        events_page = { is_v1: true }
    }



    const subroutes = props.sitesettings.site_navigation.map((item) => {
        return item.submenu.filter(item => item.path && item.type !== "linked").map(item => {
            return (
                <Route exact key={item.id} path={(item.path) ? item.path : "/"}>
                    <div>{getLayout(item)}</div>
                </Route>
            )
        })
    });

    const routes = props.sitesettings.site_navigation.filter(item => item.path && item.type !== "linked").map((item) => {


        return (
            <Route exact key={item.id} path={item.path}>
                <div>{getLayout(item)}</div>
            </Route>
        );
    });

    const drawRoutes = () => {

        if (window.sitesettings.is_private) {

            if (!session.logged_in) {
                return <RequiredLogin />
            }

            if (window.sitesettings.is_exclusive) {
                return <RequiredLogin />
            }

        }

        const linkHome = () => {
            if (window.sitesettings.store_info.alternate_home) {
                window.location.href = window.sitesettings.store_info.alternate_home;
                return;
            } else {
                if (window.sitesettings.landing_page_url) {
                    history.push("/home");
                } else {
                    history.push("/");
                }
            }
        }


        const drawMessage = () => {


            if (window.sitesettings?.banner_message) {
                if (window.sitesettings.banner_message.text) {

                    let { type = "success", link = "", text = "" } = window.sitesettings.banner_message;

                    let _color = "#f00";
                    let _txt = "#fff";
                    switch (type) {
                        case "warning":
                            _color = "#ffa200";
                            _txt = "#000";
                            break;
                        case "error":
                            _color = "#e00000";
                            break;
                        case "info":
                            _color = "#1465c8";
                            break;
                        default:
                            _color = "#2eb000";
                            break;
                    }

                    return (
                        <div style={{ "position": "relative", "zIndex": "100" }}>
                            <BMAlert
                                onClick={() => { if (link) { window.open(link) } }}
                                className="switchbanner"
                                message={<div style={{ "textAlign": "center" }}>
                                    <small style={{ "color": _txt }}>{text}</small>
                                </div>}
                                banner
                                style={{ "cursor": (link) ? "pointer" : "default", "color": "#fff", "backgroundColor": _color, "borderLeft": "0px", "borderRight": "0px", "position": "inherit", "height": "30px", "width": "100%" }}
                                showIcon={false}
                                type={type}
                            />
                        </div>
                    )
                }
            }
        }

        // HOME
        let home = props.sitesettings.site_navigation.find(item => item.type === "home");
        if (!home) {
            home = props.sitesettings.extra_navigation.find(item => item.type === "home")
            if (!home) {
                home = { "label": "Home", "path": "/", "is_v1": false, "id": "h", "search": [], "type": "home", "submenu": [] }
            }
        }

        return (
            <div className={(window.sitesettings.is_v1) ? "" : "bm-background bm-background-color"}>



                <div id="bm-notification"></div>
                <a href="#main-content" tabIndex="0" className="skip-link asa-btn" aria-label="Skip to main content">Skip to main content</a>
                <SiteSearchBar site_navigation={props.sitesettings.site_navigation} />
                <SiteNav2 current={current} site_navigation={props.sitesettings.site_navigation} />
                <main id="main-content" tabIndex="-1" role="main" style={{ minHeight: "calc(100vh - 374px)" }}>
                    <div style={{ "position": "absolute", "opacity": "0" }}><Alert message={setTitle(current.pathname, true)} /></div>
                    <GlobalBanner />
                    <CustomLayout banner={true} is_global={true} />
                    <LocationSelector />
                    <Switch>
                        <Route path="/cancel/event/:event_id/:encrypted_rsvp_tag" children={addWrap(<CancelEvent />)} />
                        <Route path="/features" children={addWrap(<FeaturesLayout />)} />
                        <Route exact path="/gift-registry" children={addWrap(<GiftRegistry />)} />
                        <Route path="/gift-registry/:item_id" children={addWrap(<GiftRegistryDetails />)} />
                        <Redirect from="/r/ei/:item_id" to="/item/:item_id" />
                        <Redirect exact from="/checkout" to="/checkout/cart" />
                        <Route path="/purchase" children={addWrap(<PurchaseLayout />)} />
                        <Route path="/pdf/:process_id" children={addWrap(<ProcessPDF />)} />
                        <Route path="/checkout/:progress" children={addWrap(<CheckoutLayout key={0} />)} />
                        <Route path="/item/:item_id" children={addWrap(<ItemLayout viewed={viewed} setViewed={setViewed} />)} />
                        <Route path="/account/orders" children={addWrap(<OrdersLayout />)} />
                        <Route path="/account/order/:id" children={addWrap(<OrdersLayout />)} />
                        <Route path="/account/:view" children={addWrap(<AccountLayout />)} />
                        <Route exact path="/privacy-policy" children={addWrap(<PrivacyPolicy />)} />
                        <Redirect from="/r/eigr/:item_id" to="/item/:item_id" />
                        <Route path="/lists/cartable/:list_id" children={addWrap(<ListGridLayout cartable />)} />
                        <Route path="/lists/cartable/:list_id/:page_id" children={addWrap(<ListGridLayout cartable />)} />
                        <Route path="/lists/:list_id/:page_id" children={addWrap(<ListGridLayout />)} />
                        <Route path="/lists/:list_id" children={addWrap(<ListGridLayout />)} />
                        <Redirect exact from="/account" to="/account/login" />
                        <Route path="/forgot-password" children={addWrap(<AccountLayout />)} />
                        <Route path="/account/activate" children={addWrap(<AccountLayout />)} />

                        {subroutes}
                        {routes}
                        {hidden_routes}
                        {url_shortcuts}

                        {/* PRE WEBSTORE DESIGNER */}
                        {((events_page.is_v1 && !window.sitesettings.using_events_v2) && <Route path="/events/:event_filter" children={addWrap(<EventsLayout />)} />)}
                        {/* WEBSTORE DESIGNER / OLD EVENTS */}
                        {((!events_page.is_v1) && <Route path="/events/:event_filter" children={addWrap(<><CustomLayout events_details={true} location={events_page} /></>)} />)}
                        {/* NEW EVENTS */}

                        <Route exact path="/browse" children={addWrap(<BrowseLayout />)} />
                        <Route path="/browse/:id" children={addWrap(<CustomLayout location={{ type: "browse" }} />)} />

                        <Route exact path="/" children={getLayout(home)} />
                        <Route path="*">

                            <Card style={{ "maxWidth": "1200px", "margin": "0 auto", "marginTop": "20px" }}>
                                <Result
                                    title="404"
                                    subTitle="Oops! The page you're looking for doesn't exist."
                                    extra={
                                        <Button aria-label="Click to return to the homepage" type="primary" onClick={() => linkHome()}>
                                            Go to Homepage
                                        </Button>
                                    }
                                />
                            </Card>
                        </Route>
                    </Switch>
                </main>
                <SiteFooter2 store_info={props.sitesettings.store_info} />
                <LiveToggle />
            </div>
        )
    }


    // if(history.location.pathname=== "/test"){
    //     return(<Test />)
    // }


    return drawRoutes();

}

export default SiteRouter;

import React from "react";
import { Button, Modal, Input, Space } from 'antd';
import { FacebookOutlined, ShareAltOutlined, TwitterSquareFilled } from '@ant-design/icons';
import { BlueskyIcon, MastodonIcon, ThreadsIcon, TwitterIcon } from "../../../util/Icons";

export default function EventSocial(props) {


    const { path, name, link_id = 0 } = props;

    const shareEvent = () => {

        Modal.info({
            icon: null,
            centered: true,
            title: name,
            width: "auto",
            style: { "padding": "0px" },
            content: <>
                <p>To share this with others, copy this link (e.g. Ctrl-C) and then paste it into an email.</p>
                <Input defaultValue={window.sitesettings.store_info.base_url + "/events/" + path} style={{ "marginBottom": "10px" }} />
            </>,
            okType: "default",
            okText: "Close"
        });

    }

    return (<>
        <br />
        <div style={{ "textAlign": "center" }}>
            <Space>
                {window.sitesettings.share_twitter && (
                    <Button
                        className={`bm-lnk-${link_id}`}
                        type="text"
                        icon={<TwitterIcon aria-hidden style={{ fontSize: "23px" }} />}
                        aria-label="Share on Twitter (opens in a new tab)"
                        onClick={() =>
                            window.open(
                                `https://twitter.com/share?url=${window.sitesettings.store_info.base_url}/events/${path}`,
                                "_blank"
                            )
                        }
                    />
                )}

                {window.sitesettings.share_facebook && (
                    <Button
                        className={`bm-lnk-${link_id}`}
                        type="text"
                        icon={<FacebookOutlined aria-hidden style={{ fontSize: "25px" }} />}
                        aria-label="Share on Facebook (opens in a new tab)"
                        onClick={() =>
                            window.open(
                                `https://www.facebook.com/sharer/sharer.php?u=${window.sitesettings.store_info.base_url}/events/${path}`,
                                "_blank"
                            )
                        }
                    />
                )}

                {window.sitesettings.share_bluesky && (
                    <Button
                        className={`bm-lnk-${link_id}`}
                        type="text"
                        icon={<BlueskyIcon aria-hidden style={{ fontSize: "25px"}} />}
                        arial-label="Share on BlueSky (opens in a new tab)"
                        onClick={() =>
                            window.open(
                                `https://bsky.app/intent/compose?text=${window.sitesettings.store_info.base_url}/events/${path}`,
                                "_blank"
                            )
                        }
                    />
                )}

                {window.sitesettings.share_mastodon && (
                    <Button 
                        className={`bm-lnk-${link_id}`}
                        type="text"
                        icon={<MastodonIcon aria-hidden style={{ fontSize: "25px"}} />}
                        arial-label="Share on Mastodon (opens in a new tab)"
                        onClick={() =>
                            window.open(
                                `https://mastodonshare.com/?url=${window.sitesettings.store_info.base_url}/events/${path}`,
                                "_blank"
                            )
                        }
                    />
                )}

                {window.sitesettings.share_threads && (
                    <Button
                        className={`bm-lnk-${link_id}`}
                        type="text"
                        icon={<ThreadsIcon aria-hidden style={{ fontSize: "25px"}} /> }
                        arial-label="Share on Threads (opens in a new tab)"
                        onClick={() =>
                            window.open(
                                `https://threads.net/intent/post?url=${window.sitesettings.store_info.base_url}/events/${path}`,
                                "_blank"
                            )
                        }
                    />
                )}

                <Button className={"bm-lnk-" + link_id} onClick={() => shareEvent()} type="text" icon={<ShareAltOutlined aria-hidden style={{ "fontSize": "25px" }} />} />
            </Space>
        </div>
    </>
    )
}
import React, { useState } from "react";

import { Button, Card, Result, Divider, Input, Typography, Alert, Popconfirm } from 'antd';
import { apiCall } from "../../utilities/Api";
import { useSession } from "../../../util/Session";
import conditional from "../../utilities/conditional";
import moment from "moment-timezone";
export default function PromoCodes() {

    const [session, setSession] = useSession();
    const [error, setError] = useState(false);


    const addPromoCode = (_code) => {
        apiCall("checkout/addPromocode", { code: _code }, (_status, _result) => {
            if (_status) {
                window.checksum = _result.checksum;
                setSession({ ...session, ["cart"]: _result })
            } else {
                setError(_result.error);
            }
        });
    }

    const removePromoCode = () => {
        apiCall("checkout/removePromocode", {}, (_status, _result) => {
            if (_status) {
                window.checksum = _result.checksum;
                setSession({ ...session, ["cart"]: _result })
            } else {
                setError(_result.error);
            }
        });

    }


    if (!window.sitesettings.checkout_info.using_promo_codes) {
        return (<></>);
    }

    let m = session.cart.summary.promo_codes.find((item) => item.automatic === false)

    if (m) {
        return (<>
            <Card style={{"border" : "1px solid #dedede"}}>
                <strong>Applied Promo Code:</strong>
                <div style={{"fontFamily" : "monospace", "color" : "#3c8617", "fontSize" : "16px"}}><strong>{m.code}</strong></div>
                {((m.expiry_date>0) &&  <div>Expires on {moment(m.expiry_date*1000).format("MMM Do YYYY h:mma")}<div className="shim" /></div>)}
                <p><em>{m.description}</em></p>
                <Popconfirm onConfirm={() => removePromoCode()} title={<>Are you sure?</>} ><Button size="small" type="link" danger>Remove</Button></Popconfirm>
                <br clear="all" />
            </Card>
            <div type="error" className="shim"></div>
        </>)
    }

    return (<>
        <Card style={{"border" : "1px solid #dedede"}}>
            <Input.Search
                placeholder="Promo code"
                allowClear
                className="promo-coder-field"
                aria-label="Enter Promo code"
                enterButton={<Button aria-label="Add Promo Code" type="primary">Add</Button>}
                maxLength={15}
                size="large"
                style={{ "fontFamily": "monospace" }}
                onSearch={addPromoCode}
            />
            <conditional.true value={error}>
                <div className="shim"></div>
                <Alert className="msg" description={error} type="error" />
            </conditional.true>
        </Card>
        <div type="error" className="shim"></div>
    </>);
}
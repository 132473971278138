import React, { useState } from "react";
import {useSession} from "../../../util/Session";
import { Button, Space } from 'antd';
import Html from "../../utilities/Html";
import FeaturesSocial from "./FeaturesSocial";
import FeaturesPDF from "./FeaturesPDF";
export default function FeaturesOptionsBar(props) {


    
    
    const base_url = window.location.href.replace("/features", "");

    const { description, list_id, header } = props.data;

    const [session] = useSession(); 

    const detailsVisible = useState(false);


    const drawUserButtons = () => {

        if(session.logged_in){
            return(
            <>
                 <FeaturesPDF list_id={list_id}>PDF</FeaturesPDF>
                 {/* <Button className="small-btn" type="primary" size="small">Excel</Button> */}
                 {/* <FeaturesEmail path="http://www.google.ca/" name={header} /> */}
                 {/* <Button className="small-btn" type="primary" size="small">My notes</Button> */}
                 {/* <Button onClick={() => setDetailsVisible(!detailsVisible)} className="small-btn" type="primary" size="small">{(detailsVisible) ? "Hide details" : "Show details"}</Button> */}
            </>
            )
        }

    }

    return (<>

        <div style={{ "textAlign": "center" }}>
            <div style={{ "display": (detailsVisible) ? "block" : "none" }}>
                {description !== "" && <> <Html html={description} /><br /><br /></>}
            </div>

            <Space>

                <Button aria-label="Print (opens in a new tab)" onClick={() => window.open("https://bookmanager.com" + window.sitesettings.basename + "?q=h.misc.listfuncs&act=tldefpp&t=1&id=" + list_id + "", "_blank")} className="small-btn" size="small">Print</Button>
                <FeaturesSocial path={base_url+ "/lists/" + list_id} name={header} >Share</FeaturesSocial>
                {drawUserButtons()}
            </Space>


        </div>
    </>
    );

}

import React, { useState, useEffect } from 'react';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import Html from '../../components/utilities/Html';

const Carousel = (props) => {
  const {
    image_radius = 0,
    auto = false,
    timeout = 3,
    images = [],
    animation = "fade",
    button_type = 1,
    height = 400,
    background_size = "cover",
    background_position = "center center",
    content_header_style = 0,
    content_paragraph_style = 0,
    content_button_style = 0,
    content_padding_x = 10,
    content_padding_y = 10,
    content_align = "center",
    content_justify = "center",
    content_text_align = "center",
    contained = false,
    content_max_width = 1248,
    image_opacity = 100
  } = props;

  const originalData = images;
  // For sliding, clone the last item at the start and first item at the end for infinite cycling
  const displayedData =
    animation === 'scroll'
      ? [originalData[originalData.length - 1], ...originalData, originalData[0]]
      : originalData;

  // Initial index: 1 for scroll (first original item), 0 for fade
  const [currentIndex, setCurrentIndex] = useState(animation === 'scroll' ? 1 : 0);
  const [transitioning, setTransitioning] = useState(false);


  const handleNext = () => {
    if (!transitioning) {
      setTransitioning(true);
      if (animation === 'scroll') {
        setCurrentIndex(prev => prev + 1);
      } else {
        setCurrentIndex(prev => (prev + 1) % originalData.length);
        // Match CSS transition duration
        setTimeout(() => setTransitioning(false), 500);
      }
    }
  };

  const handlePrev = () => {
    if (!transitioning) {
      setTransitioning(true);
      if (animation === 'scroll') {
        setCurrentIndex(prev => prev - 1);
      } else {
        setCurrentIndex(prev => (prev - 1 + originalData.length) % originalData.length);
        setTimeout(() => setTransitioning(false), 500);
      }
    }
  };

  // Handle infinite cycling for sliding by resetting index after transition
  const handleTransitionEnd = () => {
    if (animation === 'scroll') {
      if (currentIndex === 0) {
        setCurrentIndex(displayedData.length - 2); // Last original item
        setTransitioning(false);
      } else if (currentIndex === displayedData.length - 1) {
        setCurrentIndex(1); // First original item
        setTransitioning(false);
      } else {
        setTransitioning(false);
      }
    }
  };


  useEffect(() => {
    const intervalId = setInterval(() => {
      if (auto) {
        handleNext()
      }
    }, (timeout * 1000));
    return () => clearInterval(intervalId);
  }, [timeout, auto]);

  const parsePath = (url) => {
    const abs = /^(?:[a-z]+:)?\/\//i;
    return (abs.test(url)) ? url : "https://cdn1.bookmanager.com/i/" + window.san + url;
  }

  const formatURL = (url) => {
    url = url.replace("https://", "");
    url = url.replace("http://", "");
    url = url.replace("www.", "");
    return url;
  }


  const openURL = (image, index, _type = "frame") => {


    if (index !== currentIndex) { return; }

    if (image?.button_label && _type === "frame") {
      return;
    }

    if (formatURL(image.link)) {
      window.open(image.link, (image.tab) ? "_blank" : "_self");
    }
  }

  const drawItem = (item, style, index) => {

    const {
      headline = "",
      paragraph = "",
      button_label = "",
      link = "",
      tab = false
    } = item;

    return (
      <div onClick={() => { openURL(item, index, "frame") }} style={{ cursor : (currentIndex === index && formatURL(link) && !button_label) ? "pointer" : "default", pointerEvents: (currentIndex === index) ? "auto" : "none", position: 'relative', width: '100%', ...style }}>
        {/* Background Image Div */}
        <div
          style={{
            backgroundImage: `url(${parsePath(encodeURI(item.path))})`,
            backgroundSize: background_size,
            borderRadius: image_radius + "px",
            backgroundPosition: background_position,
            backgroundRepeat: "no-repeat",
            width: '100%',
            height: height + "px", // set a height or use another method to size the container
            opacity: image_opacity + "%",

          }}
        ></div>

        {/* Text Container */}
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            textAlign: content_text_align,
            color: '#fff',
            width: '100%', // adjust as needed
            padding: '0 20px', // optional for spacing
            height: height + "px", // set a height or use another method to size the container
          }}
        >
          <div style={{
            display: "flex",
            alignItems: content_justify,
            justifyContent: content_align,
            height: height + "px",
            padding: content_padding_y + "px " + content_padding_x + "px"
          }}>
            <div style={{"maxWidth" : (contained) ? content_max_width + "px" : "100%"}}>
              {headline && (
                <h2 className={'bm-txt-' + content_header_style}>
                  {headline}
                </h2>
              )}
              {paragraph && (
                <p className={'bm-txt-' + content_paragraph_style}>
                  <Html clear={false} className={""} html={paragraph} />
                </p>
              )}
              {button_label && (
                <div>
                  <Button
                    onClick={() => openURL(item, index, "button")}
                    className={'bm-btn-' + content_button_style}
                  >
                    {button_label}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div style={{ "height": height + "px", "position": "relative" }}>
      <div style={{ "borderRadius": image_radius + "px" }}>

        {(animation === "scroll" &&
          <div style={{ overflow: 'hidden', width: '100%', height: '100%' }}>
            <div
              style={{
                display: 'flex',
                width: `${displayedData.length * 100}%`,
                transform: `translateX(-${(currentIndex * 100) / displayedData.length}%)`,
                transition: transitioning ? 'transform 0.5s ease-in-out' : 'none',
              }}
              onTransitionEnd={handleTransitionEnd}
            >
              {displayedData.map((item, index) => (
                <div
                  key={index}
                  style={{ width: `${100 / displayedData.length}%`, height: '100%' }}
                >
                  {drawItem(item, { height: height + "px" }, index)}
                </div>
              ))}
            </div>
          </div>
        )}

        {/* FADE WRAPPER */}
        {(animation === "fade" &&
          <div style={{ position: 'relative', width: '100%', height: '100%' }}>
            {originalData.map((item, index) => {
              return drawItem(item, {
                position: 'absolute',
                width: '100%',
                height: height + "px",
                opacity: index === currentIndex ? 1 : 0,
                transition: 'opacity 0.5s ease-in-out',
              }, index)
            })}
          </div>
        )}

      </div>
      <Button className={"bm-btn-" + button_type} onClick={() => handlePrev()} style={{ position: 'absolute', left: 0, top: '50%', transform: 'translateY(-50%)', "zIndex": "2" }}>
        <LeftOutlined style={{ "minWidth": "20px" }} />
      </Button>
      <Button className={"bm-btn-" + button_type} onClick={() => handleNext()} style={{ position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)', "zIndex": "2" }} >
        <RightOutlined style={{ "minWidth": "20px" }} />
      </Button>
    </div>
  );
};


export default Carousel;
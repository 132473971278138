import React, { useState } from "react"
import { Button, Card, Divider, Row, Col, Space, Popconfirm, Modal, Form, message, Dropdown, Menu, Checkbox, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import AddressForm from "./AddressForm";
import { useSession } from "../../../util/Session";
import { apiCall } from "../../utilities/Api";
import { Link, useHistory } from "react-router-dom";
import PostalCodeFormatter from "../../formatters/PostalCodeFormatter";
import PhoneNumberFormatter from "../../formatters/PhoneNumberFormatter";
import { useForm } from "antd/lib/form/Form";
import conditional from "../../utilities/conditional";

export default function AccountAddressBook(props) {

    const {callback = () => {}} = props; 

    const { clearError = () => { } } = props;
    const [session, setSession] = useSession();
    const history = useHistory();
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedAddress, setSelectedAddress] = useState({});
    const [useBilling, setUseBilling] = useState((props.hasOwnProperty("saved") && props.saved.hasOwnProperty("account")) ? props.shipping.saved.account : false);
    const [type, setType] = useState("");



    let addresses = session.user.addresses;

    let has_default = false;
    addresses.forEach(element => {
        if (element.preferred) {
            has_default = true;
        }
    });

    const [billingIsDefault, setBillingIsDefault] = useState(!has_default);



    const onCreateAddress = (_status, _result) => {
        if (_status) {
            addresses = _result;
            setSession({ ...session, "user": { ...session.user, "addresses": _result } })

            let has_default = false;
            _result.forEach(element => {
                if (element.preferred) {
                    has_default = true;
                }
            });

            if (props.form) {
                setSelectedAddress(_result[_result.length - 1]);
                setUseBilling(false);
                setBillingIsDefault(false);
                props.form.setFieldsValue({ ..._result[_result.length - 1], "account": false });

            } else {
                setBillingIsDefault(!has_default);
            }

            message.success("Address saved successfully");
            setModalVisible(false);
        } else {
            message.error(_result.error);
        }
       
    }

    const saveAddress = (_fields) => {

       
        if(props?.form){
            props.form.setFieldsValue(_fields);
        }
   
     
        // remove optional
        if (!_fields.email_address) {
            delete _fields.email_address;
        }

       

        if (_fields.address_id) {
            apiCall("address/modify", _fields, (_status, _result) => {
                if (_status) {
                    setSession({ ...session, "user": { ...session.user, "addresses": _result } })

                    if (props.hasOwnProperty("saved")) {
                        let find = _result.find(item => item.address_id === _fields.address_id);
                        if (find) {
                            props.setSaved({ ...props.saved, "shipping": find });
                        }
                    }

                    let has_default = false;
                    _result.forEach(element => {
                        if (element.preferred) {
                            has_default = true;
                        }
                    });
                    setBillingIsDefault(!has_default);
                    callback(_result); 
                    message.success("Address saved successfully");
                    setModalVisible(false);
                } else {
                    message.error(_result.error);
                }
                

            });
        } else {
            apiCall("address/create", _fields, onCreateAddress);
        }
    }

    const drawAdd = () => {
        return (
            <Card tabIndex={0} aria-label="Add Address" onKeyDown={(e) => { if (e.key === "Enter") { selectAddress(0) } }} onClick={() => selectAddress(0)} style={{ "backgroundColor": "#fbfbfb", "border": "1px dashed #ccc", "cursor": "pointer" }} className="addressItem">
                <div style={{ "height": "232px", "textAlign": "center" }}>
                    <div className="shim" style={{ "height": "75px" }}></div>
                    <PlusOutlined style={{ "fontSize": "32px" }} />
                    <div className="shim"></div>
                    <div className="shim"></div>
                    <h3>Add Address</h3>
                </div>
            </Card>
        )
    }

    const onDeleteAddress = (_status, _result) => {
        if (_status) {
            addresses = _result;
            setSession({ ...session, "user": { ...session.user, "addresses": _result } })
            message.success("Address removed successfully");
        } else {
            message.error("There was an error.");
        }
    }

    const deleteAddress = (_address_id) => {
        apiCall("address/delete", { address_id: _address_id }, onDeleteAddress);
    }

    const drawAddresses = () => {
        return addresses.map((item, index) => {
            return drawAddress(item, index);
        })
    }

    const onSetDefaultShipping = (_status, _result) => {
        if (_status) {


            let has_default = false;
            _result.forEach(element => {
                if (element.preferred) {
                    has_default = true;
                }
            });

            setBillingIsDefault(!has_default);
            addresses = _result;
            setSession({ ...session, "user": { ...session.user, "addresses": _result } });
            clearError();

        }
    }

    const setDefaultShipping = (_set, _address_id) => {
        if (_set) {
            apiCall("address/setPreferred", { address_id: _address_id }, onSetDefaultShipping);
        }
    }

    const drawAddress = (_item, _key) => {
        let {
            name = "",
            company_name = "",
            address_id = 0,
            preferred,
            email_address = false,
            phone_number = false,
            phone_number_ext = "",
            street_address = "",
            street_address_2 = "",
            postal_code = "",
            country = "",
            province = "",
            city = "",
        } = _item;

        return (
            <Col key={_key} xs={24} sm={12} lg={8} >
                <Card className="addressItem">
                    <div>
                        {(name && <><strong>{name}</strong><br /></>)}
                        <span>{email_address}</span><br />
                        <span><PhoneNumberFormatter display country={country} value={phone_number} /> {(phone_number_ext && <> ext. {phone_number_ext}</>)}</span><br />
                        {(company_name && <><span>{company_name}</span><br /></>)}
                        <span>{street_address}</span><br />
                        {(street_address_2 && <><span>{street_address_2}</span><br /></>)}
                        <span>{city}, {province}</span><br />
                        <span>{country}</span><br />
                        <span><PostalCodeFormatter display value={postal_code} country={country} /></span><br />
                        <div className="shim"></div><div className="shim"></div><div className="shim"></div>
                        <Checkbox checked={(preferred) ? true : false} onChange={(e) => setDefaultShipping(!preferred, address_id)}>Default Shipping Address</Checkbox>
                    </div>
                    <div className="shim"></div><div className="shim"></div>
                    <Space>

                        <Button aria-label="Edit Address" className="asa-anchor" onClick={() => selectAddress(address_id)} style={{ "color": "#069" }} type="link">Edit</Button>
                        {(addresses.length > 0 && <span>|</span>)}
                        {(addresses.length > 0 &&
                            <Popconfirm
                                title="Are you sure wish to delete this address?"
                                onConfirm={() => deleteAddress(address_id)}
                                okText="Delete"
                                okButtonProps={{ type: "danger" }}
                                cancelText="Cancel"
                            >
                                <Button aria-label="Remove Address" className="asa-danger" style={{ "color": "#e70d3d" }} type="link">Remove</Button>
                            </Popconfirm>
                        )}
                    </Space>
                </Card>
            </Col>
        )
    }

    const selectAddress = (_address_id, _type) => {


        if (_type) {
            setType(_type);
        }
        let _add = addresses.find(item => item.address_id === _address_id);

        setSelectedAddress((_add) ? _add : {});

        setModalVisible(true);
    }

    const onSetDefault = (_status, _result) => {
        if (_status) {

            addresses = _result;

            let _add = addresses.find(item => item.preferred === true);

            if (_add) {
                updateShipping(_add);
            } else {
                updateShipping(0);
            }

            //updateShipping(_address_id); 
            setSession({ ...session, "user": { ...session.user, "addresses": _result } });
            clearError();

        }
    }



    const updateShipping = (_add) => {
        props.setSaved({ ...props.saved, "shipping": _add });
    }

    const setBilling = () => {
        setUseBilling(true);
        props.form.setFieldsValue({ ...session.user, "address_id": "", "name": session.user.first_name + " " + session.user.last_name, "account": true });
        apiCall("address/setPreferred", { address_id: 0 }, onSetDefault);
        updateShipping(0);
    }

    const setDefault = (_address_id, _type = "billing") => {
        let _address = addresses.find(item => item.address_id === _address_id);

        props.form.setFieldsValue({ ..._address, "account": false });
        setSelectedAddress((_address) ? _address : {});
        setUseBilling(false);
        apiCall("address/setPreferred", { address_id: _address_id }, onSetDefault);


    }

    const [form] = useForm();
    const drawModal = () => {
        return (
            <>
                <Modal destroyOnClose={true} footer={null} onCancel={() => setModalVisible(false)} open={modalVisible}>
                    <h3>{(Object.keys(selectedAddress).length === 0) ? "New" : "Edit"} Address</h3>
                    <div aria-hidden><Divider /></div>
                    <Form form={form} onFinish={(_fields) => saveAddress(_fields)} layout="vertical">
                        <AddressForm form={form} type={type} data={selectedAddress} />
                        <div aria-hidden><Divider /></div>
                        <div style={{ "float": "right" }}>
                            <Space>
                                <Button onClick={() => setModalVisible(false)}>Cancel</Button>
                                <Button htmlType="submit" type="primary">Save</Button>
                            </Space>
                        </div>
                        <br clear="all" />
                    </Form>
                </Modal>
            </>
        )
    }



    const drawDropdown = (_type) => {
        const menu = (
            <Menu style={{ "padding": "0px", "maxHeight": "300px", "overflowY": "auto" }}>
                <Menu.Item style={{ "borderBottom": "1px solid #eee" }} key={"billing"}>
                    <span onClick={() => setBilling()}>Use my account address</span>
                </Menu.Item>
                {addresses.map((item, index) => {
                    return (<Menu.Item onClick={() => setDefault(item.address_id, _type)} style={{ "borderBottom": "1px solid #eee" }} key={index}>
                        <span>{item.name}</span>
                        <span style={{ "display": "block", "marginTop": "-7px" }}><small>{item.street_address}</small></span>
                    </Menu.Item>)
                })}
            </Menu>
        );

        if (addresses.length <= 0) {
            return (<></>)
        }

        return (
            <>
                <Dropdown placement="topRight" overlay={menu} trigger={['click']}>
                    <Button onClick={e => e.preventDefault()} size="small" ><small>Change Address</small></Button>
                </Dropdown>
            </>
        )
    }


    const drawBilling = () => {

        return (<>
            <Card>
                <div style={{ "padding": "0px 3px" }}>
                    <strong>{session.user.first_name} {session.user.last_name}</strong><br />
                    <span>{session.user.email_address}</span><br />
                    {(session.user.company && <><span>{session.user.company}</span><br /></>)}
                    <span><PhoneNumberFormatter display country={session.user.country} value={session.user.phone_number} /> {(session.user.phone_number_ext && <> ext. {session.user.phone_number_ext}</>)}</span><br />
                    <span>{session.user.street_address}</span><br />
                    {(session.user.street_address_2 && <>
                        <span>{session.user.street_address_2}</span><br />
                    </>)}
                    <span>{session.user.city}, {session.user.province}</span><br />
                    <span>{session.user.country}</span><br />
                    <span><PostalCodeFormatter display value={session.user.postal_code} country={session.user.country} /></span><br />
                    <div className="shim"></div><div className="shim"></div>
                    <Checkbox checked={(billingIsDefault) ? true : false} onChange={(e) => setDefaultShipping(!billingIsDefault, "0")}>Default Shipping Address</Checkbox>
                </div>
                <div className="shim"></div><div className="shim"></div>

                <Button aria-label="Edit Account" style={{ "color": "#069" }} className="asa-anchor" type="link" onClick={() => history.push("/account/details")}>Edit Account</Button>
                <div className="shim"></div>
            </Card>

        </>)
    }

    const display = () => {

        if (!session.user.hasOwnProperty("addresses")) {
            return (<></>)
        }

        if (props.shipping) {

            let _address = addresses.find(item => item.preferred === true);
            if (!_address || useBilling) {
                return (
                    <>

                        {drawModal()}
                        <Card>
                            <div style={{ "height": "200px", "padding": "0px 3px" }}>
                                <Form.Item hidden name="address_id" initialValue={""}></Form.Item>
                                <Form.Item hidden name="name" initialValue={session.user.first_name + " " + session.user.last_name}></Form.Item>
                                <Form.Item hidden name="email_address" initialValue={session.user.email_address}></Form.Item>
                                {(session.user.company && <Form.Item hidden name="company" initialValue={session.user.company}></Form.Item>)}
                                <Form.Item hidden name="phone_number" initialValue={session.user.phone_number}></Form.Item>
                                <Form.Item hidden name="phone_number_ext" initialValue={session.user.phone_number_ext}></Form.Item>
                                <Form.Item hidden name="street_address" initialValue={session.user.street_address}></Form.Item>
                                <Form.Item hidden name="city" initialValue={session.user.city}></Form.Item>
                                <Form.Item hidden name="country" initialValue={session.user.country}></Form.Item>
                                <Form.Item hidden name="province" initialValue={session.user.province}></Form.Item>
                                <Form.Item hidden name="postal_code" initialValue={session.user.postal_code}></Form.Item>
                                <Form.Item hidden name="account" initialValue={true}></Form.Item>
                                <strong>{session.user.first_name} {session.user.last_name}</strong><br />
                                <span>{session.user.email_address}</span><br />
                                {(session.user.company && <><span>{session.user.company}</span><br /></>)}
                                <span><PhoneNumberFormatter display country={session.user.country} value={session.user.phone_number} /> {(session.user.phone_number_ext && <> ext. {session.user.phone_number_ext}</>)}</span><br />

                                <conditional.true value={(!session.user.street_address || !session.user.country || !session.user.postal_code)}>
                                    <div className="shim"></div><div className="shim"></div>
                                    <Typography.Paragraph type="danger">
                                        Shipping address is required for shipping. <br />Please add a shipping address to your Address Book below, or edit your <Link to="/account/details"><strong style={{ "color": "#ff222d" }}>Account Details.</strong></Link>
                                    </Typography.Paragraph>
                                </conditional.true>

                                <conditional.true value={(session.user.street_address && session.user.country && session.user.postal_code)}>
                                    <span>{session.user.street_address}</span><br />
                                    {(session.user.street_address_2 && <>
                                        <Form.Item hidden name="street_address_2" initialValue={session.user.street_address_2}></Form.Item>
                                        <span>{session.user.street_address_2}</span><br />
                                    </>)}
                                    <span>{session.user.city}, {session.user.province}</span><br />
                                    <span>{session.user.country}</span><br />
                                    <span><PostalCodeFormatter display value={session.user.postal_code} country={session.user.country} /></span><br />

                                </conditional.true>





                            </div>
                            <div className="shim"></div><div className="shim"></div>
                            <Space size="small">{drawDropdown("shipping")}<Button onClick={() => selectAddress(0, "shipping")} size="small"  ><small>Create New</small></Button> </Space>
                        </Card>
                    </>
                )
            }

            return (
                <>
                    {/* <pre>{JSON.stringify(session.user.addresses, null, 2)}</pre>
                    <div style={{"display" : "block"}}>----</div>
                <pre>{JSON.stringify(addresses, null, 2)}</pre> */}

                    {drawModal()}
                    <Card>
                        <div style={{ "height": "200px", "padding": "0px 3px" }}>
                            <Form.Item hidden name="address_id" initialValue={_address.address_id}></Form.Item>
                            <Form.Item hidden name="name" initialValue={_address.name}></Form.Item>
                            <Form.Item hidden name="email_address" initialValue={_address.email_address}></Form.Item>
                            {(_address.company_name && <Form.Item hidden name="company_name" initialValue={_address.company_name}></Form.Item>)}
                            <Form.Item hidden name="phone_number" initialValue={_address.phone_number}></Form.Item>
                            <Form.Item hidden name="phone_number_ext" initialValue={_address.phone_number_ext}></Form.Item>
                            <Form.Item hidden name="street_address" initialValue={_address.street_address}></Form.Item>
                            <Form.Item hidden name="city" initialValue={_address.city}></Form.Item>
                            <Form.Item hidden name="country" initialValue={_address.country}></Form.Item>
                            <Form.Item hidden name="province" initialValue={_address.province}></Form.Item>
                            <Form.Item hidden name="postal_code" initialValue={_address.postal_code}></Form.Item>
                            <Form.Item hidden name="account" initialValue={false}></Form.Item>
                            <strong>{_address.name}  <Button size="small" onClick={() => selectAddress(_address.address_id)}><small>Edit</small></Button></strong><br />
                            <span>{_address.email_address}</span><br />
                            {(_address.company_name && <><span>{_address.company_name}</span><br /></>)}
                            <span><PhoneNumberFormatter display country={_address.country} value={_address.phone_number} />{(_address.phone_number_ext && <> ext. {_address.phone_number_ext}</>)}</span><br />
                            <span>{_address.street_address}</span><br />
                            {(_address.street_address_2 && <>
                                <Form.Item hidden name="street_address_2" initialValue={_address.street_address_2}></Form.Item>
                                <span>{_address.street_address_2}</span><br /></>)}
                            <span>{_address.city}, {_address.province}</span><br />
                            <span>{_address.country}</span><br />
                            <span><PostalCodeFormatter display value={_address.postal_code} country={_address.country} /></span><br />
                        </div>
                        <div className="shim"></div><div className="shim"></div>
                        <Space size="small">{drawDropdown("shipping")}

                            {/* <Button onClick={() => history.push("/account/addresses")} size="small"  ><small>Address Book</small></Button>  */}
                            <Button onClick={() => selectAddress(0, "shipping")} size="small"  ><small>Create New</small></Button>
                        </Space>
                    </Card>
                </>
            )

        }

        return (
            <>

                {drawModal()}
                <h2>Address Book</h2>
                <div aria-hidden><Divider /></div>
                <Row gutter={[10, 10]}>
                    <Col key="bill" xs={24} sm={12} lg={8} >{drawBilling()}</Col>
                    {drawAddresses()}
                    <Col key="add" xs={24} sm={12} lg={8} >{drawAdd()}</Col>
                </Row>
            </>
        );

    }

    return display();

}
import React from "react";
import { Button, Modal, Input} from 'antd';
import {FacebookFilled, TwitterSquareFilled } from '@ant-design/icons';

export default function FeaturesSocial(props) {


    const {path, name} = props; 
   
    const shareFeature = () =>{

        Modal.info({
            icon : null,
            centered : true,
            title: name, 
            width:"auto",
            style:{"padding" : "0px"},
            content: <>
                        <p>To share this with others, copy this link (e.g. Ctrl-C) and then paste it into an email.</p>
                        <Input defaultValue={path} style={{"marginBottom" : "10px"}} />
                        <br />
                        <a target="_blank" aria-label="Share on Twitter (opens in a new tab)" rel="noopener noreferrer" href={"https://twitter.com/share?url="+path}><Button type="text" icon={<TwitterSquareFilled aria-hidden style={{"fontSize" : "25px", "color" : "#00acee"}} />} /></a>
                <a target="_blank" aria-label="Share on Facebook (opens in a new tab)" rel="noopener noreferrer" href={"https://www.facebook.com/sharer/sharer.php?u="+path}><Button type="text" icon={<FacebookFilled aria-hidden style={{"fontSize" : "25px", "color" : "#4267B2"}} />} /></a>
                    </>,
            okType:"default",
            okText:"Close"
        });

    }

    return(<>
            <Button onClick={() =>shareFeature()} className="small-btn" size="small">{props.children}</Button>
            </>
        )
}
import { Form, Input } from "antd";
import React from "react";

export default function TextAreaField(props) {

    const { data = {}, contact = {} } = props;

    const {
        id = "",
        label = "Text Area",
        type = "textarea",
        description = "",
        placeholder = "Enter text...",
        max_length = 500,
        rows = 3,
        required = false,
        required_message = "This field is required"
    } = data;

    let defaultValue = (contact.hasOwnProperty([id])) ? contact[id] : "";

    return (
        <Form.Item initialValue={defaultValue} extra={description} rules={[{ required: required, message: (required_message) ? required_message : "This field is required" }]} label={label} name={id} >
            <Input.TextArea size="large" showCount rows={rows} maxLength={max_length} placeholder={placeholder} />
        </Form.Item>
    )
}
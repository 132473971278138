import { Button, Popover } from "antd";
import React, { useEffect, useRef } from "react";
import { UserOutlined } from "@ant-design/icons";


export default function AccessiblePopover(props) {
    const { open = false, onOpenChange = () => {}, content, icon = <></>, label = "" } = props;
    const popoverRef = useRef(null);
    const triggerRef = useRef(null);

    useEffect(() => {
        if (open) {
            // Move focus inside the popover
            const focusableElements = popoverRef.current?.querySelectorAll("button, [href], input, select, textarea, [tabindex]:not([tabindex='-1'])");
            if (focusableElements?.length) {

                setTimeout(() => {
                    focusableElements[0].focus(); 
                }, 1)
             
              
            }

            // Handle Escape and Tab key navigation
            const handleKeyDown = (e) => {
                if (e.key === "Escape") {
                    onOpenChange(false);
                    triggerRef.current.focus(); // Restore focus to trigger button
                }

                if (e.key === "Tab") {
                    const focusable = Array.from(focusableElements);
                    const first = focusable[0];
                    const last = focusable[focusable.length - 1];

                    if (e.shiftKey && document.activeElement === first) {
                        e.preventDefault();
                        last.focus();
                    } else if (!e.shiftKey && document.activeElement === last) {
                        e.preventDefault();
                        first.focus();
                    }
                }
            };

            document.addEventListener("keydown", handleKeyDown);

            return () => {
                document.removeEventListener("keydown", handleKeyDown);
            };
        }
    }, [open, onOpenChange]);

    return (
        <Popover
            {...props}
            content={
                <div ref={popoverRef}>
                    <div tabIndex="0" aria-hidden="true" style={{"width" : "0px", "height" : "0px", "overflow" : "hidden", "outline" : "none"}}></div>
                    {content}
                </div>
            }
            open={open}
            onOpenChange={onOpenChange}
        >
             <Button
                ref={triggerRef}
                onClick={() => onOpenChange(!open)}
                className="styled_btn asa-btn"
                type="primary"
                size={props.size}
                aria-expanded={open} // Announce expanded state
                aria-controls="login-popover" // Associate button with popover
                aria-label="Login"
            >
                {icon} {label}
            </Button>
        </Popover>
    );
}
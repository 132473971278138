import React, { useState, useEffect } from "react";
import { Button, Layout, Divider, Card, Row, Col, Result, Modal, Table, message } from 'antd';
import OrderItem from "./OrderItem";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { apiCall, apiDownload } from "../../utilities/Api";
import { convertLineBreaks, pluralize } from "../../../util/Utils";
import { Link } from "react-router-dom";
import moment from "moment";
import 'moment-timezone';
import { useSession } from "../../../util/Session";
import conditional from "../../utilities/conditional";
import { existsInWishlist } from "../../../util/Utils";
import { DownloadOutlined } from '@ant-design/icons';
import Html from "../../utilities/Html";

function OrderDetails() {


    const [session, setSession] = useSession();
    const { id } = useParams();

    const loc = useLocation();
    const history = useHistory();
    let has_data = false;

    if (loc.state) {
        has_data = true;
    }

    const [results, setResults] = useState((has_data) ? loc.state.data : {});



    const { custom_form = {} } = results;
    const { delivery_choice_label = "", form_header = "", values = [] } = custom_form;



    const checkEmailed = (_arr = []) => {
        let ret = false;
        _arr.forEach(item => {
            if (item.cart_info?.cart_notes?.gift_card_info?.recipient_email) {
                ret = true;
            }
        })

        if (results.delivery_key === "electronic") {
            return false;
        }
        return ret;
    }

    const contains_emailed = checkEmailed(results?.items);

    const [error, setError] = useState(false);
    const [loading, setLoading] = useState((has_data) ? false : true);



    const removeCols = [
        {
            title: 'eisbn',
            dataIndex: 'eisbn',
            key: 'eisbn',
            width: "50px",
            render: (e, f) => {
                console.log(f);
                return (<img alt={f.title} style={{ "maxWidth": "30px", "maxHeight": "50px" }} src={"https://cdn1.bookmanager.com/i/m?b=" + e + "&cb=" + f.cover_image_cache} />)
            }
        },
        {
            title: 'title',
            dataIndex: 'title',
            key: 'title'
        },
    ]


    const showConfirm = (_result, _wishlist_id) => {

        if (!loc.search) {
            return;
        }

        let matches = [];
        let eisbns = [];

        _result.items.forEach(item => {
            let found = existsInWishlist(session, item.title_info.eisbn);
            if (found) {
                eisbns.push(item.title_info.eisbn);
                matches.push({
                    title: item.title_info.title,
                    eisbn: item.title_info.eisbn,
                    key: item.title_info.eisbn
                })
            }
        });

        if (!matches.length > 0) {
            return;
        }

        const send = (_eisbns) => {
            apiCall("titlelist/removeItemsFromList", { eisbns: JSON.stringify(_eisbns), list_id: _wishlist_id }, (_status, _result) => {
                if (_status) {
                    apiCall("session/get", {}, (_status, _result) => {
                        if (_status) {
                            setSession(_result);
                        }
                    })
                    message.success("Items removed from wishlist.");
                }
            })
        }

        Modal.confirm({
            content: <>
                <strong>Remove purchased items from your favourites? </strong>
                <div className="shim"></div><div className="shim"></div><div className="shim"></div>
                <Table
                    showHeader={false}
                    size="small"
                    bordered
                    scroll={{ y: 250 }}
                    columns={removeCols}
                    dataSource={matches}
                    pagination={false}
                />
            </>,
            okText: "Remove",
            cancelText: "Keep",
            onOk() {
                send(eisbns);
            },
            onCancel() {
                console.log('Keep');
            },
        });
    }






    const onGetPreviousOrder = (_status, _result) => {

        if (_status) {
            setResults(_result);
            showConfirm(_result, session.wishlist_id);
        } else {
            setError(true);
        }
        setLoading(false);
    }

    const getPreviousOrder = () => {
        window.payment = "";
        if (session.logged_in) {
            apiCall("order/get", { "order_id": id }, onGetPreviousOrder);
        }

    }

    useEffect(getPreviousOrder, [])

    const displayLoading = () => {
        return (<></>);
    }

    const displayError = () => {
        return (<></>);
    }

    const displayStatus = () => {

        if (loading) return displayLoading();
        if (error) return displayError();
        return display();
    }



    const onUpdateSession = (_status, _result) => {
        if (_status) {
            setSession({ ...session, "cart": _result.cart });
        }
    }

    const updateSession = () => {
        if (has_data) {
            if (loc.search) {

                apiCall("session/get", {}, onUpdateSession)

            }
        }
    }

    useEffect(updateSession, [])


    const drawTitle = () => {


        switch (results.delivery_key) {
            case "electronic":
                return (<>Thank you! Your order has been submitted.</>);
                break;
            case "pickup":
                return (<>Thank you! Your order has been submitted.</>);
            case "ship":
                return (<>Thank you! Your order has been submitted.</>);
            case "quote":
                return (<>Your quote request has been submitted.</>);
            default:
                return (<>Thank you! Your order has been submitted.</>);
        }

    }

    const drawMessage = () => {
        switch (results.delivery_key) {
            case "electronic":
                return (<>You'll receive a confirmation email within a few minutes with your order details. <strong>This will not contain a gift card redemption code, and is just a confirmation that we received your order.</strong><br /><br /></>);
                break;

            case "pickup":
                return (<>You'll receive a confirmation email within a few minutes with your order details. </>);
            case "ship":
                return (<>You'll receive a confirmation email within a few minutes with your order details.</>);
            case "quote":
                return (<>You'll receive a confirmation email within a few minutes with your order details.</>);
            default:
                return (<>You'll receive a confirmation email within a few minutes with your order details.</>);
        }

    }

    const drawSubtitle = () => {


        switch (results.delivery_key) {

            case "electronic":
                return (
                    <div style={{ "textAlign": "center" }}>
                        <strong style={{ 'color': '#5f5f5f', "fontSize": "16px" }}>When will your recipient(s) receive gift card code(s)?</strong><br />
                        <div className='shim'></div>
                        <div style={{ 'color': '#5f5f5f' }}>Your recipient(s) will receive gift card codes once we have confirmed payment and processed your order within <strong>business hours.</strong> You will receive another email once codes have been sent.<span> <br /><br />For immediate assistance, please call {(window.sitesettings.store_info.phone) ? window.sitesettings.store_info.phone : window.sitesettings.store_info.phone_2}.</span></div>
                    </div>
                );
                break;
            case "pickup":
                return (
                    <div style={{ "textAlign": "center" }}>
                        <strong style={{ 'color': '#5f5f5f', "fontSize": "16px" }}>When can you pick up your order?</strong><br />
                        <div className='shim'></div>
                        <div style={{ 'color': '#5f5f5f' }}>You'll receive {communication_preference === 'phone' ? 'a call' : (communication_preference === "text") ? 'a text' : 'an email'} when your order is ready for in-store pick up. <br />Pick up is available during <Link aria-label="View business hours (opens in a new tab)" style={{ "color": "#01739e" }} target="_blank" rel="noopener noreferrer" to='/contact-hours/'><strong>business hours</strong></Link>.
                            <br />
                            {((window.sitesettings.store_info.phone || window.sitesettings.store_info.phone_2) && <span> For immediate assistance, please call {(window.sitesettings.store_info.phone) ? window.sitesettings.store_info.phone : window.sitesettings.store_info.phone_2}.</span>)}
                            {((!window.sitesettings.store_info.phone && !window.sitesettings.store_info.phone_2) && <span> For immediate assistance, please email  <a style={{ "color": "#01739e" }} href={"mailto:" + window.sitesettings.store_info.email}><strong>{window.sitesettings.store_info.email}</strong></a></span>)} </div>
                    </div>
                );
            case "ship":
                return (<div style={{ 'color': '#5f5f5f', "textAlign": "center" }}></div>);
            case "quote":
                return (<div style={{ 'color': '#5f5f5f', "textAlign": "center" }}>We will review your quote and comments during regular business hours. We will contact you to discuss your needs before finalizing any items that are in stock, or need to be ordered.</div>);
            default:
                return (<div style={{ 'color': '#5f5f5f', "textAlign": "center" }}>You'll receive a confirmation email with your order details shortly.</div>);
        }

    }


    // const drawSubtitle = () => {
    // 	if (results.delivery_type === 'Pick up at store') {
    // 		return (<div style={{"textAlign" : "center"}}>
    // 			<strong style={{'color' : '#5f5f5f'}}>When can you pick up your order?</strong><br />
    // 			<div className='shim'></div>
    // 			<div style={{'color' : '#5f5f5f'}}>You'll receive {communication_preference === 'phone' ? 'a call' : (communication_preference === "text") ? 'a text' : 'an email'} when your order is ready for in-store pick up. Pick up is available during <Link style={{"color" : "#01739e"}} to='/contact-hours/'><strong>business hours</strong></Link>. For immediate assistance, please call {window.sitesettings.store_info.phone}.</div>
    // 		</div>);
    // 	} else {
    // 		return (<div style={{'color' : '#5f5f5f', "textAlign" : "center"}}>You'll receive a confirmation email with your order details shortly.</div>);
    // 	}
    // }


    const drawOrderComplete = () => {


        if (!loc.search) {
            return (<></>);
        }


        return (<>

            <Result
                icon={<></>}
                style={{ "paddingTop": "0px", 'margin': '0 auto' }}
                className="halfWidth"
                status="success"
                title={<span style={{ 'display': 'block', 'textAlign': 'center', "lineHeight" : "120%" }}><strong>{drawTitle()}</strong>

                    <div style={{ 'color': '#5f5f5f' }}></div>
                </span>}
                subTitle={<span style={{ 'display': 'block', 'textAlign': 'center' }}>

                    <div style={{ "color": "#5f5f5f" }}>{drawMessage()}</div>
                    <div style={{ "color": "#e70d3d" }}><em>Check your junk or spam folder if you do not receive an email.</em></div>
                    <br />
                    {drawSubtitle()}


                    {(contains_emailed && <>
                        <br />
                        <strong>Confirmation email will not contain a gift card redemption code, and is just a confirmation that we received your order.</strong>
                        <br /><br />
                        <strong style={{ 'color': '#5f5f5f', "fontSize": "16px" }}>When will your recipient(s) receive gift card code(s)?</strong><br />
                        <div className='shim'></div>
                        <div style={{ 'color': '#5f5f5f' }}>Your recipient(s) will receive gift card codes once we have confirmed payment and processed your order within <strong>business hours.</strong> You will receive another email once codes have been sent.<span> <br /><br />For immediate assistance, please call {(window.sitesettings.store_info.phone) ? window.sitesettings.store_info.phone : window.sitesettings.store_info.phone_2}.</span></div>
                    </>)}

                </span>}
                extra={<Link to="/"><Button>Continue Shopping</Button></Link>}
            />

        </>)

    }


    const { summary = {}, order_number = 0, order_date = 0, communication_preference = "phone", delivery_type = "", payment_type = "", payment_message = "", purchaser_name = "" } = results;
    const { discounts = "", promo_codes = [], total_gift_card_amount_used = "0.00", original_price = "", total_owing = "0.00", item_count = 0, taxes = "0.00", subtotal = "0.00", total = "0.00", shipping = "0.00" } = summary;


    const drawTaxes = (_arr) => {

        return _arr.map((item, index) => {

            return (
                <Row key={item}>
                    <Col span={17}><small>{(item.type) ? <>{item.type} </> : "Tax"}</small></Col>
                    <Col span={7}><span style={{ "textAlign": "right", "display": "block" }}><small>${item.amount}</small></span></Col>
                </Row>
            )
        })

    }


    const drawPromoLineItems = (_arr, show_ship = false) => {
        if (!_arr) {
            return (<></>);
        }
        return _arr.map((item, index) => {

            if (((show_ship) && item.type !== "freeship") || ((!show_ship) && item.type === "freeship")) {
                return (<></>);
            }

            // Garbage coming in? 
            if (item.amount === "0.00") {
                return (<></>)
            }

            return (
                <Row>
                    <Col span={17}><span style={{ "color": "#e70d3d" }}><small>{item.description}</small></span></Col>
                    <Col span={7}><span style={{ "color": "#e70d3d", "textAlign": "right", "display": "block" }}><small>- ${item.amount}</small></span></Col>
                </Row>
            )
        })
    }




    const drawSummary = () => {

        return (

            <Card className="adjust-margin" title={(<div><strong style={{ "fontSize": "18px" }}>Order Summary</strong><span style={{ "display": "block", "fontSize": "12px" }}>{item_count} {pluralize(item_count, "title", "s")}</span></div>)}>

                <conditional.true value={(original_price)}>
                    <Row>
                        <Col span={17}>Price</Col>
                        <Col span={7}><span style={{ "textAlign": "right", "display": "block" }}>${original_price}</span></Col>
                    </Row>
                </conditional.true>

                <conditional.true value={((original_price) && (discounts) && discounts !== "0.00")}>
                    <Row>
                        <Col span={17}><span style={{ "color": "#e70d3d" }}><small>Discount</small></span></Col>
                        <Col span={7}><span style={{ "textAlign": "right", "display": "block", "color": "#e70d3d" }}><small> - ${discounts}</small></span></Col>
                    </Row>
                </conditional.true>

                <conditional.true value={(promo_codes.length > 0)}>
                    {drawPromoLineItems(promo_codes)}
                </conditional.true>



                <conditional.true value={(total_gift_card_amount_used !== "0.00")}>
                    <Row>
                        <Col span={17}><span style={{ "color": "#e70d3d" }}>Gift Cards</span></Col>
                        <Col span={7}><span style={{ "textAlign": "right", "display": "block", "color": "#e70d3d" }}>-${total_gift_card_amount_used}</span></Col>
                    </Row>
                </conditional.true>

                <Row>
                    <conditional.true value={((discounts && discounts !== "0.00") && (original_price))}>
                        <div aria-hidden><Divider style={{ "margin": "10px 0px" }} /></div>
                    </conditional.true>
                    <Col span={17}>Subtotal</Col>
                    <Col span={7}><span style={{ "textAlign": "right", "display": "block" }}>${subtotal}</span></Col>
                </Row>

                <conditional.true value={((!original_price) && (discounts) && discounts !== "0.00")}>
                    <Row>
                        <Col span={17}><span style={{ "color": "#e70d3d" }}>Discounts</span></Col>
                        <Col span={7}><span style={{ "textAlign": "right", "display": "block", "color": "#e70d3d" }}>${discounts}</span></Col>
                    </Row>
                </conditional.true>


                {drawTaxes(taxes)}

                <conditional.true value={(shipping !== "0.00")}>
                    <Row>
                        <Col span={17}><small>Shipping</small></Col>
                        <Col span={7}><span style={{ "textAlign": "right", "display": "block" }}><small>${shipping}</small></span></Col>
                    </Row>
                </conditional.true>

                <conditional.true value={(promo_codes.length > 0)}>
                    {drawPromoLineItems(promo_codes, true)}
                </conditional.true>



                <div aria-hidden><Divider style={{ "margin": "10px 0px", "marginBottom": "20px" }} /></div>
                <Row>
                    <Col span={17}><strong style={{ "fontSize": "18px", "color": "#202020" }}>Total</strong></Col>
                    <Col span={7}><strong><span style={{ "textAlign": "right", "display": "block", "fontSize": "16px", "color": "#202020" }}>${summary.total_owing}</span></strong></Col>
                </Row>

            </Card>
        )

    }


    const downloadExcel = (_order_id) => {
        apiDownload({ order_id: _order_id }, "order/getExcel");
    }


    const drawFormFields = () => {

        // const { custom_form = {} } = data;
        // const { delivery_choice_label = "", form_header = "", values = [] } = custom_form;


        if(!delivery_choice_label){
            return(<></>)
        }

        const columns = [
            {
                title : <small>Field</small>,
                dataIndex : "label", 
                render : (e) => {
                    return(<em>{e}</em>)
                }
            },
            {
                title : <small>Value</small>,
                dataIndex : "value"
            }
        ]
        return (
            <div>
                <div className="shim"></div><div className="shim"></div>
                <Card className="bc c avoid-cut print-pad-thin" >
                    <div><strong>{form_header}</strong></div>
                    <Divider dashed style={{"margin" : "10px 0px"}} />
                    <Table showHeader={false} className="mediumTable" pagination={false} columns={columns} dataSource={values} />
                </Card>
                <div className="shim"></div><div className="shim"></div>
            </div>

        )
    }


    const display = () => {



        return (
            <div>


                <div style={{ "float": "right" }}>
                    <Button onClick={() => history.goBack()} style={{ "float": "right" }}>Back</Button>
                </div>

                {/* HIDE DOWNLOAD EXCEL BUTTON IF IT'S A QUOTE */}
                {(((results.hasOwnProperty("delivery_key"))) && <>
                    <span><h2>Order #{order_number} </h2> <Button onClick={() => downloadExcel(order_number)} size="small" icon={<DownloadOutlined aria-hidden />}><small> &nbsp;Download Excel</small></Button></span>
                </>)}
                <div aria-hidden><Divider /></div>


                {drawOrderComplete()}

                <Layout className="responsiveSider" >
                    <Layout.Sider className="responsiveSummaryLeft adjust-padding" width="none" theme="light">
                        <Card >
                            <div><strong>Date</strong></div>
                            <div>{moment(order_date * 1000).tz('America/Los_Angeles').format('MMM DD, YYYY')}</div>
                            <div className="shim" />

                            <div><strong>Delivery</strong></div>
                            <div>{delivery_type}</div>
                            <div className="shim" />

                            <div><strong>Payment</strong></div>
                            <div>{payment_message}</div>

                            <conditional.true value={(purchaser_name)}>
                                <div className="shim" />
                                <div><strong>Ordered by</strong></div>
                                <div>{purchaser_name}</div>
                            </conditional.true>



                            <conditional.true value={(results.delivery_key === "ship")}>
                                <div aria-hidden><Divider dashed style={{ "marginTop": "10px", "marginBottom": "10px" }} /></div>

                                <strong>Shipping</strong>

                                <conditional.true value={(results.shipping.orgname)}>
                                    <br />{results.shipping.orgname}
                                </conditional.true>

                                <conditional.true value={(results.shipping.name)}>
                                    <br />{results.shipping.name}
                                </conditional.true>

                                <conditional.true value={(results.shipping.email)}>
                                    <br /><a href={"mailto:" + results.shipping.email}>{results.shipping.email}</a>
                                </conditional.true>


                                <conditional.true value={(results.shipping.addr1)}>
                                    <br />{results.shipping.addr1}
                                </conditional.true>
                                <conditional.true value={(results.shipping.addr2)}>
                                    <br />{results.shipping.addr2}
                                </conditional.true>
                                <conditional.true value={(results.shipping.city)}>
                                    <br />{results.shipping.city}, {results.shipping.prov} {results.shipping.country}
                                </conditional.true>
                                <conditional.true value={(results.shipping.phone)}>
                                    <br />{results.shipping.phone}
                                </conditional.true>
                                <conditional.true value={(results.shipping.provider)}>
                                    <br />{results.shipping.provider}
                                </conditional.true>
                            </conditional.true>


                        </Card>
                        <br />
                        <conditional.true value={(results.notes || results.purchaseorder)}>
                            <>
                                <Card>
                                    <conditional.true value={(results.notes)}>
                                        <strong>Notes</strong>

                                        <br />
                                        <span style={{ "whiteSpace": "pre-line" }}>{results.notes.split("\\n").join("\n")}</span>

                                        {/* <span style={{"whiteSpace" : "pre-line"}}>{ <Html className="" html={convertLineBreaks(results.notes)} />}</span> */}
                                        <br />
                                    </conditional.true>
                                    <conditional.true value={(results.purchaseorder)}>
                                      
                                        <strong>Purchase Order</strong>
                                        <br />{results.purchaseorder}

                                    </conditional.true>

                                </Card>
                                <br />
                            </>
                        </conditional.true>






                        {drawSummary()}

                        {drawFormFields()}

                        <br />



                        <conditional.true value={(payment_type !== "quote")}>
                            <Card >
                                <div><strong>Payments </strong></div>
                                <div aria-hidden><Divider /></div>
                                <conditional.true value={total_gift_card_amount_used !== "0.00"}>
                                    <Row>
                                        <Col flex="auto">Gift cards redeemed</Col>
                                        <Col style={{ "textAlign": "right" }} flex="auto"><span style={{ "color": "#e70d3d" }}>-${total_gift_card_amount_used}</span></Col>
                                    </Row>
                                </conditional.true>

                                <conditional.true value={(total_owing !== "0.00")}>
                                    <Row>
                                        <Col flex="80px">{payment_message}</Col>
                                        <Col style={{ "textAlign": "right" }} flex="auto"><strong>${total_owing}</strong></Col>
                                    </Row>
                                </conditional.true>

                            </Card>
                        </conditional.true>




                    </Layout.Sider>
                    <Layout.Content style={{ "width": "auto" }} >
                        <OrderItem show_batch max hide_header={true} data={results} />
                    </Layout.Content>
                </Layout>
            </div>
        );

    }

    return displayStatus();

}

export default OrderDetails;
import React from "react";
import { Map, Marker, Popup, TileLayer } from "react-leaflet";
import Html from "../components/utilities/Html";
import moment from "moment";
import conditional from "../components/utilities/conditional";

function ContactLayout() {

    const contact_styles = window.sitesettings.styles.contact_page;

    let header_fontSize = "";
    let header_fontFamily = "";
    let header_color = "";
    let header_textShadow = "";

    if (contact_styles["ct-title"]) {
        header_fontSize = contact_styles["ct-title"]["font-size"];
        header_fontFamily = contact_styles["ct-title"]["font-family"];
        header_color = contact_styles["ct-title"].color;
        header_textShadow = contact_styles["ct-title"]["text-shadow"];
    }

    let bg_image = "";
    let bg_size = "";
    let bg_repeat = "";
    let bg_color = "";
    // let bg_position = "";

    if (contact_styles["ct-bg"]) {
        bg_image = contact_styles["ct-bg"]["background-image"];
        bg_size = contact_styles["ct-bg"]["background-size"];
        bg_repeat = contact_styles["ct-bg"]["background-repeat"];
        bg_color = contact_styles["ct-bg"]["background-color"];
        // bg_position = contact_styles["ct-bg"]["background-position"];
    }

    let content_fontFamily = "";
    let content_color = "";
    let content_textShadow = "";
    let content_bgImage = "";
    let content_bgSize = "";
    let content_bgRepeat = "";
    let content_bgColor = "";
    let content_bgPosition = "";


    let special_fontFamily = content_fontFamily
    let special_color = content_color
    let special_textShadow = content_textShadow
    let special_bgImage = content_bgImage
    let special_bgSize = content_bgSize
    let special_bgRepeat = content_bgRepeat
    let special_bgColor = content_bgColor
    let special_bgPosition = content_bgPosition

    if (contact_styles["ct-loc"]) {
        content_fontFamily = contact_styles["ct-loc"]["font-family"];
        content_color = contact_styles["ct-loc"]["color"];
        content_textShadow = contact_styles["ct-loc"]["text-shadow"];
        content_bgImage = contact_styles["ct-loc"]["background-image"];
        content_bgSize = contact_styles["ct-loc"]["background-size"];
        content_bgRepeat = contact_styles["ct-loc"]["background-repeat"];
        content_bgColor = contact_styles["ct-loc"]["background-color"];
        content_bgPosition = contact_styles["ct-loc"]["background-position"];
    }


    if (contact_styles["ct-special-hours"]) {
        special_fontFamily = contact_styles["ct-special-hours"]["font-family"];
        special_color = contact_styles["ct-special-hours"]["color"];
        special_textShadow = contact_styles["ct-special-hours"]["text-shadow"];
        special_bgImage = contact_styles["ct-special-hours"]["background-image"];
        special_bgSize = contact_styles["ct-special-hours"]["background-size"];
        special_bgRepeat = contact_styles["ct-special-hours"]["background-repeat"];
        special_bgColor = contact_styles["ct-special-hours"]["background-color"];
        special_bgPosition = contact_styles["ct-special-hours"]["background-position"];
    }




    const displayStatus = () => {

        return display();

    }


    function minToTime(mins) {
        if (mins >= 24 * 60 || mins < 0) {
            return "";
        }
        var h = mins / 60 | 0,
            m = mins % 60 | 0;
        return moment.utc().hours(h).minutes(m).format("h:mmA");
    }


    const drawDayHours = (_day) => {

        if (_day.length > 1) {
            return (<>
                <td className="time centered" title="Opens at:">{minToTime(_day[0])}</td>
                <td className="time centered" title="Closes at:">{minToTime(_day[1])}</td>
            </>)
        } else {
            return <td colSpan={2} className="day centered">CLOSED</td>
        }

    }

    const dayLabels = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]
    const specialDayLabels = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
    const monthLabels = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
    const drawWeekdays = () => {


        if (window.ws_hours.length < 1) {
            return (<></>);
        }


        let _special = parseSpecial();

        return window.ws_hours.weekdays.map((_day, i) => {

            // check special 

            _special.filter((_i) => {
                if (_i.day === dayLabels[i]) {
                    return _i;
                } else {

                    return false;

                }
            })


            if (_day.length > 0) {

            }


            return (
                <tr key={i} className="contact_hours_row">
                    <td className="day label" title="Day"><span><span>{dayLabels[i]}</span><span className="Special">{(dayIsSpecial(i) && "*")}</span></span></td>

                    {drawDayHours(_day)}
                </tr>
            )
        })

    }


    const checkRange = ({ day, mon }) => {

        let current_month = moment().format("M");
        let next_month = moment().add(1, "month").format("M");
        if (mon == current_month || mon == next_month) {
            return true;
        }

        return false;



    }

    const inRange = (d1, d2) => {




        let diff = Math.abs(d1.getTime() - d2.getTime());
        let diff_days = Math.round(diff / (1000 * 60 * 60 * 24));
        return (diff_days < 29) ? true : false;

    };


    const parseSpecial = () => {

        let today = new Date();

        if (window.ws_hours.length < 1) {
            return [];
        }

        return window.ws_hours.singles.filter((_day) => {


            return checkRange(_day);

            return inRange(today, new Date(today.getFullYear(), _day.mon - 1, _day.day, 0, 0, 0, 0));


        }).map((_day, i) => {

            let d; 
            if(moment().format("M") == 12 && _day.mon == 1){
                d = new Date(today.getFullYear() + 1, _day.mon - 1, _day.day, 0, 0, 0, 0);
            } else {
                d = new Date(today.getFullYear(), _day.mon - 1, _day.day, 0, 0, 0, 0);
            }
          
            

            return {
                "label": specialDayLabels[d.getDay()] + " " + monthLabels[d.getMonth()] + " " + d.getDate(),
                "date": d.getTime(),
                "range": _day.range,
                "day": specialDayLabels[d.getDay()]
            }

        })

    }


    const dayIsSpecial = (_day) => {



        let ret = false;
        let _special = parseSpecial();



        for (let i = 0; i < _special.length; i++) {


            let dotw = moment(_special[i].date);

            dotw.add(-1, "day");
            if (_day === dotw.day()) {
                ret = true;
            }

            if (dotw.diff(moment().add(-2, "days")) < 1) {
                ret = false;
            }
        }

        return ret;
    }


    const hasSpecial = () => {

        let _special = parseSpecial();
        //_special.sort((a, b) => (a.date > b.date) ? 1 : -1)

        let future = _special.filter((item) => {
            let today = moment();
            today.add(-1, 'day');

            let diff = moment(item.date).diff(today, 'minutes');


            if (diff > 0) {
                return item;
            } else {
                return false;
            }

        })


        if (future.length > 0) {
            return true;
        } else {
            return false;
        }

    }



    const drawSpecial = () => {

        let _special = parseSpecial();

        _special.sort((a, b) => {
            return (a.date > b.date) ? 1 : -1
        })
        let future = _special.filter((item) => {
            let today = moment();
            today.add(-2, 'days');
            let diff = moment(item.date).diff(today, 'days');
            if (diff > 0) {
                return item;
            } else {
                return false;
            }

        })


        return _special.map((_day, i) => {
            return (
                <tr key={i} className="contact_hours_rows.Special">
                    <td className="day label Special">* {_day.label}</td>
                    {drawDayHours(_day.range)}
                </tr>
            )
        })

    }

    const drawMarkers = () => {


        return (
            <Marker key={"marker"} position={[window.sitesettings.store_info.latitude, window.sitesettings.store_info.longitude]}>
                <Popup ><strong>{window.sitesettings.store_info.name}</strong><br />
                    <span>{window.sitesettings.store_info.address}</span><br />
                    <span>{window.sitesettings.store_info.city}, {window.sitesettings.store_info.province} {window.sitesettings.store_info.country}</span><br />
                    <span>{window.sitesettings.store_info.postal_code}</span><br />
                </Popup>
            </Marker>
        )



        // return results.map((contact, i) => {

        //     let {lng, lat} = contact.lat_lng;
        //     return (
        //         <Marker key={i} position={[lat, lng]}> 
        //             <Popup ><strong>{contact.altname}</strong><br />
        //             <span>{contact.sc_addr_array.address}</span><br />
        //             <span>{contact.sc_addr_array.city}, {contact.sc_addr_array.prov} {contact.sc_addr_array.country}</span><br />
        //             <span>{contact.sc_addr_array.postal}</span><br />
        //             </Popup>
        //         </Marker>
        //         )
        // })

    }


    const display = () => {


        if (!window.ws_hours) {
            return (<></>)
        }


        return (<div style={{ "backgroundPosition": content_bgPosition, "marginTop": "-20px", "backgroundColor": bg_color, "backgroundRepeat": bg_repeat, "backgroundSize": bg_size, "backgroundImage": "url(" + bg_image + ")" }}>
            <div className="shim" style={{ "height": "20px" }}></div>
            <div className="shim show-block" style={{ "height": "20px" }}></div>
            <div style={{ "fontSize": "24px" }}><div id="contactTitle" style={{ "fontSize": header_fontSize, "fontFamily": header_fontFamily, "color": header_color, "textShadow": header_textShadow }}>Contact &amp; Hours</div></div>

            <div style={{ "maxWidth": "700px", "margin": "0 auto" }}>
                <div style={{ "fontFamily": content_fontFamily, "color": content_color, "textShadow": content_textShadow, "backgroundImage": "url(" + content_bgImage + ")", "backgroundSize": content_bgSize, "backgroundRepeat": content_bgRepeat, "backgroundColor": content_bgColor, "backgroundPosition": content_bgPosition }} className="contact_box">

                    <div className="contact_address">
                        {window.sitesettings.store_info.name}<br />
                        {window.sitesettings.store_info.address} <br />
                        {window.sitesettings.store_info.city}, {window.sitesettings.store_info.province} {window.sitesettings.store_info.postal_code} <br />
                        {window.sitesettings.store_info.country}
                    </div>


                    <conditional.true value={window.sitesettings.store_info.phone}>
                        <div className="contact_phone"><a style={{ "color": "#069" }} aria-label="Store phone number" href={"tel:" + window.sitesettings.store_info.phone}>{window.sitesettings.store_info.phone}</a></div>
                    </conditional.true>
                    <conditional.true value={window.sitesettings.store_info.phone_2}>
                        <div className="contact_phone"><a style={{ "color": "#069" }} aria-label="Alternate store phone number" href={"tel:" + window.sitesettings.store_info.phone_2}>{window.sitesettings.store_info.phone_2}</a></div>
                    </conditional.true>
                    {(window.sitesettings.store_info.toll_free && <div className="contact_toll_free"> <a style={{ "color": "#069" }} aria-label="Store toll-free phone number" href={"tel:" + window.sitesettings.store_info.toll_free}>toll free {window.sitesettings.store_info.toll_free}</a></div>)}
                    <div className="contact_email"><a style={{ "color": "#069" }} href={"mailto:" + window.sitesettings.store_info.email}>{window.sitesettings.store_info.email}</a></div>

                    <br /><br />

                    {(!Array.isArray(window.ws_hours) &&
                        <div className="contact_hours">
                            <div className="title">{window.ws_hours.title}</div>
                            <table className="contact_hours_table" id="contact_hours_table">
                                <tbody>
                                    <tr>
                                        <td key="d" className="label top ruled"></td>
                                        <td key="e" className="label top time ruled Open centered">Open</td>
                                        <td key="f" className="label top time ruled Close centered">Close</td>
                                    </tr>
                                    {drawWeekdays()}

                                    <tr>
                                        <td colSpan="3">
                                            <table className="contact_hours_table special-hours-table css-applicator" style={{ "fontFamily": special_fontFamily, "color": special_color, "textShadow": special_textShadow, "backgroundImage": "url(" + special_bgImage + ")", "backgroundSize": special_bgSize, "backgroundRepeat": special_bgRepeat, "backgroundColor": special_bgColor, "backgroundPosition": special_bgPosition }} >
                                                {(hasSpecial() &&

                                                    <tbody>
                                                        <tr className="contact_hours_row upcoming Special"><td colSpan="3" style={{ "textAlign": "center" }}>Upcoming Dates</td></tr>
                                                        <tr><td colSpan="3">&nbsp;</td></tr>
                                                        {drawSpecial()}
                                                        <tr><td colSpan="3">&nbsp;</td></tr>
                                                        <tr><td colSpan="3">&nbsp;</td></tr>
                                                    </tbody>

                                                )}
                                            </table>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                            <br />
                        </div>
                    )}

                    {/* else  */}

                    {(Array.isArray(window.ws_hours) && <>
                        <div>{((window.ws_htmlhours !== null) && <Html html={window.ws_htmlhours} />)}</div>
                        <br /><br />
                    </>
                    )}

                </div>
            </div>
            <div style={{ "maxWidth": "1020", "textAlign": "center", "fontSize": "18px", "padding": "0px 20px", "fontWeight": "bold" }}>{<Html className="" html={window.ws_contact_blurb} />}</div>
            <br /><br />
            {/* MAP */}

            <div style={{ "height": "400px", "marginBottom": "-20px" }}>
                <Map scrollWheelZoom={false} style={{ "height": "400px" }} center={[window.sitesettings.store_info.latitude, window.sitesettings.store_info.longitude]} zoom={15}>
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    />
                    {drawMarkers()}
                </Map>
            </div>
        </div>)

    }

    return displayStatus();

}

export default ContactLayout;
import { Form, Radio, Space } from "antd";
import React from "react";

export default function RadioField(props) {

    const { data = {}, contact = {} } = props;

    const {
        id = "",
        label = "Select",
        type = "select",
        description = "",
        options = [],
        required = false,
        required_message = "This field is required",
    } = data;

    let defaultValue = (contact.hasOwnProperty([id])) ? contact[id] : options[0].label;

    return (

        <div>

            <Form.Item
                rules={[{ required: required, message: (required_message) ? required_message : "This field is required" }]}
                label={
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                        <span style={{ lineHeight: '1' }}>{label}</span>
                        <div style={{ fontSize: '12px', color: '#666', marginTop: '4px' }}>{description}</div>
                    </div>
                }
                name={id}
            >
                <Radio.Group>
                    {options.map(item => (
                        <Radio style={{ display: 'block' }} value={item.label}>
                            {item.label}
                        </Radio>
                    ))}
                </Radio.Group>
            </Form.Item>
        </div>
    )
}
// Resources
import React, { useState, useEffect } from "react";
import { Steps, Empty, Result, Divider, Button, Modal } from 'antd';
import { useSession } from "../util/Session";
// Components
import Cart from "../components/components/checkout/Cart";
import Shipping from "../components/components/checkout/Shipping";
import ContactInfo from "../components/components/checkout/ContactInfo";
import Payment from "../components/components/checkout/Payment";
import { useParams, useHistory } from "react-router-dom";

import CreditCard from "../components/components/checkout/CreditCard";
import Summary from "../components/components/checkout/Summary";
import ImportExcelToCart from "../components/ImportExcelToCart";
import { apiCall } from "../components/utilities/Api";
import ShippingProviders from "../components/components/newcheckout/ShippingProviders";
import Html from "../components/utilities/Html";
import GlobalPaymentsForm from "../components/components/checkout/GlobalPaymentsForm";
import ClearentPaymentsForm from "../components/components/checkout/ClearentPaymentsForm";
import ChasePaymentsForm from "../components/components/checkout/ChasePaymentsForm";
import { EmptyCart } from "../util/Icons";


const { Step } = Steps;

function CheckoutLayout(props) {

    const currentProgress = useParams();
    const history = useHistory();
    const [context] = useSession();
    const [progress, setProgress] = useState(currentProgress.progress);
    const [saved, setSaved] = useState({ "shipping": {}, "contact": {}, "payment": "", "delivery": "", "provider": "", "batch": "", "cc_info": "" });
    const [step, setStep] = useState("cart");

    const [continueVisible, setContinueVisible] = useState(false);

    let disableForward = false;

    window.checksum = context.cart.checksum;
    window.history_length = history.length;

    const proceed = () => {
        setProgress(progress + 1);
    }

    const getChecksum = (_leaving) => {



        if (_leaving) {



        } else {



            apiCall("session/getChecksum", {}, (_status, _result) => {

                setContinueVisible(false);



                if (_status) {
                    if (_result.cart_checksum.toString() === "d751713988987e9331980363e24189ce") {
                        if (window.location.pathname.includes("/checkout/payment") || window.location.pathname.includes("/checkout/provider") || window.location.pathname.includes("/checkout/delivery") || window.location.pathname.includes("/checkout/provider") || window.location.pathname.includes("/checkout/contact")) {
                            return;
                        }
                        window.checksum = _result.cart_checksum;
                        history.push("/checkout/cart");
                    }

                    if (window.checksum.toString() !== _result.cart_checksum.toString()) {

                        window.checksum = _result.cart_checksum;
                        history.push("/checkout/cart");
                    }
                }
            })
        }
    }


    const onBlur = (e) => {

        if (window.location.pathname.includes("/checkout/payment")) {
            return;
        }

        if (window.payment !== "paypal" && window.payment !== "global" && window.payment !== "chase" && window.payment !== "clearent" && !window.location.pathname.includes("/checkout/cart")) {
            setContinueVisible(true);
        }

    }

    const onFocus = () => {

        if (window.location.pathname.includes("/checkout/payment")) {
            return;
        }

        if (window.payment !== "paypal" && window.payment !== "global" && window.payment !== "chase" && window.payment !== "clearent" || window.location.pathname.includes("/checkout/cart")) {
            getChecksum(false);
        }
    }

    const onPopState = (e) => {
        if (document.location.toString().substr(document.location.toString().length - 4, 4) === "cart") {
            disableForward = true;
        } else {
            disableForward = false;
        }
        if (disableForward) {
            history.push("/checkout/cart");
        }
    }

    useEffect(() => {
        window.addEventListener('focus', onFocus);
        window.addEventListener('blur', onBlur);
        window.addEventListener('popstate', onPopState);
        return () => {
            window.removeEventListener('focus', onFocus);
            window.removeEventListener('blur', onBlur);
            window.removeEventListener('popstate', onPopState);
        };
    }, []);




    // const onFocus = () =>{

    //     getChecksum(); 
    // }

    const displayProgress = () => {
        if (currentProgress.progress !== "cart") {
            if (!saved.delivery) {
                history.push("/checkout/cart");
            }
        }


        switch (currentProgress.progress) {



            case "cart":
                window.payment = "";
                return (<Cart step={step} setStep={setStep} saved={saved} setSaved={setSaved} proceed={proceed} />);

            case "delivery":
                window.payment = "";
                return (<Shipping step={step} setStep={setStep} saved={saved} setSaved={setSaved} proceed={proceed} />);

            case "provider":
                window.payment = "";
                return (<><ShippingProviders step={step} setStep={setStep} saved={saved} setSaved={setSaved} proceed={proceed} /></>);

            case "contact":
                window.payment = "";
                return (<ContactInfo step={step} setStep={setStep} saved={saved} setSaved={setSaved} proceed={proceed} />);

            case "payment":

                return (<Payment step={step} setStep={setStep} saved={saved} setSaved={setSaved} proceed={proceed} />);

            case "credit_card":
                window.payment = "";
                return (<CreditCard step={step} setStep={setStep} saved={saved} setSaved={setSaved} proceed={proceed} />);

            case "chase":
                window.payment = "chase";
                return (<>
                    <ChasePaymentsForm />
                </>);

            case "clearent":
                window.payment = "clearent";

                // !NOTE clearent is placed on the summary page
                return (<></>)


            case "global":
                // !NOTE global is placed on the summary page
                window.payment = "global";
                return (<></>);

            case "review":
                return (<Summary step={step} setStep={setStep} saved={saved} setSaved={setSaved} proceed={proceed} />);

            default:
                return (<Cart step={step} setStep={setStep} saved={saved} setSaved={setSaved} proceed={proceed} />);

        }

    }

    const getStep = () => {


        if(context.cart.summary.contains_only_emailed_gift_card){
            switch (currentProgress.progress) {
                case "cart":
                    return 0;
                case "delivery":
                    return 1;
                case "provider":
                    return 2;
                case "contact":
                    return 1;
                case "payment":
                    return 2;
                case "credit_card":
                    return 2;
                case "review":
                    return 3;
                default:
                    return 6;
            }
        }

        switch (currentProgress.progress) {
            case "cart":
                return 0;
            case "delivery":
                return 1;
            case "provider":
                return 2;
            case "contact":
                return 3;
            case "payment":
                return 4;
            case "credit_card":
                return 4;
            case "review":
                return 5;
            default:
                return 6;
        }
    }


    const getCheckoutLabel = () => {

        switch (currentProgress.progress) {
            case "cart":
                return (<>Your Cart {<Button style={{ "float": "right" }} onClick={history.goBack}><span style={{ "fontSize": "14px", "lineHeight": "18px" }}>Go back</span></Button>}</>)
            case "delivery":
                return (<>Shipping</>)
            case "provider":
                return (<>Provider</>)
            case "contact":
                return (<>Contact</>)
            case "payment":
                return (<>Payment</>)
            case "credit_card":
                return (<>Credit Card</>)
            case "review":
                return (<>Review</>)
            default:
                return (<>Checkout</>)
        }
    }

    const displayEmpty = () => {
        return (
            <div className="container">
                <h2>Cart</h2>
                <div aria-hidden><Divider /></div>
                <div style={{ "display": "block", "textAlign": "center" }}>
                    <Result
                        icon={<EmptyCart aria-hidden style={{ "color": "#dce0e6" }} />}
                        status="warning"
                        title="There are no items in your cart."
                        subTitle={<><ImportExcelToCart /></>}
                    />
                </div>
            </div>

        )
    }

    const onChange = (v) => {


    }

    const display = () => {



        if (!window.sitesettings.cart_enabled) {

            return (
                <>
                    <div className="container">
                        <Result
                            status="warning"
                            title={"Online ordering is disabled."}
                        />
                    </div>
                </>
            )
        }


        const drawDeliveryLabel = () => {


            if(context.cart.summary.contains_only_emailed_gift_card){
                return "Delivery";
            }

            if(saved?.delivery === "ship"){

                if(saved?.custom === true && window.sitesettings.checkout_info.label_override_ship){
                    return window.sitesettings.checkout_info.label_custom_ship; 
                }
                return "Ship";
            }

            if(saved?.delivery === "pickup"){
                if(saved?.custom === true && window.sitesettings.checkout_info.label_override_pickup){
                    return window.sitesettings.checkout_info.label_custom_pickup; 
                }
                return "Pick up";
            }

            if(saved?.delivery === "local_delivery"){
                return "Local Delivery";
            }

           


        }

        return (
            <>
   
                <div className="container">
                    {(currentProgress.progress !== "cart" && <Button onClick={() => history.goBack()} style={{ "float": "right" }}>Back</Button>)}
                    <h2>{getCheckoutLabel()}</h2>
                    {/* <div aria-hidden="true" style={{ "padding": "20px 100px" }} className="hide-block">
                        <Steps size="small" onChange={(v) => onChange(v)} current={getStep()}>
                            <Step key="cart" onKeyDown={(e) => {if(e.key === "Enter" && (getStep(currentProgress.progress) > 0)){ history.push("/checkout/cart")}}} onClick={() => history.push("/checkout/cart")} title="Cart" />

                            {(!context.cart.summary.contains_only_emailed_gift_card && <>
                                <Step disabled={!(getStep(currentProgress.progress) > 1 && (saved.delivery === "ship"))} key="address" style={{ "pointerEvents": (getStep(currentProgress.progress) > 1 && (saved.delivery === "ship")) ? "auto" : "none" }} title="Address" onKeyDown={(e) => {if(e.key === "Enter" && (saved && (saved.delivery === "ship") && saved.shipping.hasOwnProperty("address_id") !== 0)){ history.push("/checkout/provider")}}} onClick={() => (saved && (saved.delivery === "ship") && saved.shipping.hasOwnProperty("address_id") !== 0) ? history.push("/checkout/delivery") : null} />
                            </>)}

                            {(!context.cart.summary.contains_only_emailed_gift_card && <>
                                <Step disabled={!(getStep(currentProgress.progress) > 2 && (saved.delivery === "ship"))} key="shipping" style={{ "pointerEvents": (getStep(currentProgress.progress) > 2 && (saved.delivery === "ship")) ? "auto" : "none" }} onKeyDown={(e) => {if(e.key === "Enter" && saved && saved.delivery === "ship"){ history.push("/checkout/provider")}}} onClick={() => (saved && saved.delivery === "ship") ? history.push("/checkout/provider") : null} title={drawDeliveryLabel()} />
                            </>)}
                            <Step disabled={!(getStep(currentProgress.progress) > 4)}  key="contact" style={{ "pointerEvents": (getStep(currentProgress.progress) > 3) ? "auto" : "none" }} title="Contact" onKeyDown={(e) => {if(e.key === "Enter" && saved && Object.keys(saved.contact).length !== 0){history.push("/checkout/contact");}}} onClick={() => (saved && Object.keys(saved.contact).length !== 0) ? history.push("/checkout/contact") : null} />
                            <Step disabled={!(getStep(currentProgress.progress) > 4)} key="payment" style={{ "pointerEvents": (getStep(currentProgress.progress) > 4) ? "auto" : "none" }} title="Payment" onKeyDown={(e) => {if(e.key === "Enter" && saved && saved.payment !== ""){ history.push("/checkout/payment")}}} onClick={() => (saved && saved.payment !== "") ? history.push("/checkout/payment") : null} />
                            <Step disabled={true} key="summary" style={{ "pointerEvents": "none" }} title="Review" />
                        </Steps>
                    </div>
                    <br /> */}
                    {displayProgress()}
                </div>
            </>
        )
    }




    const displayStatus = () => {
        if (!context.cart.items || context.cart.items.length < 1) {
            return displayEmpty();
        } else {
            return display();
        }
    }

    return displayStatus();

}

export default CheckoutLayout;
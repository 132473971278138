import { Checkbox, Form, Space } from "antd";
import React from "react";

export default function CheckBoxField(props) {

    const { data = {}, contact = {} } = props;

    const {
        id = "",
        label = "Checkbox",
        type = "checkbox",
        description = "",
        required = false,
        required_message = ""
    } = data;

    let defaultValue = (contact.hasOwnProperty([id])) ? true : false;

    // <Form.Item initialValue={defaultValue} label={null} valuePropName="checked" extra={description} name={id} >
    //     <Checkbox >{label}</Checkbox>
    // </Form.Item>

    return (
        <>
            <Space align="start">
                {(required && <span style={{ "color": "red" }}>*</span>)}
                <Form.Item name={id} valuePropName="checked" rules={required ? [{ validator: (_, value) => value ? Promise.resolve() : Promise.reject(new Error(required_message || "This field is required")), },] : []} extra={description}>
                    <Checkbox>{label}</Checkbox>
                </Form.Item>
            </Space>
        </>

    )
}
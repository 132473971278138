import React, { useEffect, useState } from "react";
import { Button, Modal, message } from 'antd';
import List from "../wrappers/List";
import { ReadOutlined } from '@ant-design/icons';
import { apiCall } from "../../utilities/Api";

export default function EventViewMore(props) {

    const { type = "more", cc_event = false, event_id = "" } = props;
    const [modalVisible, setModalVisible] = useState(false);
    const close = () => { setModalVisible(false) }

    const [data, setData] = useState([]);

    const fetchData = () => {
        if(!modalVisible){
            return; 
        }

        let _api = "event/getEventItems";
        if(window.sitesettings.using_events_v2){
            _api = "event/v2/getEventItems";
        }
    
        apiCall(_api, {event_id : event_id, type : (type === "image" || type === "more") ? "book" : type}, (_status, _result) => {
            if(_status){
                setData(_result);
            } else {
                message.error(_result.error)
            }
        })
    }

    useEffect(fetchData, [modalVisible]);



    const displayButton = () => {

        if (cc_event) {
            return (<div onClick={() => setModalVisible(true)} >{props.children}</div>);
        }

        switch (type) {
            case "image":
                return (<div style={{ "display": "flex", "cursor": "pointer", "flexDirection": "row" }} onClick={() => setModalVisible(true)} type="primary" >{props.children}</div>);
            case "book":
                return (<Button onClick={() => setModalVisible(true)} type="primary" >Buy Books</Button>);
            case "ticket":
                return (<Button onClick={() => setModalVisible(true)} type="secondary" >Buy Tickets</Button>);
            default:
                return (<Button onClick={() => setModalVisible(true)} style={{ "width": "90%", "marginTop": "20px" }} type="text" icon={<ReadOutlined aria-hidden />} block >More</Button>);
        }
    }



    return (
        <>
            {displayButton()}
            <Modal wrapClassName="siteModal" width={(data.length > 1) ? "90%" : 512} open={modalVisible} onCancel={close} destroyOnClose closable={true} footer={null}>
                <List data={data} />
            </Modal>
        </>
    );

}
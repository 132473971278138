import React from "react";
import Html from "../components/utilities/Html";
import { getColor } from "../util/CC";
import { Button } from "antd";
import { useMediaQuery } from "react-responsive";

export default function SideBySide(props) {

    const template = window.sitesettings.template_info;
    const ismobile = useMediaQuery({ query: '(max-width: 716px)' })
    const { content = {} } = props;

    const {
        location = "main",
        component = "blank_space",
        container = true,
        data = {},
        injected_data = {}
    } = content;

    const {
        flip = false,
        background_size = "cover",
        bg_horizontal_align = "center",
        bg_vertical_align = "center",
        text_container = true,
        container_width = 1248,
        container_align = "0 auto",
        text_background_colour = 1,
        text_padding_x = 10,
        text_padding_y = 10,
        background_colour = 0,
        width = 100,
        justify = "center",
        image_width = 50,
        image_height = 50,
        align = "left",
        textAlign = "left",
        padding_x = 10,
        padding_y = 10,
        heading = "Heading...",
        heading_preset = 2,
        paragraph = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque tincidunt enim vel lectus egestas hendrerit. Sed at tempus ante. Interdum et malesuada fames ac ante ipsum primis in faucibus.",
        paragraph_preset = 1,
        button_url = "",
        button_label = "",
        button_type = 1,
        button_tab = true,
        button_block = false,
        background_image_radius = 0,
        link_id = 0,

        // NEW
        image_interior_width = 100,
        image_interior_align = "center",
        image_url = "",
        image_tab = true,
        background_alt = "",

        allow_mobile_overrides = false,
        mobile_flip = false,
        mobile_image_height = 300,
        mobile_bg_horizontal_align = "center",
        mobile_bg_vertical_align = "center",
        mobile_background_size = "cover",

    } = data;


    const has_url = (image_url === "" || image_url === "/" || image_url === "http://" || image_url === "https://") ? false : true; 

    const { background_image = "" } = injected_data;

    const linkOut = () => {
        if (button_url) {
            window.open(button_url, (button_tab) ? "_blank" : "_self");
        }
    }

    const parsePath = (url) => {
        const abs = /^(?:[a-z]+:)?\/\//i;
        return (abs.test(url)) ? url : "https://cdn1.bookmanager.com/i/" + window.san + url;
    }

    const drawRender = () => {

        let _style = {
            padding: text_padding_y + "px " + text_padding_x + "px",
            maxWidth: width + "%",
            alignSelf: "flex-end",
            textAlign: textAlign,
            flexGrow: 1,
        }

        let bg_style = {
            borderRadius: background_image_radius + "px",
            backgroundRepeat: "no-repeat",
            backgroundPositionX: bg_horizontal_align,
            backgroundPositionY: bg_vertical_align,
            backgroundSize: background_size,
            minHeight: image_height + "px",
            backgroundImage: `url('${parsePath(background_image)}')`
        }

        let containerStyle = {
            maxWidth: (text_container) ? container_width + "px" : "100%",
            padding: padding_y + "px " + padding_x + "px",
            margin: container_align
        }

        switch (justify) {
            case "center":
                _style.alignSelf = "center";
                break;
            case "bottom":
                _style.alignSelf = "flex-end";
                break;
            default:
                _style.alignSelf = "flex-start";
                break;
        }

        let _stack = (flip) ? "bm-stack" : "bm-stack-flip";


        // mobile 
        if (allow_mobile_overrides && ismobile) {
            bg_style.minHeight = mobile_image_height + "px";
            _stack = (mobile_flip) ? "bm-stack" : "bm-stack-flip";
            bg_style.backgroundPositionX = mobile_bg_horizontal_align;
            bg_style.backgroundPositionY = mobile_bg_vertical_align;
            bg_style.backgroundSize = mobile_background_size;
        }


        return (
            <div className={(container) ? "preview-container" : ""}>
                <div style={{ background: getColor(background_colour, template) }}>
                    <div style={containerStyle}>
                        <div className={_stack} style={{ display: 'flex', width: '100%' }}>
                            <div style={{ flex: '0 0 ' + image_width + "%", display: 'flex', "justifyContent": image_interior_align }}>
                                <div role="img" tabIndex={(has_url) ? 0 : null} aria-label={(!has_url) ? background_alt : (image_tab) ? "Click to visit link (opens in a new tab)" : "Click to visit link"} onClick={() => {
                                    if (has_url) {
                                        window.open(image_url, (image_tab) ? "_blank" : "_self")
                                    }
                                }} onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        if (has_url) {
                                            window.open(image_url, (image_tab) ? "_blank" : "_self")
                                        }
                                    }
                                }} style={{ ...bg_style, cursor: (has_url) ? "pointer" : "inherit", "width": image_interior_width + "%" }}></div>

                            </div>
                            <div style={{ flex: '0 0 ' + (100 - image_width) + "%", display: 'flex', justifyContent: align, background: getColor(text_background_colour, template) }}>
                                <div style={_style}>

                                    {(heading &&
                                        <h2 style={{ "display": "block" }} className={"bm-txt-" + heading_preset} >
                                            {heading}
                                        </h2>
                                    )}
                                    {(paragraph &&
                                        <p className={"bm-txt-" + paragraph_preset} >
                                            <Html clear={false} className={"bm-lnkin-" + link_id} html={paragraph} />
                                        </p>
                                    )}

                                    {(button_label &&
                                        <div style={{ "display": "block" }}>
                                            <div className="shim" /><div className="shim" />
                                            <Button block={button_block} onClick={() => linkOut()} className={"bm-btn-" + button_type} >{button_label}</Button>
                                        </div>
                                    )}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }



    return drawRender();
}
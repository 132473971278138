import React from "react";
import moment from "moment";
import Html from "../../components/utilities/Html";
import { getColor } from "../../util/CC";
import { getOGColor, prettyTime } from "../../util/Utils";
import { Skeleton, Space, Tag } from "antd";
import { useHistory } from "react-router-dom";
import { EnvironmentFilled } from '@ant-design/icons';

export default function EventUI(props) {

    const { single = false } = props;
    const template = window.sitesettings.template_info;
    const history = useHistory();

    const {
        flourish = true,
        highlight_colour = 0,
        text_colour = 0,
        event = {},
        title_preset = 0,
        date_preset = 0,
        description_preset = 0,
        link_id = 0
    } = props;

    const {
        id = "",
        info = {},
        from = 0,
        to = 0,
        title = "",
        date = "",
        recurring_as_single_row = false,
        end_date = "",
        start_time = "",
        end_time = "",
        all_day = false,
        category = "",
        location_text = "",
        attendance = "",
        summary = "",
    } = event;

    const {
        name = "",
        description = ""
    } = info;


    const linkOut = () => {
        history.push("/events/" + id)
    }

    const start = moment.unix(from).tz('America/Los_Angeles');
    const drawDateRange = (_start_ts, _end_ts) => {
        // lock to pacific time
        let start_time = moment.unix(_start_ts).tz('America/Los_Angeles');
        let end_time = moment.unix(_end_ts).tz('America/Los_Angeles');
        // format
        let format = 'dddd MMMM Do, YYYY @ h:mmA';
        // check if the range spans over multiple days
        if (start_time.isSame(end_time, 'day')) {
            // start and end match - just display start
            if (_start_ts === _end_ts) {
                return start_time.format(format);
            }
            // same day, draw one date and time range
            return start_time.format(format) + ' - ' + end_time.format('h:mm A');
        } else {
            // different days, draw the date and time for each day
            return start_time.format(format) + ' - ' + end_time.format(format);
        }
    }

    const drawFlorish = () => {
        if (!flourish) {
            return (<></>)
        }
        if (!window.sitesettings.using_events_v2) {
            return (
                <div style={{ "cursor": "pointer", "width": "50px", "lineHeight": "20px", "marginTop": "5px", "padding": "4px 0px", "height": "70px", "overflow": "hidden", "textAlign": "center", "color": getColor(text_colour, template, "#000"), "backgroundColor": getColor(highlight_colour, template, "#fff") }}>
                    <div><small>{start.format("MMM").toUpperCase()}</small></div>
                    <div style={{ "fontSize": "20px" }}>{start.format("D")}</div>
                    <div><small>{start.format("YYYY")}</small></div>
                </div>
            )
        } else {
            return (
                <div className="hide-block" style={{ "width": "50px", "lineHeight": "20px", "marginTop": "5px", "padding": "4px 0px", "height": "70px", "overflow": "hidden", "textAlign": "center", "color": getColor(text_colour, template, "#000"), "backgroundColor": getColor(highlight_colour, template, "#fff") }}>
                    <div><small>{moment(date, "YYYYMMDD").format("MMM").toUpperCase()}</small></div>
                    <div style={{ "fontSize": "20px" }}>{moment(date, "YYYYMMDD").format("D")}</div>
                    <div><small>{moment(date, "YYYYMMDD").format("YYYY")}</small></div>
                </div>
            );
        }
    }

    const drawCategory = () => {
        if (!category) {
            return (<></>)
        }
        return (<Tag className="internal" style={{ "padding": "6px", "lineHeight": "4px" }} color={category.colour}>{category.name}</Tag>)
    }

    const drawDate = () => {
        if (window.sitesettings.using_events_v2) {
            let _date = moment(date, "YYYYMMDD").format("dddd MMM Do, YYYY");

            if(recurring_as_single_row && end_date){
                _date = moment(date, "YYYYMMDD").format("ddd MMM Do, YYYY") + " to " + moment(end_date, "YYYYMMDD").format("ddd MMM Do, YYYY");
            }
            
            return (
                <>
                {(flourish && <div className="show">{moment(date, "YYYYMMDD").format("MMM, Do YYYY")}</div>)}
                <Space size={10}>
                    {(!flourish && <div>{_date}</div>)}
                    {(start_time && <div>{prettyTime(start_time)}</div>)}
                    {((start_time && end_time) && <div>-</div>)}
                    {(end_time && <div>{(!start_time && <>Ends </>)}{prettyTime(end_time)}</div>)}
                    {(all_day && <div>All Day</div>)}
                    {drawCategory()}
                    {(location_text && <div><EnvironmentFilled />&nbsp;{location_text}</div>)}
                </Space>
                </>
            )
        } else {
            { return drawDateRange(from, to) }
        }
    }

    const drawTitle = () => {
        if (window.sitesettings.using_events_v2) {
            return title;
        } else {
            return name;
        }
    }

    const drawDescription = () => {
        if (window.sitesettings.using_events_v2) {

            if (single && event.description) {
                return <Html overflow="inherit" clear={false} className="" anchor={"bm-lnkin-" + link_id} html={event.description} />
            }
            if (summary) {
                return <div style={{"whiteSpace" : "pre-line"}}><Html overflow="inherit" clear={false} className="" anchor={"bm-lnkin-" + link_id} html={summary} /></div>;
            } else {
                return <Html overflow="inherit" clear={false} className="" anchor={"bm-lnkin-" + link_id} html={event.description} />
            }
        } else {
            return <Html overflow="inherit" clear={false} className="" anchor={"bm-lnkin-" + link_id} html={event.info.description} />
        }
    }

    if (!date && !from) {
        return <Skeleton />
    }           

    return (
        <>
            <Space style={{"flexWrap" : (flourish) ? "nowrap" : "nowrap"}} size={20} align="start">
                {drawFlorish()}
                <div>
                    <h4 tabIndex={0} aria-label="Click to view event" onKeyDown={(e) => {if(e.key === "Enter"){linkOut()}}} onClick={() => linkOut()} style={{ "display": "block" }} className={(single) ? "bm-txt-" + title_preset : "bm-txt-link bm-txt-" + title_preset }>
                        {drawTitle()}
                    </h4>
                    <div aria-label="Click to view event" onKeyDown={(e) => {if(e.key === "Enter"){linkOut()}}} onClick={() => linkOut()} style={{ "display": "block" }} className={(single) ? "bm-txt-" + date_preset : "bm-txt-link bm-txt-" + date_preset}>
                        {drawDate()}
                    </div>
                    <div className="shim" /><div className="shim" />
                    <div style={{ "display": "block"}} className={"bm-lnkin-" + link_id + " bm-txt-" + description_preset}>
                        {drawDescription()}
                    </div>
                    <div>
                        {((!single && summary) && <><div className="shim" /><a tabIndex={0} aria-label="Click to view event" onKeyDown={(e) => {if(e.key === "Enter"){linkOut()}}} onClick={() => linkOut()} className={"bm-txt-0 " + "bm-lnk-" + link_id}>Read More...</a></>)}
                    </div>
                </div>
            </Space>
        </>
    )
}
import React from "react";
import { getColor } from "../util/CC";
import Carousel from "./helpers/Carousel";
import { useMediaQuery } from "react-responsive";
export default function ImageCarousel(props) {

    const template = window.sitesettings.template_info;
    const ismobile = useMediaQuery({ query: '(max-width: 716px)' })

    const { content = {} } = props;

    const {
        location = "main",
        component = "image_carousel",
        container = true,
        data = {},
        injected_data = {}
    } = content;

    const {
        images = []
    } = injected_data;

    const {
        animation = "fade",
        height = 500,
        width = 0,
        padding_y = 0,
        padding_x = 0,
        background_colour = 0,
        button_type = 1,
        bg_horizontal_align = "center",
        bg_vertical_align = "center",
        background_size = "cover",
        align = "center",
        auto = false,
        timeout = 3,
        image_radius = 0,

        content_header_style = 0,
        content_paragraph_style = 0,
        content_button_style = 0,
        content_padding_x = 10,
        content_padding_y = 10,
        content_align = "center",
        content_justify = "center", // "start, center, end"
        content_text_align = "center", //"left, center right"
        image_opacity = 100,
        contained = false,
        content_max_width = 1248,

        allow_mobile_overrides = false,
        mobile_height = 500,
        mobile_width = 0,
        mobile_padding_y = 0,
        mobile_padding_x = 0,
        mobile_bg_horizontal_align = "center",
        mobile_bg_vertical_align = "center",
        mobile_background_size = "cover",
        mobile_align = "center",

        mobile_content_header_style = 0,

    } = data;

    const drawRender = () => {
        let _align;
        switch (align) {
            case "center":
                _align = "0 auto"
                break;
            case "right":
                _align = "0 0 0 auto"
                break
            default:
                _align = "0 0 0 0"
        }

        let carouselStyle = {
            background: getColor(background_colour, template),
            backgroundPositionX: bg_horizontal_align,
            backgroundPositionY: bg_vertical_align,
            padding: `${padding_y}px ${padding_x}px`,
            position: "relative",
            maxWidth: (width) ? width + "px" : "100%",
            margin: _align,
        };

        let background_position = bg_horizontal_align + " " + bg_vertical_align;

        // mobile

        const m = (allow_mobile_overrides && ismobile) ? true : false;
        if (m) {
            carouselStyle.padding = `${mobile_padding_y}px ${mobile_padding_x}px`;
            carouselStyle.backgroundPositionX = mobile_bg_horizontal_align;
            carouselStyle.backgroundPositionY = mobile_bg_vertical_align;
            background_position = mobile_bg_horizontal_align + " " + mobile_bg_vertical_align;
            carouselStyle.maxWidth = (mobile_width) ? mobile_width + "px" : "100%";
            switch (mobile_align) {
                case "center":
                    _align = "0 auto"
                    break;
                case "right":
                    _align = "0 0 0 auto"
                    break
                default:
                    _align = "0 0 0 0"
            }
            carouselStyle.margin = _align;
        }


        return (
            <div className={(container) ? "preview-container" : ""}>
                <div style={carouselStyle}>
                    <Carousel
                        image_radius={image_radius}
                        auto={auto} timeout={timeout}
                        background_position={background_position}
                        background_size={(m) ? mobile_background_size : background_size}
                        height={(m) ? mobile_height : height}
                        button_type={button_type}
                        animation={animation}
                        images={images}
                        content_header_style={(m) ? mobile_content_header_style : content_header_style}
                        content_paragraph_style={content_paragraph_style}
                        content_button_style={content_button_style}
                        content_padding_x={content_padding_x}
                        content_padding_y={content_padding_y}
                        content_align={content_align}
                        content_justify={content_justify}
                        content_text_align={content_text_align}
                        image_opacity={image_opacity}
                        contained={contained}
                        content_max_width={content_max_width}
                    />
                </div>
            </div>
        );
    }


    return drawRender();
}
import React, { useState, useEffect } from "react";
import { Row, Col, Input, Select, Button, Upload, message, AutoComplete, Space } from 'antd';
import { CaretRightOutlined, SearchOutlined } from '@ant-design/icons';
import { apiCall } from "../components/utilities/Api";
import { useHistory } from "react-router-dom";
import { getISBNCount } from "../util/Utils";
import { pixelTrack } from "../util/SiteRouter";


// Destructures
const { Option } = Select;
const { Search } = Input;

export default function SearchField(props) {

    const history = useHistory();
    const [searchValue, setSearchValue] = useState("")
    const searchCategories = ["Keyword", "Title", "Author", "Publisher", "URL"];
    const [searchType, setSearchType] = useState(searchCategories[0].toLowerCase());
    const [processing, setProcessing] = useState(false);
    const [mobileMenu, setMobileMenu] = useState(false);

    const onLocChange = () => {
        if (!history.location.pathname.includes("/browse/") || !history.location.pathname.includes(searchValue)) {
            setSearchValue("");
        }
        if (mobileMenu) {
            setMobileMenu(false);
        }
    }

    useEffect(onLocChange, [history.location])

    const onUploadFile = (_status, _result) => {
        setProcessing(false);
        if (_status) {
            history.push("/browse/filter/x/" + _result.id)
        } else {
            message.error("File is too large. (Max size is 50MB)");
        }
    }

    const uploadFile = (_file) => {
        if (_file.file.size > 50000000) {
            message.error("File is too large. (Max size is 50MB)");
            return;
        }
        setProcessing(true);
        apiCall("browse/file", { file: _file.file }, onUploadFile);
    }

    const onTypeChange = (_v) => {
        // if (_v === "file") {
        //     return; 
        // }
        setSearchType(_v);
    }

    const searchURL = (_url) => {
        apiCall("browse/url", { url: _url }, (_status, _result) => {
            if (_status) {
                history.push("/browse/filter/x/" + _result.id)
            }
        })
    }

    const generateFile = (_val) => {
        var filename = 'pasted.txt';
        let _file = new File([new Blob([_val])], filename)
        let f = {}
        f.file = _file;
        return f;
    }

    const getCacheKeyForFile = (_file) => {
        setProcessing(true);
        apiCall("browse/file", { file: _file.file }, (_status, _result) => {
            if (_status) {

                history.push({ pathname: "/browse/filter/v/sequence/x/" + _result.id, state: Math.random().toString() })
            }
            setProcessing(false);
        });
    }


    const onSearch = (_search, e) => {

        if (e) {
            e.target.blur();
        }

        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;

        if (_search !== "") {
            if (searchType === "url") {
                searchURL(_search)
                return;
            } else {

                if (getISBNCount(_search) > 1) {
                    getCacheKeyForFile(generateFile(_search));
                    return;
                }

                const search_path = "/browse/filter/t/" + encodeURI(_search).replace(new RegExp('%25', 'g'), '%2525') + "/k/" + encodeURI(searchType); 

                pixelTrack("Search", search_path, _search);

                // REGEX for weird issue with percent decoding on useHistory()
                history.push({ "pathname": search_path, "search_bar": { "searchTerm": _search, "searchType": searchType } })
            }
        } else {
            // clear search
            history.push("/browse/filter/");
        }

    }

    const displaySearchOptions = () => {
        return searchCategories.map((item, id) => {
            return (<Option className="tab" key={id} value={item.toLowerCase()}>{item}</Option>)
        })
    }

    const displaySearchCategores = () => {
        return (
            <Select id="type_list" style={{ "borderRight": "1px solid #eee", "height": "38px", "width": "115px" }}
                bordered={false}
                size="large"
                onChange={(_v) => onTypeChange(_v)} value={searchType}
                dropdownMatchSelectWidth={false}
                optionFilterProp="children" 
                aria-label="Select Search Type"
                aria-hidden
                role="combobox"
                aria-haspopup="listbox"
                aria-expanded="false" // Make sure to control this state
                defaultValue="Keyword"
            >
                {displaySearchOptions()}
            </Select>
        )
    }


    const uploadButton = () => {

        if (searchType !== "file") {
            return (<></>)
        }
        return (<Upload accept=".txt, .pdf, .xls, .xlsx, .html, .csv" showUploadList={false} customRequest={(e) => uploadFile(e)}><Button loading={processing} style={{ "top": "16px", "position": "absolute", "marginLeft": "70px", "zIndex": "11", "border": (window.lightheader) ? "1px solid #ddd" : "1px solid #ddd", "color": "#000" }}>Choose file <small> (max 50MB)</small></Button></Upload>)
    }

    const [hasMobileLogo, setHasMobileLogo] = useState(true);




    const drawPlaceholder = (_type) => {
        // const searchCategories = ["Keyword", "Title", "Author", "Publisher", "URL", "File"];


        switch (_type) {

            case "title":
                return "exact title";

            case "author":
                return "author";

            case "publisher":
                return "publisher";

            case "url":
                return "URL";

            case "file":
                return "file";

            case "keyword":
            default:
                return "title, author, subject, or ISBN";


        }
    }


    const SearchSuggest = (props) => {

        const [options, setOptions] = useState([])
        const [term, setTerm] = useState("");
        let _debounce = null;

        const getSuggestions = (_t) => {

            if (!_t) {
                setTerm("");
                setOptions([]);
                return;
            }
            apiCall("browse/getSuggest", { t: _t }, (_status, _result) => {
                if (_status) {
                    setOptions(_result)
                }
            })
        }

        const handleSearch = (_t) => {
            // setTerm(_t);

            clearTimeout(_debounce);
            _debounce = setTimeout(() => {
                getSuggestions(_t);
            }, 400);
        }

        const linkOut = (_eisbn) => {
            history.push("/item/" + _eisbn);
        }

        const handleKeyDown = (ev) => {
            if (ev.keyCode === 27) {
                ev.preventDefault();
            }
        }

        //return(<Search style={{ "width": "100%", "maxWidth": "500px", "height": "40px" }} className="header_search tab" aria-label="Search by title, author, subject or ISBN" onSearch={(_value, e) => onSearch(_value, e)} placeholder={"Search by " + drawPlaceholder(searchType)} size="large" enterButton={false} />)

        return (
            
            <AutoComplete
                disabled={(searchType === "file")}
              
                aria-label="Search by title, author, subject or ISBN"
                onKeyDown={handleKeyDown}
                notFoundContent={(term) ? <>No suggesstions found...</> : null}
                onClear={() => setTerm("")}
                // value={term}
                onSearch={(e) => handleSearch(e)}
                onSelect={(e) => linkOut(e)}
                options={options.map(item => {

                    let _path = "https://cdn1.bookmanager.com/i/slider_23.php?b=" + item.eisbn + "&cb=" + item.cover_image_cache;

                    return {
                        value: item.eisbn,
                        label: <>

                            <Row wrap={false} gutter={10}>
                                <Col flex={"25px"}>
                                    <img onError={(e) => e.target.src = "https://cdn1.bookmanager.com/i/nocover.png"} style={{ "width": "25px" }} src={_path} alt={item.title} />
                                </Col>
                                <Col flex={"auto"}>
                                    <div style={{ "display": "flex", "justifyContent": "space-between", "alignItems": "center" }}>
                                        <div style={{ "lineHeight": "15px", "paddingLeft": "10px", "maxWidth": "420px", "overflow": "hidden" }}>
                                            <div style={{ "overflow": "hidden", "textOverflow": "ellipsis", "width": "400px" }}>{item.title}</div>
                                            <div style={{ "overflow": "hidden", "textOverflow": "ellipsis", "width": "400px" }}><small>{item.subtitle}</small></div>
                                            <div style={{ "overflow": "hidden", "textOverflow": "ellipsis", "width": "400px" }}><small><em>{item.author}</em></small></div>
                                        </div>
                                        <div><CaretRightOutlined /></div>
                                    </div>
                                </Col>
                            </Row>
                        </>
                    }

                })}

                style={{ "width": "100%", "maxWidth": "500px", "height": "40px", "border" : "none" }}  className="header_search tab" id="search" >

                <Search style={{"maxWidth" : "100%", "border" : "none"}} className="asa-search" enterButton={<Button className="asa-inset-button" style={{"border" : "none"}} aria-label="Search"><div aria-hidden style={{marginLeft : "-2px"}} ><SearchOutlined /></div></Button>} aria-label="Search by title, author, subject or ISBN" onSearch={(_value, e) => onSearch(_value, e)} placeholder={"Search by " + drawPlaceholder(searchType)} size="large" />
            </AutoComplete>

        )



    }

    return (


        <div style={{ "display": "flex", "alignItems": "stretch", "flexDirection" : "column" }}>


            <div style={{ "display": "flex", "width": "auto", "border" : (window.lightheader) ? "1px solid #ddd" : "none", "paddingRight" : "2px" }}>
                <label aria-label="Search" htmlFor="type_list" />
                <div style={{ "backgroundColor": "#fff", border : "none", "width": "114px" }} className="tab header_border">{uploadButton()}{displaySearchCategores()}</div>
                <SearchSuggest />
            </div>
        </div>

    )
}


/**
 * Component for showing details of the Something updated.
 *
 * @component
 * @example
 * const age = 21
 * const name = 'Jitendra Nirnejak'
 * return (
 *   <User age={age} name={name} />
 * )
 */

import React from "react";
import { Row, Col, Divider, Tooltip, Card, Space } from 'antd';
import { getItemAlt, parceAuthors, processAvailable } from "../../../util/Utils"
import { CanadaIcon } from "../../../util/Icons";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import moment from "moment";
import conditional from "../../utilities/conditional";
import SalesIndicator from "./SalesIndicator";
import GiftCardAddToCart from "../giftcards/GiftCardAddToCart";
import AlsoAvailable from "./AlsoAvailable";
import DrawShelfLocation from "../DrawShelfLocation";


// When image fails to load, this is the placeholder
const imageFallback = (i, light = true) => {
    i.target.src = "https://cdn1.bookmanager.com/i/nocover.png"
    if (light) {
        i.target.className = "light";
    }

}

function ItemSummary(props) {

    const data = props.data;
    return (<pre>{JSON.stringify(data, null, 2)}</pre>)
}


ItemSummary.Slide = (props) => {

    const data = props.data;
    return (
        <img onError={i => i.target.style.display = 'none'} className="shadow-slider" alt={getItemAlt(data)} src={"https://cdn1.bookmanager.com/i/slider_23.php?b=" + data.eisbn + "&cb=" + data.cover_image_cache} />
    );

}

ItemSummary.Image = (props) => {
    const data = props.data;
    return (
        <img alt={getItemAlt(data)} src={"https://cdn1.bookmanager.com/i/n?b=" + data.eisbn + "&cb=" + data.cover_image_cache} />
    );

}


ItemSummary.SideBar = (props) => {


    const data = (props.data) ? props.data : defaultData;



    return (
        <div className="ItemSidebar" style={{ "textAlign": "center" }}>
            <img onError={(i) => imageFallback(i)} alt={getItemAlt(data)} style={{ "width": "90%" }} src={"https://cdn1.bookmanager.com/i/n?b=" + data.eisbn + "&cb=" + data.cover_image_cache} />

            <div className="shim" />
            <strong>{data.title}</strong><br />

            <conditional.true value={data.authors.length > 0}>
                <>by {parceAuthors(data.authors)}<br /></>
            </conditional.true>

            {data.binding}
            <conditional.true value={(data.release_date)}>
                | {moment(data.release_date * 1000).format('MMM DD, YYYY')}<br />
            </conditional.true>


            <div>{processAvailable(data.available, "sidebar", data.price, props.show_price, data, "", props.popup)}</div>

            <div aria-hidden><Divider style={{ "marginBottom": "5px" }} /></div>
        </div>
    );

}



ItemSummary.Gift = (props) => {


    const { gift_registry_id = "" } = props;

    const drawSubtitle = (_data) => {


        if (_data.series) {

            if (_data.series_num) {
                return _data.series + " #" + _data.series_num;
            } else {
                return _data.series;
            }
        } else {
            return _data.subtitle;
        }
    }


    const data = props.data;


    const {
        is_giftcard
    } = data;

    if (is_giftcard) {
        return (
            <>
                <div className="listItem">
                    <Row >
                        <Col xs={7} lg={11} >
                            <LazyLoadImage
                                alt={getItemAlt(data)}
                                onError={(i) => imageFallback(i)}
                                className="shadow-light"
                                src={"https://cdn1.bookmanager.com/i/n?b=" + data.eisbn + "&cb=" + data.cover_image_cache}
                            />
                        </Col>
                        <Col xs={17} lg={13} >
                            <h3><strong>{data.title}</strong>
                                <span>{data.subtitle}</span>
                            </h3>
                            <div className="shim"></div><div className="shim"></div>
                            <GiftCardAddToCart item={data} is_button eisbn={data.eisbn} />
                            {/* <Button type="primary">View gift cards</Button> */}
                        </Col>
                    </Row>
                </div>
            </>
        )
    }

    const wrapPublishDate = (_date) => {


        if (data.is_forthcoming) {
            return (<span style={{ "color": "#BD5800" }}>Releases: {moment(data.release_date * 1000).format('MMM DD, YYYY')}</span>)
        } else {
            return (<span>Published: {moment(data.release_date * 1000).format('MMM DD, YYYY')}</span>)
        }
    }


    return (
        <div className="listItem">
            <Row >
                <Col xs={7} lg={11} >

                    <LazyLoadImage
                        alt={getItemAlt(data)}
                        onError={(i) => imageFallback(i)}
                        className="shadow-light"
                        src={"https://cdn1.bookmanager.com/i/n?b=" + data.eisbn + "&cb=" + data.cover_image_cache}
                    />
                </Col>
                <Col xs={17} lg={13} >
                    <div className="shim" /><div className="shim" />
                    <h3><strong>{data.title}</strong>
                        <span>{drawSubtitle(data)}</span>
                    </h3>

                    <conditional.true value={data.authors.length > 0}>
                        <span style={{ "display": "block", "lineHeight": "1.2em" }}>by {parceAuthors(data.authors)}<br /></span>
                    </conditional.true>




                    <conditional.true value={(!window.sitesettings.hide_sales_rank)}>
                        <div className="shim" />
                        <Tooltip mouseEnterDelay={0.5} title={"Based on weekly sales from independent bookstores across the country"}>

                            <SalesIndicator rank={data.rank} /></Tooltip>
                    </conditional.true>
                    <div className="shim" />

                    
                    <span style={{ "display": "block", "lineHeight": "16px" }}><strong>{data.binding}</strong><br />
                        {(data.release_date && wrapPublishDate(data.release_date))}
                    </span>
                    <div className="shim" />


                    {processAvailable(data.available, "gift", data.price, props.show_price, data, gift_registry_id, props.popup)}


                </Col>
            </Row>
        </div>
    );


}




ItemSummary.Card = (props) => {

    const data = props.data;

    const wrapPublishDate = (_date) => {


        if (data.is_forthcoming) {
            return (<span style={{ "color": "#BD5800" }}>{moment(data.release_date * 1000).format('MMM DD, YYYY')}</span>)
        } else {
            return (<span>{moment(data.release_date * 1000).format('MMM DD, YYYY')}</span>)
        }
    }



    return (

        <Card
            className="card-summary"
            hoverable
            cover={<img onError={(i) => imageFallback(i, false)} alt={getItemAlt(data)} src={"https://cdn1.bookmanager.com/i/n?b=" + data.eisbn + "&cb=" + data.cover_image_cache} />}
        >
            <div style={{ "lineHeight": "14px" }}><strong>{data.title}</strong></div>
            <div className="shim" />
            <conditional.true value={data.authors.length > 0}>
                <small style={{ "display": "block", "lineHeight": "1.2em" }}>{parceAuthors(data.authors)}<br /></small>
            </conditional.true>


            <small style={{ "display": "block", "lineHeight": "16px" }}>{data.binding}&nbsp;|&nbsp;{(data.release_date && wrapPublishDate(data.release_date))}
            </small>
            <small>{processAvailable(data.available, "card", data.price, props.show_price, data, "", props.popup)}</small>
        </Card>
    )
}


ItemSummary.Impulse = (props) => {

    const { tempSession = {}, setTempSession = () => { }, description = "" } = props;
    const data = props.data;
    const {
        is_giftcard
    } = data;
    if (is_giftcard) {
        return (
            <>

            </>
        )
    }

    return (
        <div className="listItem">

            <div className="show">
                <strong>{data.title}</strong>
                <div className="shim" />
                <p>{description}</p>
            </div>

            <Space align="start">
                <div style={{ "width": "100px" }}>
                    <LazyLoadImage
                        alt={getItemAlt(data)}
                       
                        onError={(i) => imageFallback(i)}
                        className="shadow-light"
                        src={"https://cdn1.bookmanager.com/i/n?b=" + data.eisbn + "&cb=" + data.cover_image_cache}
                    />
                </div>
                <div className="hide">
                    <div style={{ "width": "170px" }}>
                        <strong>{data.title}</strong>
                        <div className="shim" />
                        <p>{description}</p>
                    </div>
                </div>
                <div>
                    {processAvailable(data.available, "impulse", data.price, props.show_price, data, "", props.popup, tempSession, setTempSession)}
                </div>
            </Space>
            <div aria-hidden><Divider style={{ "margin": "15px 0px" }} dashed /></div>
        </div>
    );

}

ItemSummary.ListItem = (props) => {
    const data = props.data;
    const {
        is_giftcard
    } = data;
    if (is_giftcard) {
        return (
            <>
                <div className="listItem">
                    <Row >
                        <Col xs={7} lg={11} >
                            <LazyLoadImage
                                alt={getItemAlt(data)}
                                onError={(i) => imageFallback(i)}
                                className="shadow-light"
                                src={"https://cdn1.bookmanager.com/i/n?b=" + data.eisbn + "&cb=" + data.cover_image_cache}
                            />
                        </Col>
                        <Col xs={17} lg={13} >
                            <h3><strong>{data.title}</strong>
                                <span>{data.subtitle}</span>
                            </h3>
                            <div className="shim"></div><div className="shim"></div>
                            <GiftCardAddToCart item={data} is_button eisbn={data.eisbn} />
                            {/* <Button type="primary">View gift cards</Button> */}
                        </Col>
                    </Row>
                </div>
            </>
        )
    }

    const wrapPublishDate = (_date) => {
        if (data.is_forthcoming) {
            return (<span style={{ "color": "#BD5800" }}>Releases: {moment(data.release_date * 1000).format('MMM DD, YYYY')}</span>)
        } else {
            return (<span>Published: {moment(data.release_date * 1000).format('MMM DD, YYYY')}</span>)
        }
    }
    return (
        <div className="listItem">
            <Row >
                <Col xs={7} lg={11} >
                    <LazyLoadImage
                        alt={getItemAlt(data)}
                        onError={(i) => imageFallback(i)}
                        className="shadow-light"
                        src={"https://cdn1.bookmanager.com/i/n?b=" + data.eisbn + "&cb=" + data.cover_image_cache}
                    />
                </Col>
                <Col xs={17} lg={13} >
                    <h3><strong>{data.title}</strong>
                        <span>{data.subtitle}</span>
                    </h3>
                    <conditional.true value={data.authors.length > 0}>
                        <span style={{ "display": "block", "lineHeight": "1.2em" }}>by {parceAuthors(data.authors)}<br /></span>
                    </conditional.true>
                    <conditional.string value={data.series}>
                        <div><strong>Series: </strong> {data.series} {(data.series_num) ? "#" + data.series_num : ""}</div>
                    </conditional.string>
                    <conditional.true value={data.language}>
                        <div>Language : {data.language}</div>
                    </conditional.true>
                    <conditional.true value={(!window.sitesettings.hide_sales_rank)}>
                        <div className="shim" />
                        <Tooltip mouseEnterDelay={0.5} title={"Based on weekly sales from independent bookstores across the country"}>
                            <SalesIndicator rank={data.rank} /></Tooltip>
                    </conditional.true>
                    <div className="shim" />
                    {(data.edition && <div style={{ "fontSize": "12px", "display": "block", "fontWeight": "normal", "marginLeft" : "-2px" }}><mark>{data.edition}</mark><div className="shim" /></div>)}
                    <span style={{ "display": "block", "lineHeight": "16px" }}><strong>{(data.is_large_print) ? "Large Print " : ""}{data.binding}</strong><br />
                        {(data.release_date && wrapPublishDate(data.release_date))}
                    </span>
                    <div className="shim" />
                    
                    {processAvailable(data.available, "list", data.price, props.show_price, data, "", props.popup)}
                   
                </Col>
            </Row>
        </div>
    );


}


// This is default data 
const defaultData = {
    "authors": [
        "Julie Beer",
        "Michelle Harris"
    ],
    "price": "11.99",
    "rank": {
        "bestseller": 4144
    },
    "binding": "Paperback",
    "release_date": 1599548400,
    "available_in_store": 19,
    "available": [
        {
            "code": "",
            "text": "New",
            "row_price": "29.95",
            "used": false,
            "note": "",
            "onhand": 8,
            "discount": 0,
            "availability": "available",
            "message": "New",
            "sub_message": "8 in store"
        },
        {
            "code": "",
            "text": "",
            "row_price": "0.00",
            "used": "",
            "note": "",
            "onhand": 0,
            "discount": 0,
            "availability": "out_of_print",
            "message": "SPECIAL ORDER",
            "sub_message": "May be slow to obtain. Suppliers are waiting for stock."
        },

        {
            "code": "",
            "text": "",
            "row_price": "0.00",
            "used": "",
            "note": "",
            "onhand": 0,
            "discount": 0,
            "availability": "out_of_stock_indefinitely",
            "message": "SPECIAL ORDER",
            "sub_message": "May be slow to obtain. Suppliers are waiting for stock."
        },

        {
            "code": "",
            "text": "New",
            "row_price": "23.00",
            "used": "",
            "note": "",
            "onhand": 0,
            "discount": 0,
            "availability": "forthcoming",
            "message": "SPECIAL ORDER",
            "sub_message": "May be slow to obtain. Suppliers are waiting for stock."
        },

        {
            "code": "",
            "text": "New",
            "row_price": "10.00",
            "used": "",
            "note": "",
            "onhand": 0,
            "discount": 0,
            "availability": "available",
            "message": "SPECIAL ORDER",
            "sub_message": "About 4 days for in-store pickup"
        },

        {
            "code": "",
            "text": "New",
            "row_price": "0.00",
            "used": "",
            "note": "",
            "onhand": 0,
            "discount": 0,
            "availability": "no_price",
            "message": "SPECIAL ORDER",
            "sub_message": "May be slow to obtain. Suppliers are waiting for stock."
        }

    ],
    "title": "Weird But True Halloween",
    "subtitle": "300 Spooky Facts to Scare You Silly",
    "eisbn": "rn8L4rYV4dGorZOZRggfUQ"
}



ItemSummary.ShowOptions = (props) => {


    const data = (props.data) ? props.data : defaultData;

    return (
        <div>
            <h2>Data Object</h2>
            <ItemSummary data={data} />
            <div aria-hidden><Divider /></div>


            <h2>Sidebar Object</h2>
            <Row>
                <Col style={{ "backgroundColor": "#eee", "padding": "20px" }} span={5}>
                    <ItemSummary.SideBar data={data} />
                </Col>
            </Row>
            <div aria-hidden><Divider /></div>

            <h2>Slide Object</h2>
            <ItemSummary.Slide data={data} />
            <div aria-hidden><Divider /></div>


            <h2>Image Object</h2>
            <ItemSummary.Image data={data} />
            <div aria-hidden><Divider /></div>

            <h2>List Object</h2>
            <div style={{ "maxWidth": "500px" }}>
                <ItemSummary.ListItem data={data} />
            </div>
            <div aria-hidden><Divider /></div>
        </div>
    );
}




export default ItemSummary;
import React, { useState, useEffect, useRef } from 'react';
import { motion, useMotionValue, useAnimation } from 'framer-motion';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button } from 'antd';

function Scroller(props) {
    const constraintsRef = useRef(null);
    const contentRef = useRef(null);
    const [sliderWidth, setSliderWidth] = useState(0);
    const [scrollerBtns, setScrollerBtns] = useState({
        left: false,
        right: true,
    })
    const animate = useAnimation();

    const {
        button_type = 0,
        gap = 10,
    } = props;

    const handleNext = () => {
        let _v = (x.prev) ? x.prev : 0;
        let _newpos = _v - constraintsRef.current?.offsetWidth;
        if (_newpos < -sliderWidth) {
            _newpos = -sliderWidth;
        }
        x.stop();
        animate.start({
            x: _newpos,
            transition: { duration: 0.5, ease: "easeOut" }
        })
    };

    const handlePrev = () => {
        let _v = (x.prev) ? x.prev : 0;
        let _newpos = _v + constraintsRef.current?.offsetWidth;
        if (_newpos > 0) {
            _newpos = 0;
        }
        x.stop();
        animate.start({
            x: _newpos,
            transition: { duration: 0.5, ease: "easeOut" }
        })
    };

    const x = useMotionValue(0);

    useEffect(() => {
        const unsubscribe = x.onChange((v) => {
            let _right = contentRef.current?.offsetWidth - constraintsRef.current?.offsetWidth;
            if (v < -10) {
                if (!scrollerBtns.left) {
                    setScrollerBtns({ left: true, right: true })
                }
            } else {
                if (scrollerBtns.left) {
                    setScrollerBtns({ left: false, right: true })
                }
            }
            if (v < -(_right - 10)) {
                if (scrollerBtns.right) {
                    setScrollerBtns({ left: true, right: false })
                }
            } else {
                if (!scrollerBtns.right) {
                    setScrollerBtns({ left: true, right: true })
                }
            }
        });

        return () => unsubscribe();
    }, [x, scrollerBtns]);

    useEffect(() => {


        const updateHeight = () => {
            let _content_width = contentRef.current?.offsetWidth | 0;
            let _constrains_width = constraintsRef.current?.offsetWidth | 0
            setSliderWidth(_content_width - _constrains_width);
        }

        updateHeight();

        setTimeout(() => {
            updateHeight();
        }, 500);

        setTimeout(() => {
            updateHeight();
        }, 1000)

    }, [sliderWidth]);

    const can_drag = (constraintsRef.current?.offsetWidth < contentRef.current?.offsetWidth) ? true : false;


    return (
        <>
            <div style={{ overflow: 'hidden', position: 'relative', width: '100%' }}>
                {(can_drag &&
                    <>
                        <Button aria-label="Click to view next set" className={"bm-btn-" + button_type} onClick={handlePrev} style={{ position: 'absolute', left: 0, top: '50%', transform: 'translateY(-50%)', zIndex: 1, "opacity": (scrollerBtns.left) ? "1" : "0" }}>
                            <LeftOutlined style={{ "minWidth": "20px" }} />
                        </Button>
                        <Button aria-label="Click to view next set" className={"bm-btn-" + button_type} onClick={handleNext} style={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)', right: 0, zIndex: 1, "opacity": (scrollerBtns.right) ? "1" : "0" }} >
                            <RightOutlined style={{ "minWidth": "20px" }} />
                        </Button>
                    </>
                )}


                <div ref={constraintsRef} style={{ width: '100%', height: '100%', overflow: 'hidden' }}>
                    <motion.div
                        ref={contentRef}
                        drag="x"
                        animate={animate}
                        dragConstraints={{ right: 0, left: (can_drag) ? -sliderWidth : 0 }}
                        dragElastic={(can_drag) ? 0.2 : 0}
                        style={{ display: 'flex', width: 'fit-content', height: '100%', x: x, }}
                    >
                        <div style={{ "display": "flex", "gap": gap + "px" }}>
                            {props.children}
                        </div>
                    </motion.div>
                </div>
            </div>
        </>
    );
}

export default Scroller;


import { Divider, Form, Input } from "antd";
import React from "react";

import { tempform } from "../../../form";
import TextInputField from "./elements/TextInputField";
import TextAreaField from "./elements/TextAreaField";
import TextEmailField from "./elements/TextEmailField";
import TextPhoneField from "./elements/TextPhoneField";
import TextDateField from "./elements/TextDateField";
import SelectBoxField from "./elements/SelectBoxField";
import CheckBoxField from "./elements/CheckBoxField";
import RadioField from "./elements/RadioField";
import TextDayMonthField from "./elements/TextDayMonthField";

export default function CustomForm(props) {

    const { form, saved = {}, formdata = {} } = props;
    const { delivery = "", contact = {} } = saved;


    const drawElement = (_data) => {

        switch (_data.type) {
            case "text":
                return (<TextInputField contact={contact} form={form} data={_data} />);
            case "textarea":
                return (<TextAreaField contact={contact} form={form} data={_data} />);
            case "email":
                return (<TextEmailField contact={contact} form={form} data={_data} />);
            case "phone":
                return (<TextPhoneField contact={contact} form={form} data={_data} />);
            case "date":
                return (<TextDateField contact={contact} form={form} data={_data} />);
            case "daymonth":
                return (<TextDayMonthField contact={contact} form={form} data={_data} />);
            case "select":
                return (<SelectBoxField contact={contact} form={form} data={_data} />);
            case "checkbox":
                return (<CheckBoxField contact={contact} form={form} data={_data} />);
            case "radio":
                return (<RadioField contact={contact} form={form} data={_data} />);
        }
    }

    const drawElements = (_formdata) => {
        return _formdata.elements.map(item => {
            return <div>{drawElement(item)}<div className="shim" /><div className="shim" /></div>;
        })
    }


    const drawForm = () => {

        if (formdata) {
            return (
                <>
                    <div>
                        <div className="shim" /><div className="shim" />
                        <Divider orientation="left"><big><strong>{formdata.header}</strong></big></Divider>
                        <div>{formdata.description}</div>
                        <div className="shim" /><div className="shim" />
                        {drawElements(formdata)}
                    </div>
                </>
            )
        } else {
            return (<></>)
        }


    }

    return drawForm();

}
import React, { useEffect, useState } from "react";

import { Button, Layout, Alert, Form, Modal, Typography, Select, message } from 'antd';
import { CartSummary } from "./Cart";
import ShippingForm from "./ShippingForm";
import Session, { useSession } from "../../../util/Session";
import { apiCall } from "../../utilities/Api";
import { useHistory } from "react-router-dom";
import conditional from "../../utilities/conditional";

export default function Shipping(props) {

    const history = useHistory();
    const [errorMSG, setErrorMSG] = useState("");
    const [shippingForm] = Form.useForm();
    const [session, setSession] = useSession();
    const { saved, setSaved } = props;
    const [loading, setLoading] = useState(false);

    const getSession = () => {
        setLoading(true);
        apiCall("session/get", {}, (_status, _result) => {
            if (_status) {
                setSession(_result);
            }
            setLoading(false);
        })
    }

    useEffect(getSession, []);





    const setShipping = (_form, _proceed = false) => {

        setLoading(true);
        setErrorMSG("");
        setSaved({ ...saved, "shipping": _form });

        let args = {};
        args.address_id = (_form.address_id) ? _form.address_id : 0;

        if (_form.hasOwnProperty("gift_registry_id")) {
            args.gift_registry_id = _form.gift_registry_id;
        }

        if (saved.hasOwnProperty("delivery")) {
            args.delivery_preference = saved.delivery;
        }

       
        apiCall("checkout/getShippingProviders", { address_id: args.address_id }, (_status, _result) => {
            if (_status) {
                if (_proceed) {
                    history.push("/checkout/provider");
                }

            } else {
                setErrorMSG(_result.error);
                message.error(<span aria-live="assertive">{_result.error}</span>)
            }
            setLoading(false);
        });

    }



    const resendShipping = (_id) => {

        let obj = {
            address_id: _id
        }
        if (saved.hasOwnProperty("delivery")) {
            obj.delivery_preference = saved.delivery;
        }

        setErrorMSG("");
        apiCall("checkout/getShippingProviders", { address_id: _id }, (_status, _result) => {
            if (_status) {

                history.push("/checkout/provider");
            } else {
                setErrorMSG(_result.error);
            }
        });

    }




    const saveAndContinue = () => {
        if (saved.shipping.hasOwnProperty("address_id")) {
            // Server needs this because it saves as snapshot in time data
            resendShipping(saved.shipping.address_id);
            //history.push("/checkout/contact");
        } else {
            shippingForm.submit();
        }
    }





    const displaySummary = () => {




        return (
            <CartSummary showShipping={false} >
                <br />
                <conditional.true value={(errorMSG && errorMSG === "Local delivery failed")}>
                    <Alert className="msg" description={<><span style={{ "textAlign": "center", "width": "100%", "display": "block" }}>
                        <>Local delivery is available to {window.sitesettings.checkout_info.local_delivery_availability}. <strong>Your shipping address does not qualify for local delivery. </strong>
                            <conditional.true value={window.sitesettings.checkout_info.ship_enabled}>
                                Please choose a different shipping address, or go back and select "Ship" to proceed.
                            </conditional.true>
                            <conditional.true value={!window.sitesettings.checkout_info.ship_enabled}>
                                Please choose a different shipping address.
                            </conditional.true>
                        </>
                    </span></>} type="error" /><br />
                </conditional.true>
                <conditional.true value={(errorMSG && errorMSG === "Not available")}>
                    <Alert className="msg" description={<><span style={{ "textAlign": "center", "width": "100%", "display": "block" }}>
                        Shipping to  {saved.shipping.province}, {saved.shipping.country} is unavailable.
                    </span></>} type="error" /><br />
                </conditional.true>
                <conditional.true value={(errorMSG && errorMSG === "Invalid country name")}>
                    <Alert className="msg" description={<><span style={{ "textAlign": "center", "width": "100%", "display": "block" }}>
                        Shipping address required.
                    </span></>} type="error" /><br />
                </conditional.true>
                <conditional.true value={(errorMSG && errorMSG !== "Not available" && errorMSG !== "Local delivery failed" && errorMSG !== "Invalid country name")}>
                    <Alert className="msg" description={<><span style={{ "textAlign": "center", "width": "100%", "display": "block" }}>
                        {errorMSG}
                    </span></>} type="error" /><br />
                </conditional.true>
                <Button block loading={loading} onClick={() => saveAndContinue()} type="primary">Save &amp; Continue</Button>
            </CartSummary>
        )
    }




    return (
        <div>
            <Layout className="responsiveSider">
                <Layout.Content style={{ "width": "auto" }}>
                    <Form form={shippingForm} onFinish={(_form) => setShipping(_form, true)} layout="vertical">
                        <ShippingForm setLoading={setLoading} setShipping={setShipping} errorMSG={errorMSG} setErrorMSG={setErrorMSG} form={shippingForm} delivery={saved.delivery} setSaved={setSaved} saved={saved} />
                    </Form>
                </Layout.Content>
                <Layout.Sider width="none" className="responsiveSummary" theme="light">
                    {/* <Affix className="hide" offsetTop={85}  > */}
                    <div className="hide">{displaySummary()}</div>
                    {/* </Affix> */}
                    <div className="show">{displaySummary()}</div>
                    <br /><br />
                </Layout.Sider>
            </Layout>
        </div>
    )
}
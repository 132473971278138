import { Divider } from "antd";
import React from "react";
import { getColor } from "../util/CC";
import { useMediaQuery } from "react-responsive";
import Html from "../components/utilities/Html";

export default function TextColumns(props) {
   
    const template = window.sitesettings.template_info;
    const ismobile = useMediaQuery({ query: '(max-width: 716px)' })    
    // Component agnostic
    const {
        name = "",
        container = true,
        data = {},
        date_changed = 0
    } = props.content;

    // Component specific
    const {
        width = 1248,
        contained = false,
        padding_y = 10,
        padding_x = 10,
        background_colour = 0,
        segments = [
            { headline: "Headline", paragraph: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque tincidunt enim vel lectus egestas hendrerit. Sed at tempus ante. Interdum et malesuada fames primis in faucibus." },
            { headline: "Headline", paragraph: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque tincidunt enim vel lectus egestas hendrerit. Sed at tempus ante. Interdum et malesuada fames primis in faucibus." },
            { headline: "Headline", paragraph: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque tincidunt enim vel lectus egestas hendrerit. Sed at tempus ante. Interdum et malesuada fames primis in faucibus." },
        ],
        headline_preset = 0,
        paragraph_preset = 0,
        align = "center",
        gap = 10,
        spacer = 0,
        spacer_colour = 0,
        border = 0,
        border_colour = 0,
        columns = 3,
        allow_segment_breaks = false,
        mobile_columns = 1,
        mobile_padding_y = 10,
        mobile_padding_x = 10,
        allow_mobile_overrides = false,

    } = data;


    const drawRender = () => {

        const m = (allow_mobile_overrides && ismobile) ? true : false;

        const _style = {
            background: getColor(background_colour, template),
            border: border + "px solid " + getColor(border_colour, template, "#000"),
            padding: (m) ? `${mobile_padding_y}px ${mobile_padding_x}px` : `${padding_y}px ${padding_x}px`,
            width: (contained && !m) ? width + "px" : "100%",
            margin: align
        };

        return (
            <div className={(container) ? "preview-container" : ""}>
                <div style={_style}>
                    <div style={{ columnCount: (m) ? mobile_columns : columns, columnGap: gap + "px" }}>
                        {segments.map((item, index) => (
                            <div key={index} style={{ marginBottom: '10px', breakInside: (allow_segment_breaks) ? "auto" : "avoid", }}>
                                {(item?.headline && <h2 style={{ "display": "block" }} className={"bm-txt-" + headline_preset} >
                                    {item.headline}
                                </h2>)}
                                {(item?.paragraph &&
                                    <div className={"bm-component  bm-txt-" + paragraph_preset} >
                                        <Html clear={false} className={""} html={item.paragraph} />
                                    </div>
                                )}
                                {((index !== segments.length - 1 && spacer > 0) && <Divider style={{ "borderTop": spacer + "px solid " + getColor(spacer_colour, template, "#000") }} />)}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        )
    }


    return drawRender();

}
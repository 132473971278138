import { message, Modal } from "antd";
import React from "react";
import { useEffect } from "react";
import { $ } from 'react-jquery-plugin';
import { useHistory } from "react-router-dom";
import { apiCall } from "../../utilities/Api";
import LoadingOverlay from "./LoadingOverlay";
import { useState } from "react";
export default function ClearentPaymentsForm(props) {


    const { onComplete = () => { } } = props;
    const history = useHistory();
    const [loading, setLoading] = useState(false);


    const completeTransaction = (_response) => {

        window.Clearent.modalClose();
        onComplete(JSON.stringify(_response));

        // let _obj = {
        //     provider: "clearent",
        //     transaction_data: JSON.stringify(_response)
        // }

        // setLoading(true);

       
        // apiCall("checkout/cardPayment", _obj, (_status, _result) => {
        //     setLoading(false);
        //     if (_status) {
        //         let order_number = _result.order_number;
        //         history.push({ "pathname": "/account/order/" + order_number, "state": { "data": _result }, "search": "success" });

        //     } else {
        //         Modal.error({ title: "There was an error", content: <>{_result.error}</> })
        //     }
        // })

    }

    const { amount } = props;

    useEffect(() => {

        // Using Jquery to load the clearent script - !NOTE the script is also loaded in index.html to make the form styles behave properly
        $.getScript("https://" + window.clearent_host + "/js/clearent.js", () => {

            let cl = window.Clearent;

            // Public Key
            cl.setProperty("pk", window.sitesettings.checkout_info.clearent_pk);
            // HTML Element ID (where the button gets drawn)
            cl.setProperty("pay-button-parent", "clearent_button");

            // If capture setting, collect payment right away - otherwise collect the token to be processed by Bookmanager later
            if (window.sitesettings.checkout_info.clearent_should_capture) {
                // cl.setProperty("request-type", "SALE");
                cl.setProperty("amount", amount);
                // Show save card option (This will return token as well)
                cl.setProperty("show-save-card-option", true);
            } else {
                // cl.setProperty("request-type", "TOKEN-ONLY");

                cl.setProperty("card-description-placeholder", "Cardholder name");
                cl.setProperty("card-description-tooltip", "Enter the name on this card");
                cl.setProperty("add-payment-button-text", "Pay Now");
                cl.setProperty("submit-pay-method-button-text", "Pay $" + amount + " Now");
                cl.setProperty("card-token-only", true);
            }

            // Run
            cl.payButton();

            window.ClearentOnSuccess = (responseRaw, responseJSON) => {
                console.log("transaction succeeded");
                completeTransaction(responseJSON);
            }

            window.ClearentOnError = (responseRaw, responseJSON) => {
                console.log("transaction failed");
                completeTransaction(responseJSON);
            }

        });

        return () => {
            // this doesn't work.
            window.Clearent.modalClose();
        };


    }, [])

    return (
        <>
            <LoadingOverlay loading={loading} />
            <div id="clearent_button"></div>
        </>
    )

}
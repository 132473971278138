import { message, Modal } from "antd";
import React from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { apiCall } from "../../utilities/Api";
import { useState } from "react";
import { useSession } from "../../../util/Session";

export default function GlobalPaymentsForm(props) {

    const { setOpen = () => { }, amount = "", onClose = () => {}, onComplete = () => {} } = props;
    const [session, setSession] = useSession();
    const { setLoading = () => { } } = props;
    const history = useHistory();
    let gp = window.GlobalPayments;
    const [postalCode, setPostalCode] = useState('');

    const cardPayment = (_response, _postal_code) => {


        onComplete(JSON.stringify(_response));
        return; 

        let _obj = {
            provider: "global",
            transaction_data: JSON.stringify(_response),
        }

        if (!session.logged_in) {
            _obj.postal_code = _postal_code
        }

        setLoading(true);
        apiCall("checkout/cardPayment", _obj, (_status, _result) => {
            setLoading(false)
            if (_status) {
                setOpen(false);
                let order_number = _result.order_number;
                history.push({ "pathname": "/account/order/" + order_number, "state": { "data": _result }, "search": "success" });
            } else {
                Modal.error({ title: "There was an error", content: <>{_result.error}</> })
            }
        })
    }

    const initGP = () => {

        let ec = 0;

        gp.configure({
            publicApiKey: window.sitesettings.checkout_info.global_pk
        });

        const cardForm = gp.ui.form({
            fields: {
                "card-holder-name": {
                    placeholder: "Card Holder Name...",
                    target: "#credit-card-card-holder"
                },
                "card-number": {
                    placeholder: "Card Number...",
                    target: "#credit-card-card-number"
                },
                "card-expiration": {
                    placeholder: "MM / YYYY",
                    target: "#credit-card-card-expiration"
                },
                "card-cvv": {
                    placeholder: "CVV...",
                    target: "#credit-card-card-cvv"
                },
                "submit": {
                    value: "Submit",
                    target: "#credit-card-submit"
                }
            },
            styles: {

                ".card-cvv" : {
                    "background": "transparent url(https://js.globalpay.com/v1/images/cvv.png) no-repeat right",
                    "background-size": "47px 30px",
                    "background-position": "98%",
                },

                "img.card-number-icon": {
                    "background": "transparent url(https://js.globalpay.com/v1/images/logo-unknown@2x.png) no-repeat",
                    "background-size": "100%",
                    "width": "49px",
                    "height": "30px",
                    "right": "8px",
                    "top": "50%",
                    "margin-top": "-15px",
                    "background-position": "50% 50%",
                },

                "img.card-number-icon.valid.card-type-visa": {
                    "background": "transparent url(https://js.globalpay.com/v1/images/logo-visa@2x.png) no-repeat",
                        "background-size": "120%",
                        "background-position-y": "-1px"
                },
                
                "img.card-number-icon.invalid.card-type-visa": {
                    "background": "transparent url(https://js.globalpay.com/v1/images/logo-visa@2x.png) no-repeat",
                        "background-size": "120%",
                        "background-position-y": "bottom"
                },
                
                "img.card-number-icon.valid.card-type-amex": {
                    "background": "transparent url(https://js.globalpay.com/v1/images/logo-amex@2x.png) no-repeat 140%",
                        "background-size": "80%",
                        "background-position-y": "-6px"
                },
                
                "img.card-number-icon.invalid.card-type-amex": {
                   "background": "transparent url(https://js.globalpay.com/v1/images/logo-amex@2x.png) no-repeat 140%",
                        "background-size": "80%",
                        "background-position-y": "87%"
                },
                
                "img.card-number-icon.valid.card-type-mastercard": {
                   "background": "transparent url(https://js.globalpay.com/v1/images/logo-mastercard@2x.png) no-repeat",
                        "background-size": "120%",
                        "background-position-y": "-1px"
                },
                
                "img.card-number-icon.invalid.card-type-mastercard": {
                    "background": "transparent url(https://js.globalpay.com/v1/images/logo-mastercard@2x.png) no-repeat",
                        "background-size": "120%",
                        "background-position-y": "bottom"
                },

                "input[type=text]": {
                    "margin-bottom": "20px",
                    "padding": "12px",
                    "border": "1px solid #ccc",
                    "border-radius": "0px",
                },

                //adding focus on the fields when selected
                "input[type=text]:focus-visible, input[type=tel]:focus-visible": {
                    "outline": "none !important",
                    "border": "1px solid #71C5E8",
                    "box-shadow": "0 0 4px 0 #71C5E8 inset",
                },

                //adding a radius on all number inputs
                "input[type=tel]": {
                    "margin-bottom": "20px",
                    "padding": "12px",
                    "border": "1px solid #ccc",
                    "border-radius": "0px"
                },


                //adding focus on the fields when selected
                "button[type=button]:focus-visible": {
                    "background-color": "#71C5E8",
                    "outline": "none !important",
                    "border": "1px solid gray",
                    "box-shadow": "0 -1px 4px 0 gray inset",
                },

                //adding styling to the submit button
                "button[type=button]": {
                    "background-color": "#000000",
                    "color": "white",
                    "padding": "12px",
                    "margin": "10px",
                    "border": "none",
                    "border-radius": "0px",
                    "cursor": "pointer",
                    "font-size": "17px",
                },

                //adding hover color
                "button[type=button]:hover": {
                    "background-color": "#222222",
                },
            }
        });

        cardForm.on("token-success", (resp) => {
            let _pc = (!session.logged_in) ? document.getElementById("postal-code").value : ""
            cardPayment(resp, _pc);
        });

        cardForm.on("token-error", (resp) => {
            ec++; 
            if(ec > 2){
                message.error("There was an error - Too many attempts");
                onClose();
                return; 
            } 
            message.error("There was an error");
        });
    }

    useEffect(initGP, [])

    const handleInputChange = (event) => {
        let reg = /^[a-zA-Z0-9\s]+$/;
        let input = event.target.value;
        if (reg.test(input)) {
            setPostalCode(event.target.value.toUpperCase());
        } else {
            if (event.target.value === "") {
                setPostalCode("")
            }
        }
    };

    return (
        <>
            <form id="payment-form" action="/Payment/Charge" method="get">
                <div id="sandbox-warning"></div>
                <label htmlFor="card-number">Card Holder Name</label>
                <div id="credit-card-card-holder"></div>
                <div className="shim" />
                <label htmlFor="card-number">Card Number</label>
                <div id="credit-card-card-number"></div>
                <div className="shim" />
                <label htmlFor="card-number">Card CVV</label>
                <div id="credit-card-card-cvv"></div>
                <div className="shim" />
                <label htmlFor="card-number">Card Expiration</label>
                <div id="credit-card-card-expiration"></div>
                <div className="shim" />
                {(!session.logged_in && <>
                    <label htmlFor="postal-code">Postal Code</label>
                    <div className="shim" />
                    <input maxLength={7} value={postalCode} onChange={handleInputChange} className="global-postal-code" placeholder="A1A 1A1" id="postal-code" type="text" name="billing-zip"   />
                    <div className="shim" />
                    <div className="shim" />
                </>)}
                <div className="shim" />
                <div className="shim" />
                <div id="credit-card-submit"></div>
            </form>
            <br clear="all" />
        </>
    )
}
import { Button, Divider, Alert } from "antd";
import Modal from "antd/lib/modal/Modal";
import React, { useState } from "react";
import { pluralize } from "../../../util/Utils";
import { apiCall } from "../../utilities/Api";

export default function AlsoAvailable(props) {

    const { eisbn = "", summary = false, count = "0" } = props;

    const [data, setData] = useState((props.data) ? props.data : []);

    // check which stores have any copies on hand, cull ones that don't
    const parseAvailabie = (_arr) => {
        let _stores = []
        _arr.forEach(itm => {
            let _store_available = false;
            itm.available.forEach(_itm => {
                if (_itm.onhand) {
                    _store_available = true;
                }
            })
            if (_store_available) {
                _stores.push(itm);
            }
        });
        return _stores;
    }

    const [modalVisible, setModalVisible] = useState();

    const changeLocation = (_loc_id) => {
        apiCall("webstore/setMultistoreLocation", { location_id: _loc_id }, (_status, _result) => {
            if (_status) {
                window.location.reload();
            }
        });
    }

    const drawAvailable = (_arr) => {
        return _arr.map((item, index) => {
            return <div>{(item.text) ? item.text + " available " : "Available "}for ${item.row_price}</div>
        })
    }

    const drawStores = (_arr) => {
        return _arr.map((item, index) => {
            return (
                <>
                    <div><strong>{item.name}</strong></div>
                    {drawAvailable(item.available)}
                    <div style={{ "float": "right", "marginTop": "-15px" }}><Button onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        changeLocation(item.location_id)
                    }} size="small"><small>Change location</small></Button></div>
                    <div aria-hidden><Divider dashed /></div>
                </>
            )
        })
    }

    // No stores have copies, return nothing

    if (!summary && parseAvailabie(data).length < 1) {
        return (<></>)
    }


    const getItem = () => {

        apiCall("title/getItem", { eisbn: eisbn }, (_status, _result) => {
            if (_status) {

                console.log(_result.multistore_available);
                setData(_result.multistore_available);
                setModalVisible(true)
            }
        })

    }


    const drawAt = (_count, _data = []) => {

        //_data = _data.filter(item => item.location_id !== window.sitesettings.store_info.id); 

        if (_data.length === 1) {
            return (
                <>
                    Also available at:
                    <div><strong>{_data[0].name}.</strong></div>
                    {drawAvailable(_data[0].available)}
                </>
            )
        }

        if (_data.length > 0) {
            _count = _data.length;
        }

        return (<>Available at <strong>{_count}</strong> other {pluralize(parseInt(_count), "location", "s")}.</>)
    }


    return (
        <>

            <Modal onClick={() => {

            }} footer={null} title={<div>Availability at other locations</div>} onCancel={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setModalVisible(false)


            }} open={modalVisible}>

                <div onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                }}>

                    {/* <Alert message="Warning: your cart will be emptied when changing locations" type="warning" /> */}
                    <div aria-hidden><Divider /></div>
                    {drawStores(parseAvailabie(data))}
                </div>

            </Modal>
            <br />
            {/* Summary */}
            {((summary && count !== "0" && count !== null) && <>
                <><a style={{ "color": "#069" }} tabIndex={0} onKeyDown={(e) => { if (e.key === "Enter") { e.preventDefault(); e.stopPropagation(); getItem() } }} onClick={(e) => { e.preventDefault(); e.stopPropagation(); getItem() }}>{drawAt(count)}</a><div className="shim" /></>
            </>)}
            {/* Details */}

            {(!summary && <>
                <a style={{ "color": "#069" }} onClick={() => setModalVisible(true)}>{drawAt(count, parseAvailabie(data))}</a>
            </>)}

        </>
    )
}
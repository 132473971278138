import React, { useState } from 'react';

function DragButton(props) {

    const { onClick = () => { }, title = "" } = props;

    const [startPosition, setStartPosition] = useState({ x: 0, y: 0 });
    const handleMouseDown = (event) => {
        setStartPosition({ x: event.clientX || event.touches[0].clientX, y: event.clientY || event.touches[0].clientY });
    };

    const handleMouseUp = (event) => {
        const endPosition = { x: event.clientX || event.changedTouches[0].clientX, y: event.clientY || event.changedTouches[0].clientY };
        const movementThreshold = 5;
        if (
            Math.abs(endPosition.x - startPosition.x) <= movementThreshold &&
            Math.abs(endPosition.y - startPosition.y) <= movementThreshold
        ) {
            onClick(event)
        }
    };

    return (
        <div
            onMouseDown={handleMouseDown}
            onTouchStart={handleMouseDown}
            onMouseUp={handleMouseUp}
            onTouchEnd={handleMouseUp}
        >
            {props.children}
        </div>
    );
}

export default DragButton;

import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSession } from "../util/Session";
import { Popover, Button, Divider, Drawer, Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { apiCall } from "../components/utilities/Api";
import PermissionSelector from "./PermissionSelector";
import AccessiblePopover from "./components/AccessiblePopover";

function AccountBox(props) {
  const { avatar_color = "" } = props;
  const history = useHistory();
  const [session, setSession] = useSession();
  const [popOverVisible, setPopOverVisible] = useState(false);

  const dismiss = () => {
    setPopOverVisible(false);
    setVisible(false);
  };

  const handlePopOverVisibleChange = (visible) => {
    setPopOverVisible(visible);
  };

  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const onLogout = (_status, _result) => {
    if (_status) {
      setSession(_result);
    } else {
      console.log("Failed to logout - Weird Error");
      setSession({
        session_id: "26EkYyG1XfMJCEtSuq9dRW8ow1LP",
        logged_in: false,
        user: [],
        cart: [],
      });
    }
  };

  const logout = () => {
    setPopOverVisible(false);
    apiCall("account/logout", {}, onLogout);
  };

  const getInitals = () => {
    let _f = session.user.first_name.substr(0, 1);
    let _l = session.user.last_name.substr(0, 1);
    return _f + _l;
  };

  const loginForm = (
    <div style={{ minWidth: "180px" }}>
      <PermissionSelector dismiss={dismiss} />
      <Button aria-label="Account Details" onClick={() => { dismiss(); history.push("/account/details"); }} style={{ width: "100%", marginBottom: "10px" }}>
        Account Details
      </Button>
      <br />
      <Button aria-label="Address Book" onClick={() => { dismiss(); history.push("/account/addresses"); }} style={{ width: "100%", marginBottom: "10px" }}>
        Address Book
      </Button>
      <br />
      {((window.sitesettings.gift_registry_enabled) &&
        <Button block style={{ marginBottom: "10px" }} onClick={() => {dismiss(); history.push("/gift-registry")}}>Gift Registry</Button>
      )}
      <Button aria-label="My Lists" onClick={() => { dismiss(); history.push("/account/lists"); }} style={{ width: "100%", marginBottom: "10px" }}>
        My Lists
      </Button>
      <Button aria-label="View Orders" onClick={() => { dismiss(); history.push("/account/orders"); }} style={{ width: "100%" }}>
        View Orders
      </Button>
      <Divider />
      <Button aria-label="Logout" onClick={() => { dismiss(); logout(); }} style={{ width: "100%" }} type="danger">
        Log out
      </Button>
    </div>
  );


  const name = session.user.is_institution && session.user.company
  ? session.user.company
  : session.user.first_name


  return (
    <div>
      {/* Desktop Popover */}
      <AccessiblePopover
        open={popOverVisible}
        onOpenChange={handlePopOverVisibleChange}
        title="Account"
        overlayClassName="fixed"
        className="hide"
        trigger="click"
        content={loginForm}
        placement="bottomRight"
        icon={<UserOutlined aria-hidden />}
        label={name}
      />

      {/* Mobile Drawer */}
      <div className="show">
        <Avatar
          style={{
            marginTop: "0px",
            cursor: "pointer",
            backgroundColor: "#ffffff11",
            color: avatar_color,
            boxShadow: "0px 0px 0px 1px #00000011",
          }}
          onClick={showDrawer}
          className="bm-icon"
          size={28}
          aria-expanded={visible} // Announce expanded state
          aria-controls="account-drawer" // Associate button with drawer
        >
          <div style={{ marginTop: "0px" }}>{getInitals()}</div>
        </Avatar>
        <Drawer
          placement="bottom"
          title={session.user.first_name + " " + session.user.last_name}
          height="380px"
          mask={false}
          onClose={onClose}
          open={visible}
        >
          <div style={{ backgroundColor: "#fff", padding: "20px" }}>
            {loginForm}
            <br />
          </div>
        </Drawer>
      </div>
    </div>
  );
}

export default AccountBox;

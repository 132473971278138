import React, { useState, useRef, useEffect } from "react";
import { Button, Divider, Input, List, Row, Col, Space, Form, Modal } from 'antd';
import { CreditCardOutlined } from '@ant-design/icons';
import conditional from "../../utilities/conditional";
import { apiCall } from "../../utilities/Api";
import { useSession } from "../../../util/Session";
import { useForm } from "antd/lib/form/Form";
import ReCAPTCHA from "react-google-recaptcha";

/*

    Add a gift card @ max 
    Edit amount used ()

*/

export default function GiftCardPurchase() {

    const [session, setSession] = useSession();
    const [loading, setLoading] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [currentGiftCard, setCurrentGiftcard] = useState({});
    const [currentBalance, setCurrentBalance] = useState("");
    const [addError, setAddError] = useState("");
    const [editError, setEditError] = useState("");
  
    const [canAdd, setCanAdd] = useState(false);

    const [addForm] = useForm();
      
    // Fetch recaptcha
    const recaptchaRef = useRef();
    const recaptchaRef2 = useRef();

    const [numberPin, setNumberPin] = useState({
        number : "",
        pin : ""
    })

    const onAddGiftCard = (_status, _result) => {

      
        if (_status) {
            addForm.resetFields(); 
            setCanAdd(false);
            window.checksum = _result.checksum;
            setSession({ ...session, "cart": _result });
        } else {

            if(_result.error === "This card has a balance of $0"){
                addForm.resetFields(); 
            }
            setAddError(_result.error);
        }
        setLoading(false);

        setNumberPin({
            number : "",
            pin : ""
        })
    }

    const addGiftCard = (_f) => {
       
        setNumberPin({
            number : _f["gift_card_number"].replace(/ /g, ''),
            pin : _f["pin"]
        })

        setAddError("")
        setLoading(true);
        
        recaptchaRef.current.reset();
        recaptchaRef.current.execute();

    }


    const onChange = (_captcha) => {

        let _args = {
            pin: numberPin.pin.replace(" ", ""), 
            captcha : _captcha, 
            gift_card_number: numberPin.number.replace(" ", "")
        }

        
      
        apiCall("checkout/addGiftCard", _args, onAddGiftCard);
    }



    const onEditCard = (_status, _result) => {
        
        if (_status) {
            setCurrentBalance(_result.balance);
            setModalVisible(true);
        }
    }

    const editCard = (_card) => {
        setCurrentGiftcard(_card);
        recaptchaRef2.current.reset();
        recaptchaRef2.current.execute();
    }

    const onChange2 = (_captcha) => {
    
        apiCall("giftcard/balance", { number: currentGiftCard.number.replace(" ", ""), captcha : _captcha }, onEditCard);
    }

    const onRemoveGiftCard = (_status, _result) => {
        if (_status) {
            window.checksum = _result.checksum;
            setSession({ ...session, "cart": _result })
            setModalVisible(false);
        }
    }

    const removeGiftCard = (_num) => {
        apiCall("checkout/removeGiftCard", { gift_card_number: _num }, onRemoveGiftCard)
    }

    const onChangeGiftCard = (_status, _result) => {
        if (_status) {
            window.checksum = _result.checksum;
            setSession({ ...session, "cart": _result })
            setModalVisible(false);
        } else {
            setEditError(_result.error);
        }
    }

    const changeGiftCard = (_f) => {

        
        setEditError("");
        apiCall("checkout/editGiftCard", { gift_card_number: currentGiftCard.number, amount: _f["amount"], pin : currentGiftCard.pin }, onChangeGiftCard)
    }

    const validateCard = (e) => {
        e.preventDefault();
        e.stopPropagation();
        addForm.setFieldsValue({ gift_card_number: e.target.value });
        if(e.target.value.length>4){
            setCanAdd(true);
        } else {
            setCanAdd(false);
        }
    }

    const formatCard = (_v, _max) => {


        return _v;

        if (!_v) {
            return "";
        }

        if(_v > _max){
            setCanAdd(true);
            return _v; 
        } else {
            setCanAdd(false);
        }

        return _v;

        _v = _v.replace(/[\D +]/g, "").substr(0, _max);


       


        // Get Card Length
        if(_max !==3){
            if (_v.length === 11 || _v.length === 8) {
                if (!canAdd) {
                    setCanAdd(true)
                }
            } else {
                if (canAdd) {
                    setCanAdd(false);
                }
            }
        }
        let gaps = [4, 8];
        let new_value = _v;
        for (let i = gaps.length - 1; i >= 0; i--) {
            if (_v.length > gaps[i]) {
                new_value = new_value.substr(0, gaps[i]) + ' ' + new_value.substr(gaps[i]);
            }
        }
        return new_value;
    }

    const validatePin = (e) => {

     
        e.preventDefault();
        e.stopPropagation();
        if(e.target.value){
            addForm.setFieldsValue({ pin: formatCard(e.target.value, 3) });
        }
        
    }



    

    const drawEditForm = () => {

     

        return (
            <>

                <h4>Edit Gift Card <strong>GC {formatCard(currentGiftCard.number, 11)}</strong></h4>
                <div aria-hidden><Divider /></div> <Form onFinish={(_f) => changeGiftCard(_f)} layout="vertical">
                    <p>Set the gift card amount you wish to use. Amount available is <strong>${currentBalance}</strong> </p>
                    <Form.Item rules={[{ pattern: new RegExp(/^\$?[0-9]+(\.[0-9][0-9])?$/g), message: "Amount required.", required: true }]} initialValue={currentGiftCard.amount} name="amount" label="Amount to use">
                        <Input prefix={<span style={{ "color": "#1f1f1f" }}>$</span>} />
                    </Form.Item>
                    <Form.Item name="number" initialValue={currentGiftCard.number} hidden noStyle >
                        <Input />
                    </Form.Item>
                    <Form.Item name="pin" initialValue={currentGiftCard.pin} hidden noStyle >
                        <Input />
                    </Form.Item>

                    <conditional.true value={(editError)}>
                        <p style={{ "color": "#ff4d4f", "textAlign": "center" }} className="error">
                            {editError}
                        </p>
                    </conditional.true>
                    <div className="shim"></div><div className="shim"></div>
                    <Space><Button onClick={() => removeGiftCard(currentGiftCard.number)} type="danger">Remove</Button><Button htmlType="submit">Update</Button></Space>
                </Form>
            </>
        )
    }

  

    return (
        <>
            <Modal
                onCancel={() => setModalVisible(false)}
                open={modalVisible}
                footer={null}
                destroyOnClose = {true}
                style={{ "maxWidth": "300px" }}
                centered={true}
            >
                {drawEditForm()}
            </Modal>
            <Divider orientation="left" ><big><strong>Redeem Gift Cards</strong></big></Divider>
            <div className="shim"></div><div className="shim"></div>
            <div>
                <Form form={addForm} onFinish={(f) => addGiftCard(f)}>
                    <Row>
                        <Col flex={"65%"}>
                            <small>&nbsp;Card number</small>
                        </Col>
                        <Col flex={"35%"}>
                            <small>&nbsp;Pin (if provided)</small>
                        </Col>
                    </Row>

                    <ReCAPTCHA
                        ref={recaptchaRef}
                        size="invisible"
                        sitekey="6LfPBUslAAAAAIrLp814HpBIixMj0rL5J3cAxEUw"
                        onChange={onChange}
                        onExpired={() => {}}
                    />

                    <Input.Group compact>
                        <Form.Item noStyle name="gift_card_number">
                            <Input size="large" aria-label="Gift card number" autoComplete="off" onChange={(e) => validateCard(e)} prefix={<div aria-hidden><span style={{ "paddingRight": "5px" }}><CreditCardOutlined /> </span><span style={{ "color": "#666" }}> GC</span></div>} placeholder="Number" style={{ "width": "65%" }}></Input>
                        </Form.Item>
                        <ReCAPTCHA
                        ref={recaptchaRef2}
                        size="invisible"
                        sitekey="6LfPBUslAAAAAIrLp814HpBIixMj0rL5J3cAxEUw"
                        onChange={onChange2}
                         onExpired={() => {}}
                    />
                        <Form.Item noStyle initialValue="" name="pin"  >
                            <Input size="large" onChange={(e) => validatePin(e)} aria-label="Gift card pin" placeholder="Pin" addonAfter={<><Button disabled={!canAdd} loading={loading} htmlType="submit" size="small" type="link" >Add</Button></>} style={{ "width": "35%" }}></Input>
                        </Form.Item>
                    </Input.Group>
                   
                </Form>
            </div>
            <conditional.true value={(addError)}>
                <p style={{ "color": "#ff4d4f", "textAlign": "center" }} className="error">
                    {addError}
                </p>
            </conditional.true>
            <conditional.true value={session.cart.gift_cards.length > 0}>
                <div>
                    <div className="shim" />
                <List
                    bordered
                    dataSource={session.cart.gift_cards}
                    header={<Row style={{ "width": "100%" }}>
                        <Col span={13}>
                            Card number
                    </Col>
                        <Col span={11}>
                            Amount used
                    </Col>
                    </Row>}
                    footer={<div style={{ "padding": "0px", "textAlign": "right" }}>Total applied from gift cards: <strong>${session.cart.summary.total_gift_card_amount_used}</strong></div>}
                    renderItem={item => (
                        <List.Item style={{ "padding": "10px 25px" }}>
                            <Row style={{ "width": "100%" }}>
                                <Col span={13}>
                                    <div><strong>GC {formatCard(item.number, 11)}</strong></div>
                                </Col>
                                <Col span={6}>
                                    <div><strong>${item.amount}</strong></div>
                                </Col>
                                <Col span={5}>
                                    <div style={{ "textAlign": "right" }}>
                                        <Space size="small">
                                            <Button onClick={() => editCard(item)} size="small"><small>Edit</small></Button>
                                        </Space></div>
                                </Col>
                            </Row>
                        </List.Item>
                    )}
                /> </div></conditional.true>
            <div className="shim"></div> <div className="shim"></div> <div className="shim"></div>
        </>
    )
}
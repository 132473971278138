import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "../../../css/slick.min.css";
import "../../../css/slick-theme.min.css";
import { useHistory, Link } from "react-router-dom";
import { RightOutlined, LeftOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { getItemAlt, pluralize } from "../../../util/Utils";
export default function TrevSlider(props) {



    const history = useHistory();
    const { items = [], row_count = 0, list_id = false } = props;


    const mx = (items.length - 8);
    const [current, setCurrent] = useState((window["remember" + list_id]) ? window["remember" + list_id] : 0);
    const [, setInfinite] = useState(false);


    useEffect(() => {
        setInfinite(true)
    }, [])
    const reduce = (props.sidebar) ? 1 : (props.features) ? 2 : 0;

    const SlickButtonFix = ({ currentSlide, slideCount, children, ...props }) => (<span {...props} style={{ "marginLeft": "-4px", "marginRight": "-4px" }}>{children}</span>);


    const rememberCurrent = (_current) => {

        setCurrent(_current);

        if (list_id && _current !== 0) {
            window["remember" + list_id] = _current;
        } else {
            delete window["remember" + list_id];
        }
    }


    let x = 0;
    let buffer = 5;
    const onDown = (e) => {
        x = e.pageX;
    }
    const onUp = (e, _item) => {

        if (Math.abs(e.pageX - x) > buffer) {
            // dragging
        } else {
            gotoProduct(_item);
        }
    }

    let link = true;
    const onTDown = () => {
        link = true;
    }
    const onTUp = (_item) => {

        if (link) {
            gotoProduct(_item);
        } else {
            // dragging
        }
    }
    const onTMove = () => {
        link = false;
    }

    const gotoProduct = (_item) => {
        history.push({ "pathname": "/item/" + _item.eisbn + "/lists/" + props.list_id });
    }

    if (items.length < 1) {
        return (<></>)
    }

    const imageFallback = (i, index) => {

        i.target.className = "imgplaceholder";
        delete i.target.onerror;
        i.target.src = "https://cdn1.bookmanager.com/i/nocover.png"
        i.target.width = "120px";
        i.target.height = "180px";

    }


    const isInRange = () => {

        if (mx >= current) {
            return true;
        } else {
            return false;
        }
    }

    return (
        <>
            <Slider
                // initialSlide={current}
                slidesToShow={8 - reduce}
                slidesToScroll={8 - reduce}

                initialSlide={current}



                // slideCount={2}
                className="trevSlider"
                infinite={(items.length > 7)}
                focusOnSelect={false}


                afterChange={(_c) => rememberCurrent(_c)}


                responsive={[
                    {
                        breakpoint: 1100,
                        settings: {
                            slidesToShow: 7 - reduce,
                            slidesToScroll: 7 - reduce,
                            infinite: false,

                            dots: true
                        }
                    },
                    {
                        breakpoint: 970,
                        settings: {
                            slidesToShow: 6 - reduce,
                            slidesToScroll: 6 - reduce,
                        }
                    },
                    {
                        breakpoint: 840,
                        settings: {
                            slidesToShow: 5 - reduce,
                            slidesToScroll: 5 - reduce
                        }
                    },
                    {
                        breakpoint: 710,
                        settings: {
                            slidesToShow: 4 - reduce,
                            slidesToScroll: 4 - reduce
                        }
                    },
                    {
                        breakpoint: 580,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3
                        }
                    },
                    {
                        breakpoint: 450,
                        settings: {
                            touchMove: true,
                            slidesToShow: 2,
                            slidesToScroll: 2
                        }
                    }
                ]}

                nextArrow={
                    <SlickButtonFix>
                        {((isInRange()) && <Button className="softShadow" shape="circle" type="primary"><RightOutlined /></Button>)}
                    </SlickButtonFix>}
                prevArrow={<SlickButtonFix>
                    {(current !== 0 && <Button className="softShadow" shape="circle" type="primary"><LeftOutlined /></Button>)}
                </SlickButtonFix>
                }


            >

                {items.slice(0, 24).map((item, index) => {
                    return (
                        <div key={index} >
                            <div onTouchMove={(e) => onTMove(e)} onTouchStart={(e) => onTDown(e)} onTouchEnd={(e) => onTUp(item)} onMouseDown={(e) => onDown(e)} onMouseUp={(e) => onUp(e, item)} className="imgbox" >
                                <img tabIndex={0} onKeyDown={(e) => {if(e.key === "Enter") {  gotoProduct(item);}}} alt={getItemAlt(item)} onError={(i) => imageFallback(i, index)} className="imgbox" style={{ "margin": "0 auto" }} src={'https://cdn1.bookmanager.com/i/slider_23.php?b=' + item.eisbn + "&cb=" + item.cover_image_cache} />
                            </div>
                        </div>)
                })}

                <div key={"end"} >
                    <Link to={"/lists/" + props.list_id}><div className="viewAll" style={{ "textAlign": "center" }}>View all <br /> {row_count} {pluralize(row_count, "product", "s")}</div></Link>
                </div>

            </Slider>
            <div style={{ "display": "block", "textAlign": "right" }}><span style={{ "textAlign": "right" }}><Button aria-label="View List" onClick={() => history.push("/lists/" + props.list_id)} style={{ "color": "#069" }} type="link"><span className="asa-anchor" style={{ "fontSize": "14px" }}>View all {row_count} {pluralize(row_count, "product", "s")}</span></Button></span></div>
        </>
    );
}
import { Space } from "antd";
import React, { useState, useEffect } from "react";

import moment from "moment";
import { getColor } from "../util/CC";
import { apiCall } from "../components/utilities/Api";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function EventsGrid(props) {

    const template = window.sitesettings.template_info;
    const ismobile = useMediaQuery({ query: '(max-width: 716px)' });
    const history = useHistory();
    const [content, setContent] = useState(props.content);
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(false);

    // Component agnostic
    const {
        name = "",
        container = true,
        data = {},
        date_changed = 0
    } = content;

    // Component specific
    const {
        width = 300,
        height = 300,
        padding_y = 0,
        padding_x = 0,
        horizontal_align = "center",
        vertical_align = "center",
        size = "cover",
        gap_y = 10,
        gap_x = 10,
        justify = "center",
        background_colour = 0,
        image_radius = 0,
        spacing = 10,
        categories = [],
        limit = 8,
        title_preset = 0,
        date_preset = 0,
        link_style = 0,
        text_align = "center",

        allow_mobile_overrides = false,
        mobile_height = 300,
        mobile_padding_y = 0,
        mobile_padding_x = 0
    } = data;

    const linkOut = (_id) => {
        history.push("/events/" + _id)
    }

    const fetchEvents = () => {
        setLoading(true);

        if (window.sitesettings.using_events_v2) {

            let obj = {
                limit: 24,
                start_date: moment().tz('America/Los_Angeles').format("YYYYMMDD")
            }

            if (categories.length > 0) {
                obj.categories = JSON.stringify([...categories].map(item => item.id));
            }

            apiCall("event/v2/list", obj, (_status, _result) => {
                if (_status) {
                    setEvents((prevRows) => [...prevRows, ..._result.rows]);

                }
                setLoading(false)
            })
        } else {

            apiCall("event/getList", { from: moment().tz('America/Los_Angeles').format("YYYYMMDD") }, (_status, _result) => {
                if (_status) {
                    setEvents(_result.rows);
                }
                setLoading(false);
            })
        }
    }
    useEffect(fetchEvents, []);



    const formatDate = (_event) => {
        let { date = "", from = "", to = "", recurring_as_single_row = false, end_date="" } = _event;
        if (window.sitesettings.using_events_v2) {
            if(recurring_as_single_row && end_date){
                return moment(date, "YYYYMMDD").format("ddd MMM Do, YYYY") + " to " + moment(end_date, "YYYYMMDD").format("ddd MMM Do, YYYY");
            }
            return moment(date, "YYYYMMDD").format("MMM Do");
        } else {
            let _to = moment(to * 1000).tz('America/Los_Angeles').format("MMM Do");
            let _from = moment(to * 1000).tz('America/Los_Angeles').format("MMM Do");
            if (_to === _from) {
                return _from;
            } else {
                return _from + "-" + _to;
            }
        }
    }


    const drawEvent = (_event, _index) => {

        let _img = (window.sitesettings.using_events_v2) ? `url('${_event.image_url}')` : `url('${_event.info.img_path}')`;
        let _title = (window.sitesettings.using_events_v2) ? _event.title : _event.info.name;
        let _coverStyle = {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            objectFit: "cover",
            height: (allow_mobile_overrides && ismobile) ? mobile_height + "px" : height + "px",
            width: (allow_mobile_overrides && ismobile) ? "100%" : width + "px",
            backgroundRepeat: "no-repeat",
            backgroundImage: _img,
            backgroundSize: size,
            backgroundPosition: vertical_align + " " + horizontal_align,
            borderRadius: image_radius + "px"
        }


        return (
            <div style={{ "maxWidth": width + "px", width: (allow_mobile_overrides && ismobile) ? "100%" : "auto" }}>
                <Space size={spacing} direction="vertical" style={{width: "100%"}}>
                    <div tabIndex={0} aria-label="Click to view event" onKeyDown={(e) => { if(e.key === "Enter"){linkOut(_event.id)}}} onClick={() => linkOut(_event.id)} className="bm-img-link" title={_title} style={_coverStyle} key={_index}></div>
                    <div className={"bm-txt-" + date_preset} style={{ "textAlign": text_align }}>{formatDate(_event)}</div>
                    <div tabIndex={0} aria-label="Click to view event" onKeyDown={(e) => { if(e.key === "Enter"){linkOut(_event.id)}}} className={"bm-txt-" + title_preset} style={{ "textAlign": text_align }}><a onClick={() => linkOut(_event.id)} className={"bm-lnk-" + link_style}>{_title}</a></div>
                </Space>
            </div>
        )
    }

    const drawRender = () => {
        const gridStyle = {
            background: getColor(background_colour, template),
            display: 'flex',
            flexWrap: "wrap",
            justifyContent: justify,
            gap: `${gap_y}px ${gap_x}px`,
            padding:  (allow_mobile_overrides && ismobile) ? `${mobile_padding_y}px ${mobile_padding_x}px` : `${padding_y}px ${padding_x}px`,
        };
        return (
            <div className={(container) ? "preview-container" : ""}>
                <div style={gridStyle}>
                    {events.slice(0, limit).map((item, index) => {
                        return drawEvent(item, index);
                    })}
                </div>
            </div>
        )
    }

    return (
        <>
            {drawRender()}
        </>
    )

}

// import { Divider, Space, Button, Row, Col, Spin } from "antd";
// import React, { useState, useEffect, useCallback, useRef } from "react";
// import moment from "moment";
// import EventUI from "./helpers/EventUI";
// import { getColor } from "../util/CC";
// import { apiCall } from "../components/utilities/Api";
// import EventViewMore from "../components/components/events/EventViewMore";
// import EventRSVP from "../components/components/events/EventRSVP";
// import { useHistory } from "react-router-dom";

// export default function EventList(props) {

//     const template = window.sitesettings.template_info;
//     const history = useHistory();
//     const [events, setEvents] = useState([]);
//     const [loading, setLoading] = useState(false);
//     const [page, setPage] = useState(0);
//     const observer = useRef();

//     const lastRowRef = useCallback(
//         (node) => {
//             if (observer.current) observer.current.disconnect();
//             observer.current = new IntersectionObserver((entries) => {
//                 if (entries[0].isIntersecting) {
//                     setPage((prevPage) => prevPage + 1);
//                 }
//             });
//             if (node) observer.current.observe(node);
//         },
//         []
//     );

//     const { content = {} } = props;

//     const {
//         location = "main",
//         component = "event_list",
//         container = true,
//         injected_data = {},
//         data = {}
//     } = content;

//     // Component specific
//     const {
//         align = "center",
//         past_events = false,
//         categories = [],
//         container_width = 1248,
//         constrain_container = false,
//         width = 200,
//         background_colour = 0,
//         padding_x = 20,
//         padding_y = 20,
//         title_preset = 0,
//         description_preset = 0,
//         date_preset = 0,
//         button_type = 0,
//         button_label = "Buy Books",
//         button_align = "left",
//         link_id = 0,

//     } = data;


//     const fetchEvents = () => {
//         setLoading(true);

//         if (window.sitesettings.using_events_v2) {

//             let _limit = 20;
//             let _page = page;
//             let _offset = _page * _limit;

//             let obj = {
//                 limit: _limit,
//                 offset: _offset
//             }

//             if (past_events) {
//                 obj.descending = true;
//             } else {
//                 obj.start_date = moment().format("YYYYMMDD")
//             }

//             if (categories.length > 0) {
//                 obj.categories = JSON.stringify([...categories].map(item => item.id));
//             }

//             apiCall("event/v2/list", obj, (_status, _result) => {
//                 if (_status) {
//                     setEvents((prevRows) => [...prevRows, ..._result.rows]);
//                 }
//                 setLoading(false)
//             })
//         } else {

//             apiCall("event/getList", { from: moment().format("YYYYMMDD") }, (_status, _result) => {
//                 if (_status) {
//                     setEvents(_result.rows);
//                 }
//                 setLoading(false);
//             })
//         }
//     }
//     useEffect(fetchEvents, [page, past_events]);


//     const linkOut = (_id) => {
//         history.push("/events/" + _id)
//     }

//     const drawButtons = (eventData) => {

//         // Buy Tickets
//         // Buy Books
//         // RSVP
//         const {
//             info = {},
//             books = [],
//             tickets = [],
//             ticket_label = "",
//             rsvp_label = "",
//             attendance = false
//         } = eventData;
//         const { book = [], ticket = [] } = info;

//         let _tickets = ticket;
//         let _tickets_label = "Tickets"
//         let _books = book;
//         let _books_label = button_label;
//         let _attendance = attendance;
//         let _rsvp_label = "";

//         // New Events
//         if (window.sitesettings.using_events_v2) {
//             _tickets = tickets;
//             _tickets_label = (ticket_label) ? ticket_label : "Tickets";
//             _books = books;
//             _rsvp_label = (rsvp_label) ? rsvp_label : "RSVP";
//         }

//         return (
//             <>
//                 <div className="shim" /><div className="shim" />
//                 <div style={{ "textAlign": button_align }}>
//                     <Space>
//                         {((_tickets.length > 0) && <EventViewMore event_id={eventData.id} type="ticket" data={_tickets} cc_event><Button className={"bm-btn-" + button_type}>{_tickets_label}</Button></EventViewMore>)}
//                         {((_books.length > 0) && <EventViewMore event_id={eventData.id} type="book" data={_books} cc_event><Button className={"bm-btn-" + button_type}>{_books_label}</Button></EventViewMore>)}
//                         {((_attendance === "request") && <EventRSVP event={eventData}><Button className={"bm-btn-" + button_type}>{_rsvp_label}</Button></EventRSVP>)}
//                     </Space>
//                 </div>
//             </>
//         )
//     }

//     const drawImages = (eventData) => {

//         const {
//             id = "",
//             info = {},
//             image_url = "",
//             title = "",
//             books = []
//         } = eventData;
//         const { name = "", img_path = "", book = [] } = info;

//         let _books = book;
//         let _image = img_path;
//         let _title = name;

//         if (window.sitesettings.using_events_v2) {
//             _books = books;
//             _image = image_url;
//             _title = title;
//         }

//         return (
//             <div style={{ width: '100%', textAlign: 'center' }}>
//                 <div onClick={() => linkOut(id)} style={{ width: '100%' }}>
//                     <img className="bm-img-link" onError={(e) => e.target.src = "https://bookmanager.com/i/nocover.png"} src={_image} alt={_title} style={{ width: '100%', height: 'auto' }} />
//                 </div>
//                 <div style={{ display: 'flex', width: '100%' }}>
//                     {(_books.map(item => {
//                         return (
//                             <div>
//                                 <EventViewMore event_id={eventData.id} type="book" data={_books} cc_event><img className="bm-img-link" onError={(e) => e.target.src = "https://bookmanager.com/i/nocover.png"} src={"https://bookmanager.com/i/m?b=" + item.eisbn} alt={item.title} style={{ width: '100%', height: 'auto' }} /></EventViewMore>
//                             </div>
//                         )
//                     }))}
//                 </div>
//             </div>
//         )
//     }


//     const drawEvent = (item) => {
//         const {
//             id = "",
//             from = 0,
//         } = item;

//         let _wrapperStyle = {}
//         let _bgStyle = {}

//         return (
//             <div key={item}>
//                 <div style={_bgStyle}>
//                     <div style={_wrapperStyle}>
//                         <Row gutter={[20, 40]} className="bm-wrap" >
//                             <Col flex={(width + "px")}>
//                                 {drawImages(item)}
//                             </Col>
//                             <Col flex="auto">
//                                 <EventUI
//                                     flourish={false}
//                                     link_id={link_id}
//                                     event_id={id}
//                                     title_preset={title_preset}
//                                     date_preset={date_preset}
//                                     description_preset={description_preset}
//                                     event={item} />
//                                 {drawButtons(item)}
//                             </Col>
//                         </Row>
//                     </div>
//                 </div>
//                 <Divider />
//             </div>
//         )
//     }

//     const drawRender = () => {

//         let _style = {
//             backgroundColor: getColor(background_colour, template),
//             padding: padding_y + "px " + padding_x + "px",
//             width: (constrain_container) ? container_width + "px" : "100%",
//             margin: (align === "center") ? "0 auto" : (align === "right") ? "0 0 0 auto" : "initial"
//         }
//         return (
//             <Spin spinning={loading}>
//                 <div className={(container) ? "preview-container" : ""}>
//                     <div style={_style}>
//                         {events.map((item, index) => {
//                             if (index === events.length - 1) {
//                                 return (<div key={index} ref={lastRowRef}>{drawEvent(item)}</div>)
//                             } else {
//                                 return (<div key={index}>{drawEvent(item)}</div>)
//                             }
//                         })}
//                     </div>
//                 </div>
//             </Spin>
//         )
//     }

//     return (
//         <>
//             {drawRender()}
//         </>
//     )

// }
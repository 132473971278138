import React, { useEffect, useState } from "react";
import { apiCall } from "../../utilities/Api";
import { Card, Row, Col, Button, Layout, Divider, Space, Alert } from 'antd';
import ShippingProvider from "./ShippingProvider";
import { CartSummary } from "../checkout/Cart";
import { useSession } from "../../../util/Session";
import { useHistory } from "react-router-dom";
import BMAlert from "../../../util/BMAlert";

export default function ShippingProviders(props) {

    const { saved = {}, setSaved = () => { } } = props;
    const [session, setSession] = useSession(); 
    const [results, setResults] = useState([]);
    const [selected, setSelected] = useState(0);
    const [loading, setLoading] = useState(false);
    const [errorMSG, setErrorMSG] = useState("")
    const history = useHistory(); 

    const hashCode = (_string) => {
        let hash = 0;
        for (let i = 0; i < _string.length; i++) {
            let char = _string.charCodeAt(i);
            hash = ((hash << 5) - hash) + char;
            hash = hash & hash;
        }
        return hash
    }

    const selectProvider = (_hash, _provider, _delivery_preference, _batch = "all") => {
        setSelected(_hash);
        setSaved({ ...saved, "provider": _provider, "delivery": _delivery_preference, "batch": _batch });
    }

    const selectDefault = (_result) => {
       let set = _result.filter(item => item.available && item.delivery_preference !=="local_delivery");

      
       if(set.length > 0){
            set.sort((a, b) => (parseFloat(a.cost) > parseFloat(b.cost)) ? 1 : -1)
            let _select = set[0];
            let _hash = hashCode(_select.provider + _select.delivery_preference)
            selectProvider(_hash, _select.provider, _select.delivery_preference, "all");
        } else {

            let find = _result.find(item => item.available && item.delivery_preference ==="local_delivery");
            if(find){
                let _hash = hashCode(find.provider + find.delivery_preference)
                selectProvider(_hash, find.provider, find.delivery_preference, "all");
            }
        }
    }

    const getShippingProviders = () => {

        if(saved.shipping.address_id === undefined){
          
            return; 
        }
        
        apiCall("checkout/getShippingProviders", { address_id: saved.shipping.address_id }, (_status, _result) => {
            if (_status) {
                setResults(_result)
                selectDefault(_result);

            } 
        });
    }

    useEffect(getShippingProviders, [])


    const saveAndContinue = () => {

        return; 
        
        setLoading(true);

        let obj = {
            delivery_preference: saved.delivery,
            address_id: saved.shipping.address_id,
            provider: saved.provider,
            multiship_method: saved.batch
        }

        apiCall("checkout/setShipping", obj, (_status, _result) => {
            if (_status) {
                setSession({...session, "cart" : _result})
                history.push("/checkout/contact")
            } else {
                setErrorMSG(_result.error);
            }
            setLoading(false);
        })

    }



    const drawMethods = () => {

        let autoselect = (results.length===1) ? true : false; 
        autoselect = false;

        return results.map(item => {
            return (
                <div key={hashCode(item.provider + item.delivery_preference)} >
                    <ShippingProvider autoselect={autoselect} saved={saved} setSaved={setSaved} address_id={saved.shipping.address_id} selectProvider={selectProvider} selected={selected} hash={hashCode(item.provider + item.delivery_preference)} data={item} />
                    <div className="shim" /><div className="shim" />
                </div>
            )
        })

    }

    const displaySummary = () => {

        return (
            <>
                <CartSummary showShipping={false} >
                    <br />
                    {((errorMSG) && <>
                        <BMAlert className="msg" description={<><span style={{ "textAlign": "center", "width": "100%", "display": "block" }}>
                            {errorMSG}
                        </span></>} type="error" /><br />

                    </>)}
                    <Button block disabled={(errorMSG || (!selected))} loading={loading} onClick={() => saveAndContinue()} type="primary">Save &amp; Continue</Button>
                </CartSummary>
            </>
        )
    }



    return (
        <>

            <Layout className="responsiveSider">
           
                <Layout.Content style={{ "width": "auto" }}>
                <div aria-hidden><Divider orientation="left">Select a Shipping Option</Divider></div>
                    {drawMethods()}
                </Layout.Content>

                <Layout.Sider width="none" className="responsiveSummary" theme="light">
                    
                    {/* <Affix className="hide" offsetTop={85}  > */}
                    <div className="hide">{displaySummary()}</div>
                    {/* </Affix> */}
                    <div className="show">{displaySummary()}</div>
                    <br /><br />
                </Layout.Sider>
            </Layout>




        </>
    )
}
/*
 LoginBox 
  - 
 Props
  -
*/

// Resources
import React, { useState } from "react"
import { useSession } from "../../../util/Session";
import { Button, Form, Input, Divider } from 'antd';
import { Link } from "react-router-dom";
import { apiCall } from "../../utilities/Api"
import { ExclamationCircleFilled } from '@ant-design/icons';
import { useHistory } from "react-router-dom";
import Cookies from 'js-cookie';
function AccountLoginForm(props) {



  const history = useHistory();
  const { show_create = true, show_forgot = true, size = "middle", redirect = false, callback = () => {} } = props;


  const [session, setSession] = useSession();
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const [loginForm] = Form.useForm();


  const checkTransfer = (_r) => {
    if (_r["STG"]) {
      Cookies.set("STG", _r["STG"], { expires: 7 });
      Cookies.set("want_v1", 1, { expires: 7 });
      window.location.reload();
    }
  }


  const onLogin = (_status, _result) => {

    setLoading(false);

    if (_status) {
      checkTransfer(_result);
      setSession(_result);
      loginForm.resetFields();
      callback(); 
      if (props.dismiss) {

        props.dismiss();
      }

      if (window.loginRedirect) {
        let m = window.loginRedirect;
        window.loginRedirect = false;
        history.push(m);
      }

      if (redirect) {
        history.push("/");
      }



    } else {

      loginForm.setFieldsValue({ password: '' });
      setError(true);
      // setMessage(_result.error);

      setMessage("Invalid Login");
    }

  }
  const login = (_form_values) => {

    setLoading(true);
    let _login_key = "san";
    if (_form_values.username.includes("@")) {
      _login_key = "email_address";
    }
    apiCall("account/login", { [_login_key]: _form_values.username, "password": _form_values.password }, onLogin);

  }


  return (<div>


    <Form
    
      autoComplete="off"
      name="basic"
      form={loginForm}
      layout="vertical"
      onFinish={(v) => login(v)}
      initialValues={{ remember: true }}
    >
      {/* {(loginmessage && <p>{window.sitesettings.login_required_message}</p>)} */}
      <Form.Item
        name="username"
        rules={[{ required: true, validator: null, message: 'Please input your email address.' }]}
      >
        <Input aria-label="Username" id="email" inputMode="email" size={size} placeholder="Email address" />
      </Form.Item>

      <Form.Item

        name="password"
        rules={[{ required: true, message: 'password required.' }]}
      >
        <Input.Password aria-label="Password" id="password" size={size} placeholder="Password" />
      </Form.Item>
      {(error && <p style={{ "paddingBottom": "15px", "color": "#ff4d4f", "textAlign": "center" }} aria-live="assertive" className="error"><ExclamationCircleFilled /> {message}</p>)}

      <Button size={size} loading={loading} style={{ "width": "100%" }} type="primary" htmlType="submit">
        Login
      </Button>

    </Form>

    {(show_forgot && <Button onClick={() => {
      history.push("/account/forgot");
      setTimeout(() => {
        (props.dismiss && props.dismiss());
      }, 100)

    }} className="inline-button pull-right">I forgot my password</Button>)}

    {(show_create && <>
      <div aria-hidden><Divider plain style={{"margin" : "10px 0px"}} >or</Divider></div>
      <Button onClick={() => {
        history.push("/account/signup")
        setTimeout(() => {
          (props.dismiss && props.dismiss());
        }, 100)
        
      }} style={{ "width": "100%" }} htmlType="submit">Create an account</Button>
    </>)}
  </div>
  );

}

export default AccountLoginForm;

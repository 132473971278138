
import React, { useEffect, useState } from "react";
import { Divider, Card, Row, Col, Button, Alert, Modal, Form, Select } from 'antd';
import { useSession } from "../../../util/Session";
import AccountAddressBook from "../account/AccountAddressBook";
import { Link } from "react-router-dom";
import PhoneNumberFormatter from "../../formatters/PhoneNumberFormatter";
import conditional from "../../utilities/conditional";
export default function ShippingForm(props) {

    const { setShipping = () => { }, setLoading = () => { }, hideCard = false, state = {}, setState = () => {}, callback = () => {} } = props;
    const [session] = useSession();


    let _initselectedshipping = "";
    if (session.user.alt_addresses.length > 0) {
        _initselectedshipping = session.user.alt_addresses[0].gift_registry_id;
    }


    const setAltShippingID = (_stuff) => {
        _initselectedshipping = _stuff;
    }


    const setAltShipping = () => {
        let _find = session.user.alt_addresses.find(item => item.gift_registry_id === _initselectedshipping);
        if (_find) {
            setShipping(_find);
            setState({...state, "address_id" : _find.address_id, "gift_registry_id" : _find.gift_registry_id})
        }
    }


    const autoSelect = () => {
        if (session.user.alt_addresses.length === 1) {
            if (session.user.alt_addresses[0].shipping_encouraged) {
                _initselectedshipping = session.user.alt_addresses[0].gift_registry_id;
                setTimeout(() => {
                    setAltShipping();
                }, 100)
            }
        }
    }

    useEffect(autoSelect, []);

    const drawShippingRecommendation = () => {

        if (session.user.alt_addresses.length > 0) {

            // Auto select if there is only one address
            if (session.user.alt_addresses.length === 1) {
                _initselectedshipping = session.user.alt_addresses[0].gift_registry_id;
                setAltShipping();
                return;
            }

            Modal.confirm({
                cancelText: "Cancel",

                onOk: () => {
                    setAltShipping();
                },

                title: "Gift registry shipping", content: <>
                    <p>Your cart contains items from multiple gift registrys. Select the gift registry address you would prefer items to be shipped.</p>
                    <Form layout="vertical">
                        <Form.Item label="Select shipping:">
                            <Select onChange={(e) => setAltShippingID(e)} defaultValue={_initselectedshipping}>
                                {session.user.alt_addresses.map(item => {
                                    return (
                                        <Select.Option value={item.address_id}>{item.gift_registry_name}<div><small style={{ "color": "#AAA" }}>{item.street_address}</small></div></Select.Option>
                                    )
                                })}

                            </Select>
                        </Form.Item>
                    </Form>
                </>
            })
        }

        return;

    }

    // useEffect(drawShippingRecommendation, []);

    const clearError = () => {
        props.setErrorMSG("");
        setLoading(false);
    }

    const resetShipping = () => {

        
        props.setErrorMSG("");
        props.setSaved({ ...props.saved, "shipping": {} });
        let _f = session?.user?.addresses.find(item => item.preferred);
        if(_f){
            setState({...state, "address_id" : _f.address_id, "gift_registry_id" : null})
        } else {
            setState({...state, "address_id" : "", "gift_registry_id" : null})
        }
    }

    const drawAddressSwap = () => {
        
        const _alt = session.user.alt_addresses[0] || {}

        if (session.user.alt_addresses.length > 0 && state.gift_registry_id === _alt.gift_registry_id) {
            // is a registry address 
            return (
                <>
                    <Alert message={<>This gift registry has a designated shipping address, recommended for deliveries. <br /><a onClick={() => resetShipping()} style={{ "color": "#1fa1e5" }}>Use a different address</a></>} type="success" />
                    <div className="shim" /><div className="shim" />
                    <Card  className="addressItem">
                        <div>
                            <strong>Gift Registry</strong><br />
                            <span>{_alt.gift_registry_name}</span><br />
                        </div>
                    </Card>
                </>
            )



        } else {
            // is non-registry or unsaved
            
            return (
                <>
                
                    <conditional.true value={(session.user.alt_addresses.filter(item => item.gift_registry_id).length > 0)}>
                        <Alert message={<>Your cart contains items from a gift registry. The creator of the gift registry would prefer those items be shipped to their address <a onClick={() => drawShippingRecommendation()} style={{ "color": "#1fa1e5" }}>Use recomended shipping</a></>} type={((session.user.alt_addresses.length > 1)) ? "error" : "warning"} />
                        <div className="shim" /><div className="shim" />
                    </conditional.true>

                    <AccountAddressBook callback={(e) => callback(e)} clearError={clearError} errorMSG={props.errorMSG} setErrorMSG={props.setErrorMSG} setSaved={props.setSaved} saved={props.saved} form={props.form} shipping />
                </>
            )

        }


    }



    const displayAddressOptions = () => {

        return (
            <>
                <Row gutter={20}>
                    <Col xs={24} lg={12}>
                        <div aria-hidden><Divider orientation="left"><big><strong>Billing Address</strong></big></Divider></div>
                        <Card className="addressItem">
                            <div style={{ "height": "200px", "padding": "0px 3px" }}>
                                <strong>{session.user.first_name} {session.user.last_name}</strong><br />
                                <span>{session.user.email_address}</span><br />
                                <span><PhoneNumberFormatter display country={session.user.country} value={session.user.phone_number} /></span><br />
                                <span>{session.user.street_address}</span><br />
                                {(session.user.street_address_2 && <><span>{session.user.street_address_2}</span><br /></>)}
                                <span>{session.user.city}, {session.user.province}</span><br />
                                <span>{session.user.country}</span><br />
                                <span>{session.user.postal_code}</span><br />
                            </div>
                            <div className="shim"></div><div className="shim"></div>
                            <Link to="/account/details"><Button size="small"><small>Edit Account</small></Button></Link>
                        </Card>
                    </Col>
                    <Col xs={24} lg={12}>
                        <div aria-hidden><Divider orientation="left"><big><strong>Shipping Address</strong></big></Divider></div>

                        {drawAddressSwap()}

                        {/* <pre>{JSON.stringify(props.saved, null, 2)}</pre> */}

                    </Col>
                </Row>
                
            </>
        )

    }

    const display = () => {

        if(hideCard){
            return displayAddressOptions();
        }
        return (
            <Card >
                {/* {showInfo()} */}
                {displayAddressOptions()}
            </Card>
        )
    }


    return display();

}

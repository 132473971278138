import React from "react";
import { getColor } from "../util/CC";
import wrap from "../util/wrap";
import { useHistory } from "react-router-dom";
import { getItemAlt } from "../util/Utils";
import { useMediaQuery } from "react-responsive";
export default function ProductGrid(props) {

    const template = window.sitesettings.template_info;
    const ismobile = useMediaQuery({ query: '(max-width: 716px)' })
    const history = useHistory();
    const { content = {} } = props;

    const {
        location = "main",
        component = "blank_space",
        container = true,
        injected_data = {},
        data = {}
    } = content;

    const {
        image_size = 120,
        padding_y = 10,
        padding_x = 10,
        gap_y = 10,
        gap_x = 10,
        list_id = "",
        actual_list_id = 0,
        limit = 18,
        show_title = false,
        title_preset = 1,
        show_author = false,
        author_preset = 1,
        background_colour = 0,
        link_id = 0,

        allow_mobile_overrides = false,
        mobile_gap_y = 10,
        mobile_gap_x = 10,
        mobile_padding_y = 0,
        mobile_padding_x = 0,
        mobile_image_size = 120,

    } = data;

    const {
        meta = {},
        items = {},
    } = injected_data;

    const {
        rows = [],
        row_count = 0
    } = items;


    const drawItem = (_item) => {


        return (
            <div key={_item.eisbn}>
                <img aria-label="Click to view details" tabIndex={0} onKeyDown={(e) => { if (e.key === "Enter") { history.push("/item/" + _item.eisbn + "/lists/" + list_id) } }} onClick={() => history.push("/item/" + _item.eisbn + "/lists/" + list_id)} alt={getItemAlt(_item)} className="bm-img-link" onError={(e) => e.target.src = "https://cdn1.bookmanager.com/i/nocover.png"} style={{ "width": "100%" }} src={"https://cdn1.bookmanager.com/i/n?b=" + _item.eisbn + "&cb=" + _item.cover_image_cache} />
                <wrap.is value={(show_title)}>
                    <div className="shim" />
                    <div aria-label="Click to view details" onKeyDown={(e) => { if (e.key === "Enter") { history.push("/item/" + _item.eisbn + "/lists/" + list_id) } }} onClick={() => history.push("/item/" + _item.eisbn + "/lists/" + list_id)} style={{ "display": "block" }} className={"bm-txt-" + title_preset}>{_item.title}</div>
                </wrap.is>
                <wrap.is value={(show_author)}>
                    <div className={"bm-txt-" + author_preset}>{_item.authors.slice(0, 1).map(item => item.name).join(", ")}</div>
                </wrap.is>
            </div>
        )
    }

    const drawPlaceholder = (_index) => {

        return (
            <div key={_index}>
                <img alt="Placeholder image" style={{ "width": "100%" }} src={"https://cdn1.bookmanager.com/i/sample_content/title_placeholder.jpg"} />
                <wrap.is value={(show_title)}>
                    <div className="shim" />
                    <div style={{ "display": "block" }} className={"bm-txt-" + title_preset}>Title</div>
                </wrap.is>
                <wrap.is value={(show_author)}>
                    <div className={"bm-txt-" + author_preset}>Author</div>
                </wrap.is>
            </div>
        )

    }

    const numberToArray = (count) => {
        const resultArray = [];
        for (let i = 1; i <= count; i++) {
            resultArray.push(i);
        }
        return resultArray;
    }

    const drawItems = () => {

        if (rows.length === 0) {
            let _arr = numberToArray(limit);
            return _arr.map(item => {
                return drawPlaceholder(item)
            })
        }

        return rows.slice(0, limit).map(item => {
            return drawItem(item);
        })

    }

    const drawRender = () => {


        let gridStyle = {
            display: 'grid',
            gridTemplateColumns: `repeat(auto-fill, minmax(${image_size}px, 1fr))`,
            gap: `${gap_y}px ${gap_x}px`,

        };

        let _wrapStyle = {
            padding: `${padding_y}px ${padding_x}px`,
            background: getColor(background_colour, template),
        }

        // mobile 
        if (allow_mobile_overrides && ismobile) {
            gridStyle.gap = `${mobile_gap_y}px ${mobile_gap_x}px`;
            gridStyle.gridTemplateColumns = `repeat(auto-fill, minmax(${mobile_image_size}px, 1fr))`;
            _wrapStyle.padding = `${mobile_padding_y}px ${mobile_padding_x}px`;
        }


        return (
            <div className={(container) ? "preview-container" : ""}>
                <div style={_wrapStyle}>
                    <div style={gridStyle}>
                        {drawItems()}
                    </div>
                    <div className="shim" />
                    <div className="float-flex">
                        <div>&nbsp;</div>
                        <div><a aria-label="Click to view all in list" tabIndex={0} onKeyDown={(e) => { if (e.key === "Enter") { history.push("/lists/" + list_id); } }} onClick={() => history.push("/lists/" + list_id)} className={"bm-txt-0 bm-lnk-" + link_id}>View all {row_count} products</a></div>
                    </div>
                </div>
            </div>
        )
    }


    return drawRender();
}
import React, { useState } from "react";
import { Button, Divider, Table, Space, Card, Badge } from 'antd';
import { Link, useHistory } from "react-router-dom";
import moment from 'moment';
import BuyAgainButton from "../checkout/BuyAgainButton";
import { processAvailable } from "../../../util/Utils";
import { apiDownload } from "../../utilities/Api";
import conditional from "../../utilities/conditional";
import { DownloadOutlined, GiftOutlined } from '@ant-design/icons';

function OrderItem(props) {

    const { show_batch = false, data } = props;
    const { order_number, order_date, summary, items, delivery_type, delivery_key = "", purchaser_name, order_location_id, shipping_batches = false } = data;
    const { total } = summary;
    const max = (props.max) ? items.length : 3;
    const [count, setCount] = useState((items.length > max) ? max : items.length);
    const history = useHistory(); 
    const columns = [
        {
            "key": 0,
            "dataIndex": "name",
            "width": "100px"
        },
        {
            "key": 0,
            "dataIndex": "title",
            "width": "250px"
        },
        {
            "key": 0,
            "dataIndex": "condition",
            "responsive": ["lg"]
        },
        {
            "key": 0,
            "dataIndex": "quantity",
            "responsive": ["lg"],
            "width": "150px"
        },

        {
            "key": 0,
            "dataIndex": "location",
            "responsive": ["lg"]
        },
        {
            "key": 0,
            "dataIndex": "buy",
            "responsive": ["lg"]
        },

    ];

    const downloadExcel = (_order_id) => {
        apiDownload({ order_id: _order_id }, "order/getExcel");
    }


    const imageFallback = (i) => {
        i.target.src = "https://cdn1.bookmanager.com/i/nocover.png"
        i.target.className = "light-cart";
    }

    const getMultistoreNameById = (_id) => {

        if (window.sitesettings.store_info.is_multistore && window.sitesettings.store_info.is_consolidated_multistore) {
            let found = window.sitesettings.store_info.multistore_info.find(item => item.id === _id);
            if (found) {
                return found.name
            } else {
                return "";
            }
        } else {
            return "";
        }
    }


    const displayItem = (_item, _key, _batch = shipping_batches) => {

        let { title_info, cart_info } = _item;
        let { quantity, gift_registry_id = "", backorder_quantity = 0, backorder_sub_message = "" } = cart_info;
        let { eisbn, cover_image_cache, title, binding, available } = title_info;
        let { text, row_price, code } = available;
        let { giftcard_price = false, gift_card_info = { to: "", from: "", message: "" } } = _item.cart_info.cart_notes;

        let shipment = [];
        if (_batch.length > 1) {

            shipping_batches.forEach((bat, index) => {
                if (bat.filter(item => item.eisbn === _item.title_info.eisbn && item.condition === _item.title_info.available.code).length > 0) {
                    shipment.push("Shipment #" + (index + 1));
                }
            });

        }

        return {
            "key": _key,
            "name": (
                <div>
                    <Link to={{ "pathname": "/item/" + eisbn }}>
                        <Badge.Ribbon text={<small><GiftOutlined /></small>} style={{ "opacity": (gift_registry_id) ? "1" : "0" }} color="volcano" placement="start" >
                            <img onError={(i) => imageFallback(i)} alt={title} className="shadow-cart" style={{ "maxHeight": "100px", "maxWidth": "80px" }} src={"https://cdn1.bookmanager.com/i/m?b=" + eisbn + "&cb=" + cover_image_cache} />
                        </Badge.Ribbon>
                    </Link>
                </div>),
            "title": (<>
                <strong>{title}</strong>


                {((cart_info.is_giftcard && (gift_card_info.to || gift_card_info.from)) && <>
                    <div className="shim" />
                    <div style={{ "padding": "5px 10px", "border": "1px dashed #eee" }}>
                        <small>
                            <Space>
                                <div>From: <span style={{ "fontWeight": "600" }}>{gift_card_info.from}</span></div>
                                <div>To: <span style={{ "fontWeight": "600" }}>{gift_card_info.to}</span></div>
                            </Space>
                            {(gift_card_info?.recipient_email && <div>Email: <span style={{ "fontWeight": "600" }}>{gift_card_info.recipient_email}</span></div>)}
                            <div>{gift_card_info.message}</div>
                        </small>
                    </div>
                </>)}

                {((shipment.length > 0) && <div style={{ "fontSize": "11px" }}><em>{shipment.join(", ")}</em></div>)}

                {(cart_info.gift_registry_info && <>
                    <div><small>{cart_info.gift_registry_info.occasion} gift registry : <em>{cart_info.gift_registry_info.name}</em></small></div>
                </>)}

                <span className="show-block">{binding}</span>
                <span className="show-block">{text}</span>
                <span className="show-block">{quantity} x ${row_price}</span>
                <div className="show-block" style={{ "lineHeight": "15px" }}>
                    {processAvailable([available], "mobile_cart")}


                    <conditional.true value={(backorder_quantity)}>
                        <div className="shim" /><div style={{ "color": "#BD5800", "display": "block" }}>{backorder_quantity} to be ordered.</div>
                    </conditional.true>
                    <conditional.true value={(backorder_quantity && backorder_sub_message)}>
                        <div className="shim" />
                        <small style={{ "color": "#BD5800", "lineHeight": "10px" }}><em>{backorder_sub_message}</em></small>
                    </conditional.true>

                    <conditional.true value={((!title_info.available.onhand) && (!title_info.is_forthcoming) && (!cart_info.is_giftcard))}>

                        <div className="shim" />
                        <small style={{ "color": "#BD5800" }}><em>{title_info.available.sub_message}</em></small>
                    </conditional.true>

                    <conditional.true value={((!title_info.available.onhand) && (title_info.is_forthcoming))}>
                        <div className="shim" />
                        <small><em style={{ "color": "#BD5800" }}>Releases: {moment(title_info.release_date * 1000).format('MMM DD, ')}</em></small>
                    </conditional.true>



                </div>
            </>),
            // "binding": binding,
            "condition": <>{text} <div className="shim"></div> {binding}</>,
            "quantity": (<>

                {(cart_info.is_giftcard) ? <>{cart_info.quantity} x ${cart_info.cart_notes.giftcard_price}</> : <>{cart_info.quantity} x ${cart_info.sold_price}</>}
                {((cart_info.discount > 0) && <div style={{ "color": "red" }}><em >{cart_info.discount}% off ${cart_info.original_price}</em></div>)}



            </>),
            "location":
                <div style={{ "lineHeight": "15px" }}>
                    {processAvailable([available], "mobile_cart")}

                    <conditional.true value={(backorder_quantity)}>
                        <div className="shim" /><div style={{ "color": "#BD5800", "display": "block" }}>{backorder_quantity} to be ordered.</div>
                    </conditional.true>
                    <conditional.true value={(backorder_quantity && backorder_sub_message)}>
                        <div className="shim" />
                        <small style={{ "color": "#BD5800", "lineHeight": "10px" }}><em>{backorder_sub_message}</em></small>
                    </conditional.true>

                    <conditional.true value={((!title_info.available.onhand) && (!title_info.is_forthcoming) && (!cart_info.is_giftcard))}>
                        <div className="shim" />
                        <small style={{ "color": "#BD5800" }}><em>{title_info.available.sub_message}</em></small>
                    </conditional.true>

                    <conditional.true value={((!title_info.available.onhand) && (title_info.is_forthcoming))}>
                        <div className="shim" />
                        <small><em style={{ "color": "#BD5800" }}>Releases: {moment(title_info.release_date * 1000).format('MMM DD, YYYY')}</em></small>
                    </conditional.true>


                </div>,
            "buy": (<BuyAgainButton eisbn={eisbn} condition={code} giftcard={(giftcard_price)} />)
        }

    }


    const headerDetails = (_direction = "vertical", _class = "show-block") => {

        return (<Space style={{ "fontSize": "12px" }} className={_class} direction={_direction} size="small">
            <span style={{ "lineHeight": "22px" }}><strong>Order #</strong>{order_number}</span>
            <span style={{ "lineHeight": "22px" }}><strong>Order Total</strong> ${total}</span>
            <span style={{ "lineHeight": "22px" }}><strong>Delivery</strong> {delivery_type}</span>
            {/* <span style={{ "lineHeight": "22px" }}><strong>Payment</strong> {payment_type}</span> */}
            <Button onClick={() => {history.push("/account/order/" + order_number)}} size="small" style={{ "fontSize": "12px" }}>View Details</Button>

            {/* HIDE DOWNLOAD EXCEL BUTTON IF IT'S A QUOTE */}
            {(((delivery_key)) && <>
                <Button onClick={() => downloadExcel(order_number)} size="small" icon={<DownloadOutlined aria-hidden />}><small> &nbsp;Download Excel</small></Button>
            </>)}

        </Space>);
    }

    const displayHeader = () => {
        return (
            <div>
                <h3>{moment(order_date * 1000).format('MMMM DD, YYYY')} {(purchaser_name) ? <small><small><em> ordered by {purchaser_name}</em> </small></small> : ""}</h3>
                <conditional.true value={(window.sitesettings.store_info.is_multistore && window.sitesettings.store_info.is_consolidated_multistore)}>
                    <div style={{ "marginTop": "-8px", "fontSize": "12px" }}><em>Ordered from {getMultistoreNameById(order_location_id)}</em></div>
                </conditional.true>
                {headerDetails()}
                {headerDetails("horizontal", "hide-box")}
            </div>

        )
    }

    return (
        <div>





            <Card bodyStyle={{ "padding": "0px" }}>
                <Table title={(props.hide_header) ? false : () => displayHeader()} showHeader={false} className="order-table" ghost={true} pagination={false} dataSource={items.slice(0, count).map((item, index) => displayItem(item, index))} columns={columns}></Table>
            </Card>




            {(count !== items.length && <div aria-hidden><Divider style={{ "borderColor": "#fff" }}>  <Button size="small" style={{ "fontSize": "12px" }} onClick={() => setCount(items.length)}>+{items.length - count} more</Button></Divider></div>)}
            <br />
        </div>
    );
}

export default OrderItem;
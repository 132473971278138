import { Form, Input } from "antd";
import React from "react";

export default function TextEmailField(props) {

    const { data = {}, contact = {} } = props;

    const {
        id = "",
        label = "Text",
        type = "text",
        description = "",
        placeholder = "Enter text...",
        max_length = 50,
        required = false,
        required_message = "This field is required"
    } = data;

    let defaultValue = (contact.hasOwnProperty([id])) ? contact[id] : "";

    return (
        <Form.Item initialValue={defaultValue}  extra={description} rules={[{ required: required, message: (required_message) ? required_message : "This field is required" }, { type: 'email', message: 'Please enter a valid email' }]} label={props.data.label} name={id} >
            <Input size="large" maxLength={max_length} placeholder={placeholder} />
        </Form.Item>
    )
}
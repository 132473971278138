import React, { useState, useEffect } from "react";
import { Divider, Button, Space, Result, Empty, Pagination, Tooltip, Select, Input } from 'antd';
import { apiCall } from "../../utilities/Api";
import NewSlider from "../wrappers/NewSlider";
import AddEditList from "../lists/AddEditList";
import ExportList from "../lists/ExportList";
import ExportPDF from "../lists/ExportPDF";
import ShareList from "../lists/ShareList";
import ImportToList from "../lists/ImportToList";
import AddListToCart from "../lists/AddListToCart";
import { UploadOutlined, EditOutlined, DeleteOutlined, ShareAltOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import conditional from "../../utilities/conditional";
import DeleteList from "../lists/DeleteList";
import { StarLargeIcon } from "../../../util/Icons";
import { useHistory } from "react-router-dom";




export default function AccountLists() {

    const [lists, setLists] = useState({});
    const page_size = 10;
    const [current, setCurrent] = useState(1);
    const [total, setTotal] = useState(0);
    const [offset, setOffset] = useState(0);
    const [sort, setSort] = useState("date_updated");
    const [search, setSearch] = useState("")
    const history = useHistory();


    const onGetLists = (_status, _result) => {
        if (_status) {
            setLists(_result);
            setTotal(_result.row_count);
        }
    }

    const getLists = () => {
        setCurrent(1);
        setOffset(0);

        apiCall("titlelist/getLists", { search_term: search }, onGetLists)
    }

    useEffect(getLists, [search])

    const displayStatus = () => {
        return display();
    }


    const sortResults = () => {

        if (lists.length < 1) {
            return lists;
        }

        // filter out wishlist, checked to push to top
        let m = [...lists.rows]

        if (sort === "name") {
            m.sort((a, b) => {
                if (a.name.toUpperCase() < b.name.toUpperCase()) { return -1; }
                if (a.name.toUpperCase() > b.name.toUpperCase()) { return 1; }
                return 0;
            });
        } else {
            m.sort((a, b) => a[sort] - b[sort]);
        }

        return m;
    }



    const drawMenu = (_item) => {

        return (<div style={{ "float": "right", "paddingRight": "10px" }}>
            <Space size={[0, 0]}>

                <conditional.true value={_item.can_delete}>
                    <AddEditList getLists={getLists} edit list={_item}>
                        <Tooltip placement="topLeft" title="Rename list" arrowPointAtCenter>
                            <Button type="text" icon={<EditOutlined aria-hidden />}></Button>
                        </Tooltip>
                    </AddEditList>
                </conditional.true>

                <ImportToList getLists={getLists} list_id={_item.list_id}>
                    <Tooltip placement="topLeft" title="Import to list" arrowPointAtCenter>
                        <Button type="text" icon={<UploadOutlined aria-hidden />}></Button>
                    </Tooltip>
                </ImportToList>

                <Tooltip placement="topLeft" title="Export to Excel file" arrowPointAtCenter>
                    <div className="hide">
                        <ExportList list_id={_item.list_id} />
                    </div>
                </Tooltip>

                <Tooltip placement="topLeft" title="Export to PDF file" arrowPointAtCenter>
                    <div className="hide">
                        <ExportPDF list_id={_item.list_id} />
                    </div>
                </Tooltip>

                <ShareList list_id={_item.list_id} >
                    <Tooltip placement="topLeft" title="Share list" arrowPointAtCenter>
                        <Button type="text" icon={<ShareAltOutlined aria-hidden />}></Button>
                    </Tooltip>
                </ShareList>

                <AddListToCart list_id={_item.list_id} >
                    <Tooltip placement="topLeft" title="Add list to cart" arrowPointAtCenter>
                        <Button type="text" icon={<ShoppingCartOutlined aria-hidden />}></Button>
                    </Tooltip>
                </AddListToCart>

                <conditional.true value={_item.can_delete}>
                    <DeleteList list_id={_item.list_id} setLists={setLists} setTotal={setTotal} >
                        <Tooltip placement="topLeft" title="Delete list" arrowPointAtCenter>
                            <Button type="text" icon={<DeleteOutlined aria-hidden />}></Button>
                        </Tooltip>
                    </DeleteList>
                </conditional.true>

            </Space>
        </div>
        )
    }

    const drawSliders = () => {
        if (lists.hasOwnProperty("rows")) {
            return sortResults().slice(offset, offset + page_size).map((item, index) => {

                if (!item.can_delete) {
                    item.name = (!window.sitesettings.store_info.is_american) ? "Favourites" : "Favorites";
                }

                return (
                    <div key={item.list_id + "-" + item.changed}>
                        <div style={{ "float": "left", "display": "block", "paddingLeft": "15px" }}><h3 style={{ "lineHeight": "30px" }}><strong>{(!item.can_delete) && <StarLargeIcon />} {item.name}</strong></h3></div>
                        {drawMenu(item)}
                        <br clear="all" />
                        <div className="shim"></div> <div className="shim"></div>
                        <NewSlider drawEmpty={drawEmpty} type="customer_list" data={item} key={index} />
                        <div aria-hidden><Divider /></div>
                    </div>
                )
            });
        }
    }


    const drawEmpty = (_item) => {
        return (<ImportToList getLists={getLists} list_id={_item.list_id}>

            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={
                <Button type="text">
                    No products.<br />
                    <strong>Click to import</strong>
                </Button>} />
            {/* <Button type="text" icon={<UploadOutlined />}></Button> */}
        </ImportToList>)
    }

    const displayEmpty = () => {

        if (lists.hasOwnProperty("rows")) {
            if (lists.rows.length === 0) {


                return (
                    <>
                        <Result
                            status="warning"
                            icon={<Empty aria-hidden description="" />}
                            title={"No results"}
                        />
                    </>
                )

            }
        } else {
            return (<></>)
        }
    }

    function displayNextPrev(current, type, originalElement) {
        if (type === 'prev') {
            return <div style={{ "color": "#069", "cursor": "pointer" }}>Previous</div>;
        }
        if (type === 'next') {
            return <div style={{ "color": "#069", "cursor": "pointer" }}>Next</div>;
        }
        return originalElement;
    }


    const onChange = (_page, _size) => {

        setCurrent(_page);
        setOffset((_page - 1) * _size);

        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }


    const drawFilters = () => {

        return (
            <>
                <div aria-hidden><Divider /></div>

                <div style={{ "float": "right" }}>
                    <Space>
                        <small>Sort:</small>
                        <Select className="asa-select" defaultValue={sort} onChange={(e) => setSort(e)} style={{ width: 130 }}>
                            <Select.Option value="date_posted">Date posted</Select.Option>
                            <Select.Option value="name">Alphabetical</Select.Option>
                            <Select.Option value="date_updated" >Last updated</Select.Option>
                        </Select>
                    </Space>
                </div>

                <br clear="all" className="shim show-block" />
                <div className="shim show-block" /> <div className="shim show-block" /> <div className="shim show-block" />
                <Input.Search allowClear onSearch={(e) => setSearch(e)} style={{ "maxWidth": "400px" }} enterButton="Search" placeholder="Enter ISBN or keyword within a list's name" />



                <div className="shim" />
            </>
        )


    }

    const drawPagination = (_divider = true) => {

        return (
            <conditional.true value={total > page_size}>

                <conditional.true value={_divider}>
                    <div aria-hidden><Divider /></div>
                </conditional.true>



                <Pagination
                    total={total}
                    pageSize={page_size}
                    style={{ "textAlign": "center", "width": "250px", "margin": "0 auto" }}
                    showSizeChanger={false}
                    current={current}
                    onChange={onChange}
                    simple
                    showTotal={total => `Total ${total} results`}
                    itemRender={displayNextPrev}
                />
            </conditional.true>
        )
    }
    const display = () => {

        return (
            <>
                <div className="container">
                    <h2 style={{ "display": "inline" }}>Lists</h2>
                    <div style={{ "float": "right" }}>

                        <Space>
                            {((window.sitesettings.gift_registry_enabled) && <Button onClick={() => history.push("/gift-registry")}>Gift Registry</Button>)}
                            <AddEditList getLists={getLists} >
                                <Button>Create new list</Button>
                            </AddEditList>
                        </Space>
                    </div>
                    <br clear="all" />



                    {drawFilters()}




                    <div aria-hidden><Divider /></div>

                    {displayEmpty()}
                    <div>{drawSliders()}</div>
                    {drawPagination(false)}
                </div>
            </>
        )
    }

    return displayStatus();
}
import React, { useRef, useState } from "react";
import { getColor } from "../util/CC";
import { Input, Result, Modal, Divider, Button, Spin } from 'antd';
import { CreditCardOutlined, DollarOutlined } from '@ant-design/icons';
import { apiCall } from "../components/utilities/Api";
import ReCAPTCHA from "react-google-recaptcha";


export default function BalanceChecker(props) {

    const recaptchaRef = useRef();
    const [loading, setLoading] = useState(false);
    const [code, setCode] = useState("")

    const template = window.sitesettings.template_info;
    const { content = {} } = props;

    const {
        location = "main",
        component = "blank_space",
        container = true,
        data = {},
    } = content;

    const {
        title = "Check your Gift Card balance",
        description = "Type in your Gift Card number (found on the back of the card, right below the barcode) and hit go.",
        width = 800,
        align = "0 auto",
        padding_x = 20,
        padding_y = 20,
        button_type = 0,
        preset = 0,
        description_preset = 0,
        background_colour = 0,
        button_label = "Check",
        text_align = "center",
        gap = 0,
    } = data;


    const checkBalance = (v) => {
        if (v) {
            setLoading(true)
            recaptchaRef.current.reset()
            recaptchaRef.current.execute()
        }
    }

    const onChange = (e) => {
        if (e) {
            apiCall("giftcard/balance", { "number": code.replace(" ", ""), "captcha": e }, (_status, _result) => {
                setCode("")
                setLoading(false)
                if (_status) {
                    Modal.success({
                        icon: <DollarOutlined />,
                        title: 'Your balance is ' + _result.balance,
                        content: `Gift cards never expire.`,

                    });
                } else {
                    Modal.warning({
                        title: "There was an error",
                        content: _result.error,
                    });
                }
            });
        }

    }

    const drawRender = () => {

        let _gridContainer = {
            maxWidth: width + "px",
            margin: align,
            padding: padding_y + "px " + padding_x + "px",
            textAlign: text_align,
        }

        let bg = {
            background: getColor(background_colour, template),
        }

        return (
            <div className={(container) ? "preview-container" : ""}>
                <div style={bg}>
                    <div style={_gridContainer}>
                        <Spin spinning={loading}>
                            <h3 className={"bm-txt-" + preset}>{title}</h3>
                            <div className="shim" style={{"height" : gap + "px"}} />
                            <p className={"bm-txt-" + description_preset}>{description}</p>
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                size="invisible"
                                sitekey="6LfPBUslAAAAAIrLp814HpBIixMj0rL5J3cAxEUw"
                                onChange={onChange}
                            />
                            <Input
                                placeholder="Gift card number.."
                                prefix={<div style={{"color" : "#666"}}><CreditCardOutlined /> &nbsp; GC &nbsp;</div>}
                                style={{ "maxWidth": "370px", "padding": "7px 12px" }}
                                enterButton="Go"
                                allowClear={true}
                                onChange={(e) => setCode(e.target.value)}
                                loading={loading}
                                size="large"

                            />
                            <div className="shim" /><div className="shim" /><div className="shim" />
                            <Button aria-label="Click to check ballance" onClick={() => checkBalance(code)} className={"bm-btn-" + button_type}>{button_label}</Button>
                        </Spin>
                    </div>
                </div>
            </div>
        )


    }

    return drawRender();
}
import React, { useState } from "react";
import { Menu } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { Link, useLocation } from "react-router-dom";
import { getOGColor, parseURL } from "../util/Utils.js";
import { getColor, getFontFamily } from "../util/CC.js";
import CSS from "./utilities/CSS.js";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min.js";


function SiteNav2(props) {

    const { mobile = false } = props;
    const history = useHistory();
    const {
        can_delete = false,
        container = true,
        visibility = "visible",
        expiry_action = false,
        start_date = null,
        end_date = null,
        date_created = 1684428651,
        date_changed = 1684429004,
        data = {},
    } = window.sitesettings.global_content.find(item => item.component === "nav_bar") || {};





    const template = window.sitesettings.template_info;

    const {
        text_colour = 0,
        text_preset = 0,
        background_colour = 0,
        selected_text_colour = 0,
        selected_background_colour = 0,
        transform = "none",
        spacing = 20,
        font_size = 14
    } = data;

    const search_bar = window.sitesettings.global_content.find(item => item.component === "search_bar") || { data: { background_colour: background_colour, text_colour: text_colour } };


    const _spacing = (mobile) ? 20 : spacing; 

    const loc = useLocation();

    const checkIsRel = (_path) => {
        if (_path.includes(".pdf")) {
            return false;
        }

        var pat = /^https?:\/\//i;
        if (pat.test(_path)) {
            if (_path.includes("bookmanager")) {
                return true;
            } else {
                return false;
            }
        }
        return true;
    }

    const displayLink = (_item, click = false) => {

        if (_item.url === "events") {
            _item.url = "/events";
        }
        if (_item.url) {
            let isrel = checkIsRel(_item.url);
            let prefix = (isrel) ? window.sitesettings.basename.slice(0, -1) : "";
            if (isrel && _item.link_type !== "_blank") {

                if(click){
                    history.push(_item.url);
                    return; 
                }

                return <div style={{ "padding": "0px " + _spacing + "px" }} onClick={() => history.push(_item.url)} >{_item.label}</div>
            }
            else {

                if(click){
                    window.open(prefix + _item.url, _item.link_type);
                    return; 
                }

                return <div style={{ "padding": "0px " + _spacing + "px" }} onClick={() => window.open(prefix + _item.url, _item.link_type)}>{_item.label}</div>
            }
        } else {

            if(click){
                history.push((parseURL(_item)) ? parseURL(_item) : _item.path);
                return; 
            }
            return (<div style={{ "padding": "0px " + _spacing + "px" }} onClick={() => history.push((parseURL(_item)) ? parseURL(_item) : _item.path)} key={_item.id}>{_item.label}</div>)
        }
    }


    const getSelectPath = (_item) => {
        if (_item.url) {
            let isrel = checkIsRel(_item.url);
            if (isrel) {
                return _item.url;
            }
            else {
                return _item.url;
            }
        } else {
            return (parseURL(_item)) ? parseURL(_item) : _item.path;
        }
    }



    // if a top-level nav is not unlinked, create a new item
    const displayLinked = (_item) => {
        if (_item.type === "unlinked") {
            return (<></>)
        } else {
            return (<Menu.Item style={{ "padding": "0px" }} className="tab" key={_item.menu_id}>{displayLink(_item)}</Menu.Item>);
        }
    }

    const displayMenu = () => {

        return props.site_navigation.map((item, index) => {
            if (item.submenu.length > 0) {
                return (
                    <Menu.SubMenu style={{ "padding": "0px " + _spacing + "px" }} className="tab"
                        key={item.menu_id} data-test={item.path}
                        title={(<div aria-hidden className="notab" >{item.label}&nbsp;<DownOutlined style={{ "fontSize": "10px", "marginRight": "-5px" }} /></div>)}>
                        {displayLinked(item)}
                        {displaySubMenu(item.submenu)}
                    </Menu.SubMenu>
                )
            } else {
                return (
                    <Menu.Item style={{ "padding": "0px" }} className="tab" key={item.menu_id}>{displayLink(item)}</Menu.Item>
                );
            }
        });
    }

    const displaySubMenu = (_sub_menu) => {
        return _sub_menu.map((item, index) => {
            return (
                <Menu.Item onKeyDown={(e) => { if(e.key === "Enter"){ displayLink(item, true)}}} style={{ "padding": "0px" }} className="tab" key={item.menu_id}>{displayLink(item)}</Menu.Item>
            );
        });
    }


    let _class = ``;

    _class += `.ant-menu-horizontal > .ant-menu-submenu > .ant-menu-submenu-title{
        color: ${getColor(text_colour, template, getOGColor("main_text_colour", "#000"))};
    }`


    _class += `.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title{
        color: ${getColor(text_colour, template, getOGColor("main_text_colour", "#000"))};
    }`

    _class += `.ant-menu-horizontal > .ant-menu-submenu-selected > .ant-menu-submenu-title{
        color: ${getColor(selected_text_colour, template, getOGColor("open_menu_text_colour", "#fff"))};
        
    }`

    _class += `.ant-menu-inline > .ant-menu-submenu-selected > .ant-menu-submenu-title{
        color: ${getColor(selected_text_colour, template, getOGColor("open_menu_text_colour", "#fff"))};
        
    }`


    _class += `
    .ant-menu-dark .ant-menu-item > span > a{
        color: ${getColor(text_colour, template, getOGColor("main_text_colour", "#000"))};
        transition: color .3s, border-color .3s, background .3s
    }`;

    _class += `
    .ant-menu-dark .ant-menu-item > span > a:hover{
        color: ${getColor(selected_text_colour, template, getOGColor("open_menu_text_colour", "#fff"))};
        transition: color .3s, border-color .3s, background .3s
    }`;

    _class += `
    .ant-menu-dark .ant-menu-item-selected > span > a{
        color: ${getColor(selected_text_colour, template, getOGColor("open_menu_text_colour", "#fff"))};
        transition: color .3s, border-color .3s, background .3s
    }`;

    _class += `
    .bm_nav .ant-menu-dark.ant-menu-horizontal > .ant-menu-item, .ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu > .ant-menu-submenu-title{
        padding: 0px ${_spacing}px;
    }`;

    _class += `
    .ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu{
        padding : 0px !important;
     }`;


    _class += `
    .bm_nav, .bm_nav .ant-menu.ant-menu-dark, .bm_nav .ant-menu-item, .bm_nav .ant-menu-sub{
        background: transparent;
        color: ${getColor(text_colour, template, getOGColor("main_text_colour", "#000"))} !important;
        font-family: '${getFontFamily(text_preset, template)}';
        text-transform : ${transform};
        transition: color .3s, border-color .3s, background .3s;
        font-size : ${font_size}px;
    }`;

    _class += `
    .bm_nav, .bm_nav .ant-menu.ant-menu-dark, bm_nav, .bm_nav .ant-menu.ant-menu{
        background: ${getColor(background_colour, template, getOGColor("main_colour", "#fff"))};
    }`;

    //hover, selected... 
    _class += `
    .bm_nav .ant-menu.ant-menu-dark .ant-menu-item-selected, .bm_nav .ant-menu.ant-menu-dark .ant-menu-submenu-active, .bm_nav .ant-menu.ant-menu-dark .ant-menu-submenu-selected, .bm_nav .ant-menu.ant-menu-dark .ant-menu-submenu-active .ant-menu-submenu-title, .bm_nav .ant-menu.ant-menu-dark .ant-menu-submenu-title:hover, .bm_nav .ant-menu.ant-menu-dark .ant-menu-item-active, .bm_nav .ant-menu-submenu-active, .bm_nav .ant-menu-submenu-selected {
        background: ${getColor(selected_background_colour, template, getOGColor("open_menu_background_colour", "#000"))} !important;
        color: ${getColor(selected_text_colour, template, getOGColor("open_menu_text_colour", "#fff"))} !important;
        transition: color 0s, border-color .0s, background .0s
    }`;

    _class += `
    .bm_nav .ant-menu.ant-menu-dark .ant-menu-submenu-title,  .bm_nav .ant-menu.ant-menu-dark .ant-menu-submenu-title:hover{
        background:none !important;
    }
    `

    // submenu 
    _class += `
    .bm_nav .ant-menu-vertical {
        background: ${getColor(background_colour, template, getOGColor("main_colour", "#fff"))} !important;
        border-radius: 0px !important;
        transition: color .3s, border-color .3s, background .3s
    }`;

    _class += `
    .bm_nav.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item {
        margin: 0px;
        transition: color .3s, border-color .3s, background .3s
    }`;

    _class += `
    .bm_nav.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected, .ant-menu-dark .ant-menu-submenu-title:hover,  .bm_nav.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item:hover, .bm_nav .ant-menu-item:focus-within{
        background: ${getColor(selected_background_colour, template, getOGColor("open_menu_background_colour", "#000"))} !important;
        color: ${getColor(selected_text_colour, template, getOGColor("open_menu_text_colour", "#fff"))} !important;
        transition: color .3s, border-color .3s, background .3s
    }`;

    _class += `
    .bm_nav.ant-menu-dark.ant-menu-inline .ant-menu-submenu:hover, .bm_nav.ant-menu-dark.ant-menu-inline .ant-menu-submenu:hover > a, .bm_nav.ant-menu-dark.ant-menu-inline .ant-menu-submenu:focus-within, .bm_nav.ant-menu-dark.ant-menu-inline .ant-menu-submenu:focus-within > a{
        background: ${getColor(selected_background_colour, template, getOGColor("open_menu_background_colour", "#000"))} !important;
        color: ${getColor(selected_text_colour, template, getOGColor("open_menu_text_colour", "#fff"))} !important;
        transition: color .3s, border-color .3s, background .3s
    }`;

    _class += `
    .bm_nav.ant-menu-inline > .ant-menu-submenu:hover > .ant-menu-submenu-title, .bm_nav.ant-menu-inline > .ant-menu-submenu:focus-within > .ant-menu-submenu-title{
    color: ${getColor(selected_text_colour, template, getOGColor("open_menu_text_colour", "#fff"))} !important;
    
    }`;

    _class += `
    .bm_nav .ant-menu-item:focus-within > span > a {
        color: ${getColor(selected_text_colour, template, getOGColor("open_menu_text_colour", "#fff"))} !important;
    }`;

    _class += `
    .ant-drawer-header{
        background: ${getColor(search_bar.data.background_colour, template, getOGColor("search_bar_colour", "#fff"))} !important;
        border-bottom: 1px solid ${getColor(search_bar.data.background_colour, template, getOGColor("search_bar_colour", "#fff"))} !important;
    }
    `

    _class += `
    .ant-drawer-header .ant-drawer-title{
        color : ${getColor(search_bar.data.text_colour, template, getOGColor("search_bar_text_colour", "#fff"))} !important;
    }
    `


    const findMenuId = (_path) => {
        let ret = "";
        window.sitesettings.site_navigation.forEach(element => {
            if (element.path === _path) {
                ret = element.menu_id
            }
            element.submenu.forEach(child => {
                if (child.path === _path) {
                    ret = child.menu_id
                }
            });
        });
        return ret;
    }


    return (
        <>
            <CSS prepend html={_class} />
            <nav className="bm_nav bm-render">
                <div className={(!mobile) ? "menubg hide-block" : ""} style={{ "maxWidth": "100%", "height": "46px" }}>
                    <Menu
                        className="bm_nav"
                        rootClassName="bm_nav"
                        forceSubMenuRender={true}
                        overflowedIndicator={<>More Menus</>}
                        selectedKeys={[findMenuId(loc.pathname)]}
                        triggerSubMenuAction={"hover"}
                        style={{ "maxWidth": (container) ? "1248px" : "100%", "margin": "0 auto", "minHeight": (mobile) ? "calc(100vh - 55px)" : "inherit" }}
                        mode={(mobile) ? "inline" : "horizontal"}
                        theme="dark"
                    >

                        {displayMenu()}
                    </Menu>
                </div>
            </nav>
        </>
    );
}

export default SiteNav2;


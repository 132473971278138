import React from "react";
import Html from "../components/utilities/Html";
import { getColor } from "../util/CC";
import { Button } from "antd";
import wrap from "../util/wrap";
import ProductCarousel from "./helpers/ProductCarousel";
import { useHistory } from "react-router-dom";
import Scroller from "./helpers/Scroller";
import DragButton from "./helpers/DragButton";
import { getItemAlt } from "../util/Utils";
export default function ProductSlider(props) {

    const template = window.sitesettings.template_info;
    const history = useHistory();
    const { content = {} } = props;

    const {
        location = "main",
        component = "blank_space",
        injected_data = {},
        container = true,
        data = {}
    } = content;

    const {
        display_mode = "scroller",
        image_size = 120,
        padding_y = 10,
        padding_x = 10,
        gap = 10,
        list_id = "",
        actual_list_id = 0,
        limit = 16,
        show_title = false,
        title_preset = 0,
        show_author = false,
        author_preset = 0,
        background_colour = 0,
        button_type = 0,
        link_id = 0
    } = data;

    const scroller = (display_mode === "scroller") ? true : false;

    const {
        meta = {},
        items = {},
    } = injected_data;

    const {
        rows = [],
        row_count = 0
    } = items;

    const drawItem = (_item) => {

        let _coverStyle = {
            
            overflow: "hidden",
        }

        let _imgStyle = {}

        if (scroller) {
            _imgStyle.height = (image_size * 1.5) + "px";
            _imgStyle.width = "auto";

        } else {
            _coverStyle.maxWidth = image_size + "px";
            _imgStyle.width = "100%";
        }


        return (
           
            <DragButton title={_item.title}>
                <div style={_coverStyle} key={_item.eisbn}>
                    <img aria-label="Click to view details" tabIndex={0} onKeyDown={(e) => { if (e.key === "Enter") { history.push("/item/" + _item.eisbn + "/lists/" + list_id) } }} onClick={() => history.push("/item/" + _item.eisbn + "/lists/" + list_id)} alt={getItemAlt(_item)} draggable={false} className="bm-img-link" onError={(e) => e.target.src = "https://bookmanager.com/i/nocover.png"} style={_imgStyle} src={"https://cdn1.bookmanager.com/i/n?b=" + _item.eisbn + "&cb=" + _item.cover_image_cache} />
                    <div style={{ whiteSpace: "initial" }}>
                        <wrap.is value={(show_title)}>
                            <div className="shim" />
                            <div aria-label="Click to view details" onKeyDown={(e) => { if (e.key === "Enter") { history.push("/item/" + _item.eisbn + "/lists/" + list_id) } }} onClick={() => history.push("/item/" + _item.eisbn + "/lists/" + list_id)} style={{ "display": "block" }} className={"bm-txt-" + title_preset}>{_item.title}</div>
                        </wrap.is>
                        <wrap.is value={(show_author)}>
                            <div className={"bm-txt-" + author_preset}>{_item.authors.slice(0, 1).map(item => item.name).join(", ")}</div>
                        </wrap.is>
                    </div>
                </div>
            </DragButton>
       
        )
    }

    const drawPlaceholder = (_index) => {

        let _coverStyle = {
            cursor: "pointer",
            overflow: "hidden",
        }

        let _imgStyle = {}

        if (scroller) {
            _imgStyle.height = (image_size * 1.5) + "px";
            _imgStyle.width = "auto";

        } else {
            _coverStyle.maxWidth = image_size + "px";
            _imgStyle.width = "100%";
        }

        return (
            <div style={_coverStyle} key={_index}>
                <img alt="Placeholder image" draggable={false} style={_imgStyle} src={"https://bookmanager.com/i/sample_content/title_placeholder.jpg"} />
                <wrap.is value={(show_title)}>
                    <div className="shim" />
                    <div style={{ "display": "block" }} className={"bm-txt-" + title_preset}>Title</div>
                </wrap.is>
                <wrap.is value={(show_author)}>
                    <div className={"bm-txt-" + author_preset}>Author</div>
                </wrap.is>
            </div>
        )

    }

    const drawRender = () => {

        const sliderStyle = {
            background: getColor(background_colour, template),
            padding: `${padding_y}px ${padding_x}px`,
        };

        return (
            <div className={(container) ? "preview-container" : ""}>
                <div style={sliderStyle}>
                    {(!scroller &&
                        <ProductCarousel button_type={button_type} image_size={image_size} gap={gap} drawPlaceholder={drawPlaceholder} drawItem={drawItem} limit={limit} images={rows.slice(0, limit)} />
                    )}

                    {(scroller &&
                        <Scroller button_type={button_type} image_size={image_size} gap={gap} drawPlaceholder={drawPlaceholder}  >
                            {rows.slice(0, limit).map(item => {
                                return drawItem(item)
                            })}
                            {((items.length === 0) &&
                                Array.from({ length: limit }, (_, index) => index + 1).map(item => {
                                    return drawPlaceholder(item)
                                })
                            )}
                        </Scroller>
                    )}

                    <div className="shim" />
                    <div className="float-flex">
                        <div>&nbsp;</div>
                        <div><a aria-label="Click to view all in list" tabIndex={0} onKeyDown={(e) => { if(e.key === "Enter"){ history.push("/lists/" + list_id); }}} onClick={() => history.push("/lists/" + list_id)} className={"bm-txt-0 bm-lnk-" + link_id}>View all {row_count} products</a></div>
                    </div>
                </div>
            </div>

        )


    }



    return drawRender();
}
import React, { useState } from "react";
import EmbedRenderer from "./helpers/EmbedRenderer";

export default function CustomEmbed(props) {

    const { edit = false, close = () => { }, saveItem = () => { }, deleteItem = () => { }, id = 0 } = props;
    const [content, setContent] = useState(props.content);

  
    // Component agnostic
    const {
        name = "",
        container = true,
        data = {},
        date_changed = 0
    } = content;

    // Component specific
    const {
        embed_code = "",
    } = data;

    

    const drawRender = () => {
        return(
            <div id={"bm-" + id} className={(container) ? "preview-container" : ""}>
                <EmbedRenderer embed_code={embed_code} />
            </div>
        )
    }

    return drawRender();

}
import React, { useEffect, useState } from "react";
import { Card, Row, Col, Button, Layout, Divider, Space, Modal, Table } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import MultipleShipments from "./MultipleShipments";
import Html from "../../utilities/Html";
import conditional from "../../utilities/conditional";
import { useHistory } from "react-router-dom";
import { useSession } from "../../../util/Session";
import { pluralize } from "../../../util/Utils";

export default function ShippingProvider(props) {


    const { autoselect = false, saved = {}, setSaved = () => { }, address_id = "", selected = 0,  selectProvider = () => { }, data, hash = "" } = props;

    const { conflicting_eisbns = [] } = data;
    const history = useHistory();
    const local_message = window.sitesettings.checkout_info.local_delivery_message;
    const shipping_message = window.sitesettings.checkout_info.shipping_message;

    const [session, setSession] = useSession();

    const {
        available = true,
        label = "",
        cost = "",
        delivery_preference = "",
        provider = "",
        delivery_min = "",
        delivery_max = ""
    } = data;

    const active = (selected === hash);
    const [batch, setBatch] = useState("all");


    const updateBatch = (_batch) => {


        setBatch(_batch);
        selectProvider(hash, provider, delivery_preference, _batch);
    }


    const autoSelectProvider = () => {
        if (autoselect) {
            selectProvider(hash, provider, delivery_preference, batch);
        }

    }

    useEffect(autoSelectProvider, [])


    const drawLocalDeliveryMessage = () => {

        let local_delivery_fee = (window.sitesettings.checkout_info.local_delivery_fee / 100).toFixed(2);
        let local_delivery_minimum = (window.sitesettings.checkout_info.local_delivery_minimum / 100).toFixed(2);
        let local_delivery_free_minimum = (window.sitesettings.checkout_info.local_delivery_free_minimum / 100).toFixed(2);
        let local_delivery_availability = window.sitesettings.checkout_info.local_delivery_availability;
        let local_delivery_free_enabled = window.sitesettings.checkout_info.local_delivery_free_enabled;
        return (
            <>
                <conditional.true value={(local_delivery_minimum !== "0.00")}>
                    <span>Orders that are ${local_delivery_minimum} or more qualify for local delivery. </span>
                </conditional.true>

                <conditional.true value={(local_delivery_fee !== "0.00")}>
                    <span>Local delivery is ${local_delivery_fee} and available to {local_delivery_availability}. </span>
                </conditional.true>

                <conditional.true value={(local_delivery_fee === "0.00")}>
                    <span>Local delivery is available to {local_delivery_availability}. </span>
                </conditional.true>

                <conditional.true value={local_delivery_free_enabled && local_delivery_free_minimum !== "0.00"}>
                    <span>Spend ${local_delivery_free_minimum} or more to get <strong>free</strong> local delivery. </span>
                </conditional.true>

            </>
        )
    }


    const showInelegable = (_cart, _conflict) => {


        let items = _cart.filter(item => _conflict.includes(item.title_info.eisbn));

        let cols = [
            {
                title: <></>, width: 50, dataIndex: "eisbn", key: "eisbn", render: (e, f) => {
                    return (<img onError={i => i.target.style.display = 'none'} className="shadow-slider" style={{ "maxWidth": "30px" }} alt={f.title_info.title} src={"https://cdn1.bookmanager.com/i/slider_23.php?b=" + f.title_info.eisbn + "&cb=" + f.title_info.cover_image_cache} />)
                }
            },

            {
                title: <small>Title</small>, dataIndex: "eisbn", key: "eisbn", render: (e, f) => {
                    return (f.title_info.title)
                }
            }
        ]


        Modal.info({

            icon: <></>,
            title: "Ineligible " + pluralize(items.length, "title", "s"),
            content: <>
                <div aria-hidden><Divider dashed style={{ "margin": "15px 0px" }} /></div>
                <Table pagination={false} size="small" bordered scroll={{ y: 300 }} columns={cols} dataSource={items} /></>
        })

    }


    const drawMessage = () => {


        if (delivery_preference === "local_delivery") {
            return (<>Local delivery is available.</>)
        }

        let _eta = (delivery_min === delivery_max) ? delivery_max : delivery_min + "-" + delivery_max + " business day(s) ETA"


        if (delivery_min && delivery_max && (delivery_min !== "0" && delivery_max !== "0")) {
            return (<><strong>{_eta}</strong> for in-stock items.</>)
        }

        return (<>No delivery <strong>ETA</strong> available at this time.</>)

    }

    if (!available && delivery_preference !== "local_delivery") {
        return (

            <Card style={{ "border": "1px dashed #ddd", "cursor": "not-allowed", "transition": "0.5s", "backgroundColor": "#fff", "opacity": "1" }}>
                <Row style={{ "color": (active) ? "#000" : "inherit" }} align="middle" gutter={30}>
                    <Col xs={12} sm={5}>
                        <span style={{ "fontWeight": (active) ? "bold" : "normal" }}>{label}</span>
                    </Col>
                    <Col className="hide-block" xs={24} sm={16}>
                        <div style={{ "display": "block", "color": "red", "opacity": "1" }}>{(conflicting_eisbns.length > 1) ? conflicting_eisbns.length + " titles" : "A title"} in your cart cannot be shipped with this provider.</div>
                        <small style={{ "opacity": "0.7" }}></small>
                        <div><div className="shim" />
                            <Button onClick={() => showInelegable(session.cart.items, conflicting_eisbns)} size="small" type="link" ><small><strong style={{ "color": "#069" }}>View ineligible {pluralize(conflicting_eisbns.length, "title", "s")}</strong></small></Button>
                        </div>
                    </Col>
                    <Col style={{ "borderLeft": "1px solid #eee" }} xs={12} sm={3}>
                        <div style={{ "textAlign": "center" }}>
                            <Button  aria-label={label + " cost is $" + cost}  aria-pressed={active} disabled={true}><strong><em>{cost}</em></strong></Button>
                        </div>
                    </Col>
                </Row>
            </Card>
        )
    }


    if (!available && delivery_preference === "local_delivery") {

        return (
            <Card style={{ "border": "1px dashed #ddd", "cursor": "not-allowed", "transition": "0.5s", "backgroundColor": "#fff", "opacity": "1" }}>
                <Row style={{ "color": (active) ? "#000" : "inherit" }} align="middle" gutter={30}>
                    <Col xs={12} sm={5}>
                        <span style={{ "fontWeight": (active) ? "bold" : "normal" }}>{label}</span>
                    </Col>
                    <Col className="hide-block" xs={24} sm={16}>
                        <div style={{ "display": "block", "color": "#e70d3d", "opacity": "1" }}>Your order / address does not qualify for local delivery.</div>
                        <small style={{ "opacity": "1" }}>{drawLocalDeliveryMessage()}</small>
                        <div><div className="shim" /><Button onClick={() => history.goBack()} size="small" type="link" ><small><strong style={{ "color": "#069" }}>Change shipping address</strong></small></Button></div>

                    </Col>
                    <Col style={{ "borderLeft": "1px solid #eee" }} xs={12} sm={3}>

                        <div style={{ "textAlign": "center" }}>
                            <Button  aria-label={label + " cost is $" + cost}  aria-pressed={active} disabled={(!active)}><strong><em>{cost}</em></strong></Button>
                        </div>
                    </Col>

                    <Col className="show-block" xs={24} sm={16}>
                        <div aria-hidden><Divider dashed style={{ "margin": "15px 0px" }} /></div>
                        <div style={{ "display": "block", "color": "#e70d3d" }}>Your order / address does not qualify for local delivery.</div>
                        <small>{drawLocalDeliveryMessage()}</small>
                    </Col>
                </Row>

            </Card>
        )

    }


    const drawEmailGiftCardsDisclaimer = () => {
        return (
            <div>
                <div><strong>{window.sitesettings.store_info.giftcard_emailed_label}:</strong></div>
                <div aria-hidden><Divider dashed style={{ "margin": "15px 0px" }} /></div>
                <Html className="" html={window.sitesettings.store_info.giftcard_emailed_message} />
            </div>
        )
    }

    return (
        <>
            <Card className={(active) ? "provider-hover-selected" : "provider-hover"} onClick={() => {

                if (active) {
                    return;
                }
                selectProvider(hash, provider, delivery_preference, batch)

            }} style={{ "border": (active) ? "1px solid #6cba66" : "none", "cursor": "pointer", "transition": "0.5s" }}>
                <Row style={{ "color": (active) ? "#000" : "inherit" }} align="middle" gutter={30}>
                    <Col xs={12} sm={5}>
                        <Space size={15}>
                            {((active) && <CheckOutlined aria-hidden style={{ "fontSize": "20px" }} />)}
                            <span style={{ "fontWeight": (active) ? "bold" : "normal" }}>{label}</span>
                        </Space>
                    </Col>
                    <Col className="hide-block" xs={24} sm={16}>
                        {drawMessage()}
                    </Col>
                    <Col style={{ "borderLeft": "1px solid #eee" }} xs={12} sm={3}>
                        <div style={{ "textAlign": "center" }}><Button aria-label={label + " cost is $" + cost} aria-pressed={active}><strong><em>{(cost && cost !== "0.00" && cost !== "TBD") ? "$" + cost : (cost === "0.00") ? "Free" : cost}</em></strong></Button></div>
                    </Col>
                    <Col className="show-block" xs={24} sm={16}>
                        <div aria-hidden><Divider dashed style={{ "margin": "15px 0px" }} /></div>
                        {drawMessage()}
                    </Col>
                </Row>

                <div style={{ "maxHeight": (active) ? "800px" : "0px", "transition": "0.5s", "overflow": "hidden" }}>

                    <div aria-hidden><Divider dashed style={{ "margin": "15px 0px" }} /></div>

                    {((delivery_preference === "local_delivery") && <>
                        {<Html className="" html={local_message} />}
                    </>)}

                    {((delivery_preference !== "local_delivery") && <>
                        {<Html className="" html={shipping_message} />}
                    </>)}

                    {((session.cart.summary.contains_emailed_gift_card) && <>
                        {drawEmailGiftCardsDisclaimer()}
                    </>)}


                    <MultipleShipments saved={saved} batch={batch} updateBatch={updateBatch} provider={provider} address_id={address_id} active={active} delivery_preference={delivery_preference} delivery_min={delivery_min} delivery_max={delivery_max} />

                </div>
            </Card>
        </>
    )
}
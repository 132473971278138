import React, { useState } from "react";
import { Popover, Button, Drawer, Modal } from "antd";
import { UserOutlined } from "@ant-design/icons";
import AccountLoginForm from "./components/account/AccountLoginForm";
import AccessiblePopover from "./components/AccessiblePopover";

function LoginBox(props) {
  const [popOverVisible, setPopOverVisible] = useState(false);

  const dismiss = () => {
    setPopOverVisible(false);
  };

  const handlePopOverVisibleChange = (visible) => {
    setPopOverVisible(visible);
  };

  // Mobile Drawer
  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };



  return (
    <div>
      <AccessiblePopover
        open={popOverVisible}
        onOpenChange={handlePopOverVisibleChange}
        title="Login"
        overlayClassName="fixed"
        className="hide"
        trigger="click"
        content={<AccountLoginForm dismiss={dismiss} />}
        placement="bottomRight"
        icon={<UserOutlined aria-hidden />}
        label="Login"
      />
      <div className="show">
        <UserOutlined
          className="icon-btn bm-icon"
          onClick={showDrawer}
          aria-expanded={visible} // Announce expanded state
          aria-controls="login-drawer" // Associate button with drawer
        />
        <Modal
          placement="bottom"
          title="Login"
          height="450px"
          onCancel={onClose}
          open={visible}
        >
          <div style={{ backgroundColor: "#fff", padding: "20px" }}>
            <AccountLoginForm dismiss={onClose} />
          </div>
        </Modal>
      </div>
    </div>
  );
}

export default LoginBox;
